import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../baseInstance';
import { CompanyAccount } from '../models';



export const companyApi = createApi( {
  baseQuery: axiosBaseQuery,
  reducerPath: 'company',
  tagTypes: [ 'current' ],
  endpoints: build => ( {
    getCurrentCompany: build.query<CompanyAccount, void>( {
      query: () => ( {
        url: '/company/current',
        method: 'GET',
      } ),
      providesTags: [ 'current' ]
    } ),
    updateTeams: build.mutation<void, {name: string, description: string}>( {
      query: arg => ( {
        url: '/company/teams',
        method: 'put',
        data: arg
      } ),
      invalidatesTags: [ 'current' ]
    } ),
    updateCompany: build.mutation<void, {recipients: string}>( {
      query: arg => ( {
        url: '/company/update',
        method: 'put',
        data: arg
      } ),
      invalidatesTags: [ 'current' ]
    } )
  } )
} );

export const {
  useGetCurrentCompanyQuery,
  useUpdateTeamsMutation,
  useUpdateCompanyMutation
} = companyApi;