import { Box, Button, Container, Grid, IconButton, InputAdornment, TextField, Typography,  } from '@mui/material'; 
import { useState } from 'react';  
import styles from './VerifyemailScreen.module.sass';
import logo from '../../../assets/img/staffgeek_logo.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { authService } from '../../../services';
import { ToastContainer, toast } from 'react-toastify';
import vecVer from '../../../assets/img/vecVer.png'; 
import { FiAlertCircle } from 'react-icons/fi';

export const VerifyemailScreen = () => {

    const navigate = useNavigate();

    const { email, status } = useParams();
    const [ isMessageSent, setIsMessageSent ] = useState<boolean>();
   
    const [ codeError, setcodeError ] = useState<boolean>( false );


    const resendemail = ()  =>  {
      authService.resendEmailverify( email ).then( ( res ) => {
       console.log( 'res',res );
       if ( res ) {
        toast.success( 'A new email was sent with a new verification code', {
          position: 'top-right',
          autoClose: 3000,
        } );
       } else {
        toast.error( 'Something went wrong', {
          position: 'top-right',
          autoClose: 3000,
        } );
       }
      } );
    };

    return (
      <Box className={styles['box']}>
      <Grid container className={`${styles['grid']} `}>
        <Grid className={`${styles['grid22']}`} item xs={6}  > 
          <Grid className={`${styles['grid44']}`} item xs={6}  > 
          <img src={logo} width={220} className={styles['logo']}/>
          </Grid>
          <Grid className={`${styles['grid33']}`} > 
            <img src={vecVer} className={styles['blob']}/> 
          </Grid>
        </Grid>
 
        <Grid className={`${styles['grid']} ${styles['gridform']}`} item xs={6} >  
          <Formik
            initialValues={{ code: '', email: email }}
            onSubmit={( fields ) => {
            authService.Emailverify ( fields ).then( ( res ) => {
              
              if ( res.success == false && res.type == 'code' ) {
                console.log( 'res****', res );
                setcodeError( true );
              }
            } ).catch( ( e ) => {
              console.log( 'first' );
              setcodeError( true );
            } );
            } } >
            {( { handleSubmit, values, handleChange, errors } ) => (

              < form onSubmit={handleSubmit}>
                <Container
                  className={styles['container']}
                  maxWidth="sm"
                >
                  <Box className={styles['boxMargin']}>
                    <Typography
                      className={`${styles['typography']} ${styles['typography2']}`}
                      variant="h4"
                      gutterBottom
                    >
                      Email Verification
                    </Typography> 
                    <p>Please check your email for a verification code and enter it below.</p>
                    <Box className={styles['boxMargin']}>
                    <TextField
                          label="Email verification code *"
                          name="code"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.code}
                          fullWidth
                          type="text"    
                          className={styles[ 'textField' ]}
                          error={ 
                            codeError == true
                          }
                          // helperText={
                          //   codeError === true ? 'Invalid verification code!' : ''  
                          // }
                      /> 
                      {codeError== true ?  
                        <div  className={styles[ 'errordiv' ]}>
                          <FiAlertCircle /> <p  className={styles['errorMessage']} >Invalid verification code!</p> 
                        </div > 
                      : '' }
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        size="large"
                        fullWidth 
                        style={{ backgroundColor:'#9200ff' }}
                        className={`${styles['textField']} ${styles['textField2']}`} >
                        Continue
                      </Button> 

                      {!isMessageSent??
                          <p className={styles['errormes']} >Invalid verification code!</p >
                      }
                    </Box>
                  </Box>
                </Container>
              </form>
            )}
          </Formik>
          <div style={{ width: '100%', height: '100px' }}>
          <div className={styles['divider']}>
            <div className={styles['line']}></div>
            <div className={styles['or']}>or</div>
            <div className={styles['line']}></div>
          </div> 
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p>Did not receive the email? <Button onClick={resendemail}> Resend email</Button></p>
            </div>
            </div> 
        </Grid>
      </Grid>
    </Box >
    );
};