import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { useGetCurrentCompanyQuery, useGetUsersQuery, useGetCurrentUserQuery } from '../../../services';
import { UserAccount } from '../../../services/models';
import { usersSortings } from '../../../utils/sortFunctions';
import { Button } from '../../controls';
import { TableListQuery } from '../../ui';
import { EditButton } from './EditButton';
import styles from './UsersScreen.module.sass';
import { DeleteButton } from './DeleteButton';

export const UsersScreen = () => {

  const navigate = useNavigate();

  const currentCompany = useGetCurrentCompanyQuery();

  const getUsersQuery = useGetUsersQuery();

  const currentUser = useGetCurrentUserQuery();

  if ( !currentCompany.data ) return null;
  
  const goCreateUser = () => navigate( routesPaths.createUser );
  const goToUserProfile = ( user: UserAccount ) => navigate( routesPaths.profile.replace( ':id', user._id ) );
  
  return (
    <>
      {currentUser.data && currentUser?.data.role === 'admin' && <div className={styles['actions']}>
        <Button styleType='alter' text='Add New User' onClick={goCreateUser} />
      </div>}
      <TableListQuery<UserAccount, 'actions' | 'firstName' | 'lastName'>
        fieldsToDisplay={ currentUser.data && currentUser.data.role === 'admin' ? [ 
          'firstName', 
          'lastName',
          'email',  
          'role', 
          'actions' 
        ] : [ 
          'firstName', 
          'lastName',
          'email', 
          'role'
        ] }
        headings={{
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email Address',
          // companyId: 'Organization',
          // createdAt: 'Registered',
          // lastLogin: 'Last Login',
          role: 'User Role',
          actions: 'Actions',
          // team: 'Team'
        }}
        queryHook={useGetUsersQuery}
        hidePagination={false}
        customFields={{
          actions: ( data: UserAccount ) => (
            <>
              <EditButton user={data} /> 
              <DeleteButton user={data} anotherStyle={{
                marginLeft: 5,
              }} />
            </>
          ),
          firstName: ( data ) => data.name.split( ' ' )[0],
          lastName: ( data ) => data.name.split( ' ' )[1],
        }}
        // onItemClick={goToUserProfile}
        sortFunctions={{
          name: usersSortings.byName,
          lastName: usersSortings.byLastName,
          firstName: usersSortings.byFirstName,
          email: usersSortings.byEmail,
          // createdAt: usersSortings.byRegistrationDate,
          // lastLogin: usersSortings.byLoginDate,
          role: usersSortings.byRole,
          // team: usersSortings.byTeam
        }}
        style={{ marginTop: 32 }}
      />
    </>
  );
};