
export const localStoragePath = {
  token: 'jwt',
  refreshToken: 'refreshToken',
};

export interface ApiSliceState {
  isLoading: boolean,
  error?: string | object,
  success?: boolean,
}

export interface AuthSliceState extends ApiSliceState {
  accessToken: string,
  isAuthorized: boolean,
  refreshToken: string,
  validated: boolean
}

export interface AppSliceState {
  isShown: boolean,
  hasFitTech: boolean,
  title?: string
}