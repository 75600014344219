import { useEffect, useMemo, useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesPaths, routesTitles } from '../../../navigation/routes';
import { useTypedSelector } from '../../../state';
import styles from './TopBar.module.sass';

export const TopBar = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const appState = useTypedSelector( state => state.appSlice );

  const [ title, setTitle ] = useState<string | null>( null );


  useEffect( () => {
    for ( const [ routeName, routePath ] of Object.entries( routesPaths ) ) {
      if ( location.pathname === routePath ) {
        setTitle( routesTitles[routeName as keyof typeof routesPaths] );
        return;
      }
    }
  }, [ location ] );
  
  return (
    <div className={styles['top-bar']}>
      <h1>
        {appState.title || title}
      </h1>
    </div>
  );
};