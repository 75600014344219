import { CSSProperties } from 'react';
import './loadingIndicator.css';

export interface LoadingIndicatorProps {
  style?: CSSProperties
}

export const LoadingIndicator = ( { style } : LoadingIndicatorProps ) => (
  <div className={'loading-indicator'} style={style}>
  </div>
);