import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseUrl } from '../baseInstance';
import { CulturalAssessmentAttribute } from '../models';
import { SubmitGameFields } from '../types';


export const assessmentGameApi = createApi( {
  baseQuery: fetchBaseQuery( { baseUrl } ),
  reducerPath: 'assessmentAttributes',
  endpoints: build => ( {
    getAssessmentAttributes: build.query<CulturalAssessmentAttribute[], void>( {
      query: () => ( { url: '/assessmentAttributes' } ),
      transformResponse: ( raw : CulturalAssessmentAttribute[] ) => {
        return raw.sort( () => Math.random() - .5 );
      }
    } ),
    getAssessmentGroups: build.query<{groups: string[], cultureNeeds: 'protected' | 'fixed'}, string>( {
      query: token => ( {
        url: '/assessment/game', params: { token }
      } )
    } ),
    submitGameResults: build.mutation<void, (
      SubmitGameFields & {
        essential: CulturalAssessmentAttribute[],
        nice: CulturalAssessmentAttribute[],
        notEssential: CulturalAssessmentAttribute[],
        token: string
      }
    )>( {
      query: arg => ( {
        url: '/assessment/game',
        method: 'post',
        body: {
          ...arg,
          nice: arg.nice.map( attribute => attribute._id ),
          essential: arg.essential.map( attribute => attribute._id ),
          notEssential: arg.notEssential.map( attribute => attribute._id ),
        }
      } )
    } )
  } )
} );

export const {
  useGetAssessmentAttributesQuery,
  useGetAssessmentGroupsQuery,
  useSubmitGameResultsMutation
} = assessmentGameApi;