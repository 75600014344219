import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { authService, getHelpField2, getHelpFields, getHelpFieldsSchema, useGetAssessmentByCompanyQuery, useGetCurrentUserQuery, useGetHelpMutation } from '../../../services';
import styles from './GetHelpScreen.module.sass';
import { useScreenTitle } from '../../../utils';
import { Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import gLogo from '../../../../src/assets/img/logo.png';
import { useState,useMemo, useEffect } from 'react'; 
import vacMess from '../../../assets/img/vacMess.png'; 
import { Formik } from 'formik';
import { object, string } from 'yup';


export const GetHelpScreen = () => {

    const navigate = useNavigate();
    useScreenTitle( 'Get Help' );
    const currentUser = useGetCurrentUserQuery(); 

    const [ id, setId ] = useState( currentUser?.data?._id );  
    const [ name, setname ]= useState( '' );
    const [ email, setemail ]= useState( '' ); 
    const [ message, setmessage ]= useState( '' ); 
    console.log( 'id',id );


    const [ createHelpreq ] = useGetHelpMutation();
    const assessment = useGetAssessmentByCompanyQuery( '1' );

    // const screenTitle = useMemo( (  ) => {
    //     if ( assessment.data?._id && assessment.data?.targetParticipants > 0 ) { 
    //       setId( assessment.data?._id );
    //     } 
    // }, [ assessment ] );

    useEffect( ()=>{
        if ( currentUser && currentUser.data ) {
            setname( currentUser.data.name );
            setemail( currentUser.data.email );
        }
    },[ currentUser ] );

 
    const submit = async ( ) => {
        if ( message ) {
        const data: any ={
            userid: id,
            name: name,
            email: email,
            message: message,
        };
        try {
            await createHelpreq( data ).then( ( res )=>{
              console.log( 'response from help request ', res );
              if ( res ){
                  setformSubmited( true );
              }
            } ); 
          } catch ( error ) { 
            console.error( 'Error creating help request:', error );
          }
        }
    };

    const [ formSubmited, setformSubmited ]= useState( false );
 
    const getHelpFieldsSchema = object( ).shape( {
        name: string() ,
        email: string() ,
        message: string().required( 'Message is required' ),
    } );

    return (
        <>
            <div className={styles['mainDiv']}>
                <div className={styles['divRight']}>
                    <h2 >Contact Us</h2>
                    {!formSubmited
                        ?<> 
                            <div className={styles['form']}>
                                <TextField  
                                    id="outlined-required-name"
                                    label="name"
                                    name='name'
                                    className={styles['TextField']}
                                    value={name}  
                                    disabled
                                />
                                <TextField  
                                    id="outlined-required-email"
                                    label="email"
                                    name='email' 
                                    className={styles['TextField']}
                                    value={email}  
                                    disabled
                                />
                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    label="Message"
                                    name='message'
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={ ( text ) => { setmessage( text.target.value ); } }
                                    className={styles['TextField2']}
                                /> 
                                <Button onClick={submit} type="submit" className={styles['formBtn']} variant="contained" endIcon={<SendIcon />}>
                                    Send
                                </Button> 
                            </div>
                         
                            </>
                        :
                            
                        <div style={{ minHeight: '450px !important', }} className={styles['successDiv']}>
                            <div className={styles['successDiv2']}>
                                <img src={vacMess} className={styles['vacMess']}/> 
                                <h3>Thank you for contacting us!</h3>
                                <p>We will get back to you as soon as possible</p>
                            </div>
                        </div>
                    }
                </div>
                <div className={styles['divLeft']}>
                    <img src={gLogo} width={220} className={styles['logo']} />
                </div>
            </div>
        </>
    );
};