import { useFormikContext } from 'formik';
import { ChangeEventHandler, CSSProperties, Fragment, MouseEventHandler } from 'react';
import { ReactComponent as TickIcon } from '../../../../../assets/icons/tick-icon.svg';
import { QuestionCustomRenderProps } from '../../Question';
import styles from './ClosedQuestion.module.sass';
import { useEffect, useState } from 'react';

export interface ClosedQuestionProps extends QuestionCustomRenderProps {
  variants?: {
    value: string,
    label: string
  }[],
  labelsStyle?: CSSProperties,
  onChange?: ( value: string ) => void
}

export const ClosedQuestion = ( {
  name, variants = [
    { label: 'Agree', value: 'agreed' },
    { label: 'Neither', value: 'neither' },
    { label: 'Disagree', value: 'disagreed' },
  ], labelsStyle, onChange,
  scrollToQuestion, number
}: ClosedQuestionProps ) => {
  const [ Pressed, setPressed ] = useState<string | null>( null );
  const { handleChange, values, setFieldValue } = useFormikContext();
  
  const inputValue = ( values as Record<string, string> )[name];

  const onInputChange: ChangeEventHandler<HTMLInputElement> = ( event ) => {
    handleChange( event );
    if ( onChange ) onChange( event.target.value );
  };

  const onClick: MouseEventHandler = event => {
    const isCurrentValueClicked = inputValue === ( event.target as HTMLInputElement ).value;
    if ( isCurrentValueClicked ) setFieldValue( name, '' );
    if ( scrollToQuestion && !isCurrentValueClicked ) scrollToQuestion( number + 1 )();
    // console.log('number',number);
    // console.log('isCurrentValueClicked',isCurrentValueClicked);
    // console.log('inputValue',inputValue);
  };
  // const onKeyDown: MouseEventHandler = event => {
  //   const isCurrentValueClicked = inputValue === (event.target as HTMLInputElement).value;
  //   if (isCurrentValueClicked) setFieldValue(name, '');
  //   if (scrollToQuestion && !isCurrentValueClicked) scrollToQuestion(number + 1)();
  //   console.log('number',number);

  // };

  

  // const handleKeyPress = (event: KeyboardEvent) => {
  //   console.log('event.key', event.key);

  //   if (event.key === 'a') {
  //     console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
  //   }
  //   if (event.key === 'b') {
  //     console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb');
  //   }
  //   if (event.key === 'c') {
  //     console.log('cccccccccccccccccccccccccccccccccccccccccccccc');
  //   }
  //   if (event.key === 'q') {
  //     window.scrollTo(0, document.body.scrollHeight);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('keydown', handleKeyPress);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleKeyPress = (event: KeyboardEvent) => {
  //     if (event.key === 'a') {
  //       const radioButtons = document.querySelectorAll('.Survey_current__9CJa7 input[type="radio"]');
  //       radioButtons.forEach((radioButton) => {
  //         if (radioButton instanceof HTMLInputElement && radioButton.value === 'agreed') {
  //           radioButton.checked = true;
  //           const isCurrentValueClicked = inputValue === (event.target as HTMLInputElement).value;
  //           if (isCurrentValueClicked) setFieldValue(name, '');
  //           if (scrollToQuestion && !isCurrentValueClicked) scrollToQuestion(number + 1)();
  //           // console.log('number',number);
  //           // console.log('isCurrentValueClicked',isCurrentValueClicked);
  //           // console.log('inputValue',inputValue);
  //         }
  //       });
  //       setPressed('a');
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyPress);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);
  return (
    <div className={styles['closed-question']}>
      {variants.map( ( variant, index ) => (
        <Fragment key={index}>
          <input
            onChange={onInputChange}
            type='radio'
            name={name}
            id={`${name}_${variant.value}`}
            value={variant.value}
            checked={inputValue === variant.value}
            onClick={onClick}
          />
          <label htmlFor={`${name}_${variant.value}`} style={labelsStyle}>
            <span>{String.fromCharCode( 65 + index )}</span>
            {variant.label}
            <TickIcon />
          </label>
        </Fragment>
      ) )}
    </div>
  );
};