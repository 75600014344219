import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import { CulturalAssessmentAttribute } from '../../../services/models';
import { Button } from '../../controls';
import { BasketProps } from './Basket';
import { BasketModalAttribute, BasketModalAttributeProps } from './BasketModalAttribute';
import styles from './CulturalAssessmentGameScreen.module.sass';

export type BasketModalProps = Omit<BasketProps, 'onDrop' | 'forceOpenModal' | 'setForceOpenModal'> & {
  attributes: CulturalAssessmentAttribute[],
  onClose: () => void,
  tip?: string,
  onCardDoubleClick?: BasketModalAttributeProps['onDoubleClick']
} & Pick<BasketModalAttributeProps, 'onDrop'>

export const BasketModal = ( { 
  title, stars, attributes, required, tip, onClose, 
  onDeleteAttribute, onDrop, onCardDoubleClick
} : BasketModalProps ) => {
  
  return (
    <div 
      className={`${styles['basket-modal']} ${styles['basket-content']}`}
      onClick={e => e.stopPropagation()}
    >
      <div>
        <div className={styles['header']}>
          <div className={styles['title']}>
            <div className={styles['stars']}>
              {Array( stars ).fill( 0 ).map( ( v, index ) => <StarIcon key={index} /> )}
              <h3>{title}</h3>
            </div>
          </div>
          <div className={styles['progress']}>
            <span>
              {attributes.length}{required && `/${required}`} cards
            </span>
            {required && (
              <div className={styles['bar']}>
                <span 
                  style={{ width: `${Math.round( attributes.length / required * 100 )}%` }}>
                </span>
              </div>
            )}
          </div>
        </div>
        { tip && (
          <div className={styles['tip']}>{tip}</div>
        )}
        <div className={styles['attributes']}>
          {attributes.map( ( attribute, index ) => (
            <BasketModalAttribute
              attribute={attribute}
              order={index+1}
              onDelete={onDeleteAttribute}
              key={index}
              isLast={index + 1 === attributes.length}
              onDrop={onDrop}
              onDoubleClick={onCardDoubleClick}
            />
          ) )}
        </div>
      </div>
      <div>
        <Button
          text='Close bucket'
          styleType='whiteText'
          style={{ fontSize: 14, textTransform: 'uppercase', width: '100%' }}
          onClick={onClose}
        />
      </div>
    </div>
  );
};