import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo, useState, useRef } from 'react';
import { useGetAssessmentByCompanyQuery, useGetAssessmentShareTokenQuery, useGetAssessmentsQuery } from '../../../services';
import styles from './FitTechInstructionsScreen.module.sass';  
import { ToastContainer, toast } from 'react-toastify';
import { routesPaths } from '../../../navigation/routes';
import { useNavigate } from 'react-router-dom';
import { useClickAwayListener, useScreenTitle } from '../../../utils'; 
 

export const FitTechInstructionsScreen = () => { 

  const navigate = useNavigate(); 
  const [ id, setId ] = useState( '' );
  const assessment = useGetAssessmentByCompanyQuery( '1' );
  const shareToken = useGetAssessmentShareTokenQuery( id || skipToken );  
  const sharePopupRef = useRef<HTMLElement>( null );
  const resultsPopupRef = useRef<HTMLElement>( null );
  const [ isResultsPopupOpen, setResultsPopupOpen ] = useState( false ); 
  const [ isPaid, setIsPaid ] = useState( false ); 

  const toggleResultsPopup = ( open: boolean ) => () => setResultsPopupOpen( open );

  useClickAwayListener( resultsPopupRef, isResultsPopupOpen ? toggleResultsPopup( false ) : null );
  useClickAwayListener( sharePopupRef, isResultsPopupOpen ? toggleResultsPopup( false ) : null );

  const gameUrl = useMemo( () => (
    typeof shareToken.data === 'string'
      ? window.location.origin + routesPaths.gameWelcome.replace( '*', shareToken.data )
      : undefined
  ), [ shareToken.data ] );

  const assessmentUrl = useMemo( () => (
    typeof shareToken.data === 'string'
      ? window.location.origin + routesPaths.fitTechBefor.replace( '*', shareToken.data )
      : undefined
  ), [ shareToken.data ] );

  

  const gamePath = useMemo( () => gameUrl?.replace( window.location.origin, '' ) || '', [ gameUrl ] );
  const assessmentPath = useMemo( () => assessmentUrl?.replace( window.location.origin, '' ) || '', [ assessmentUrl ] );
   
  const goToAssessment = () => {
    if ( assessmentUrl ) navigate( assessmentPath );
  }; 

  const goToHiringAssessment = () => {
    if ( assessmentUrl ) navigate( '/assessments/hiringAssessment' );
  }; 

  const copyAssessmentLink = () => { 
    if ( assessmentUrl ) {
      toast.success( 'Successfully copied Cultural Assessment link.', {
        position: 'top-right',
        autoClose: 3000,
      } );
      navigator.clipboard.writeText( assessmentUrl ); 
    }
  };

  useMemo( () => {
    if ( assessment.data?._id ) {
      setId( assessment.data?._id );
    }
  }, [ assessment.data ] );
 
  useScreenTitle( 'Hiring Assessment Instructions' );

  const assessments = useGetAssessmentsQuery(); 
  
  useMemo( () => {
    if ( assessments.data ) {
      const paid =  assessments.data.reduce( ( result, assessment ) => {
        return result || ( 
          assessment.fitTechStatus.paid || 
          assessment.fitTechStatus.trial === 'active' || 
          assessment.fitTechStatus.trial === 'over' 
        );
      }, false );

      setIsPaid( paid );
    }
  }, [] );


  // if( !isPaid ) {
  //   navigate( '/assessments/payment' );
  // }

  return ( 
    <div className={styles[ 'container' ]}>
      <div className={styles[ 'container2' ]}>
        <p className={styles[ 'headerText' ]}>About Hiring Assessment</p>
        <p className={styles[ 'description' ]}>Your hiring assessment is based on your cultural assessment. It exists as a link you can share with candidates by email, pasted into a job description or however else you’d like to include it into your hiring process.</p>
        <div className={styles[ 'stepsDiv' ]}>
          
          <div className={styles[ 'step' ]}>
            <div className={styles[ 'steptop']}>
              <div className={styles[ 'stepsub' ]}>
                <div className={styles['span']}></div>
                <p className={styles['StepH']}>Step 1</p>
              </div>
              <p className={styles['stepD']}>Share the hiring assessment link with candidates.</p>  
            </div>
            <button onClick={ copyAssessmentLink } className={styles['btn']}>Copy</button> 
          </div>

          <div className={styles[ 'step' ]}>
            <div className={styles[ 'steptop']}>
              <div className={styles[ 'stepsub' ]}>
                <div className={styles['span']}></div>
                <p className={styles['StepH']}>Step 2</p>
              </div>
              <p className={styles['stepD']}>Candidates fill out the hiring assessment.</p>  
            </div> 
          </div>

          <div className={styles[ 'step' ]}>
            <div className={styles[ 'steptop']}>
              <div className={styles[ 'stepsub' ]}>
                <div className={styles['span']}></div>
                <p className={styles['StepH']}>Step 3</p>
              </div>
              <p className={styles['stepD']}>You receive the results by email and your dashboard.</p> 
            </div> 
            <button onClick={ goToHiringAssessment } className={styles['btn']}>View Dashboard</button>
          </div>

        </div>
      </div> 
      {/* <ToastContainer /> */}
    </div>
  );
};