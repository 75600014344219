import { useContext, useState } from 'react';
import { useDrop } from 'react-dnd';
import ReactModal from 'react-modal';
import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import { ReactComponent as DisabledStarIcon } from '../../../assets/icons/disabled-start.svg';
import { CulturalAssessmentAttribute } from '../../../services/models';
import { BasketContext } from './BasketContext';
import { BasketModal, BasketModalProps } from './BasketModal';
import { CardView } from './CardView';
import styles from './CulturalAssessmentGameScreen.module.sass';
import { GameDragTypes } from './types';

export interface BasketProps {
  title: string,
  stars: number,
  required?: number,
  onDeleteAttribute: ( attribute: CulturalAssessmentAttribute ) => void,
  onDrop?: ( attribute: CulturalAssessmentAttribute ) => void,
  forceOpenModal?: boolean,
  setForceOpenModal?: ( open: boolean ) => void
}

const modalStyles : ReactModal.Styles = {
  content: { maxWidth: '420px', left: '50%', transform: 'translateX(-50%)', top: 20 }
};

export const Basket = ( { 
  title, stars, required, onDrop, onDeleteAttribute, forceOpenModal, setForceOpenModal
} : BasketProps ) => {

  const [ isModalOpen, setModalOpen ] = useState( false );

  const { attributes, setAttributes } = useContext( BasketContext );
  const [ doubleClickedAttribute, setDoubleClickedAttribute ] = useState<CulturalAssessmentAttribute>();

  const toggleModalOpen = () => {
    if ( forceOpenModal && setForceOpenModal ) {
      setModalOpen( false );
      setForceOpenModal( false );
    } else {
      setModalOpen( !isModalOpen );
    }
  };

  const [ { isOver } , dropRef ] = useDrop( {
    accept: GameDragTypes.CARD,
    drop: ( item: CulturalAssessmentAttribute ) => {
      if ( !attributes.find( attr => attr._id === item._id ) ) {
        setAttributes( attributes.concat( item ) );
        onDrop && onDrop( item );
      }
    },
    collect: monitor => ( {
      isOver: !!monitor.isOver()
    } )
  } );
  
  const onDeleteAttributeWrap = ( attribute: CulturalAssessmentAttribute ) => {
    setAttributes( attributes.filter( attr => attr._id !== attribute._id ) );
    onDeleteAttribute( attribute );
  };  

  const onModalCardDrop : BasketModalProps['onDrop'] = ( dropped, target, position ) => {
    const attributesWithoutDropped = attributes.filter( attribute => attribute._id !== dropped._id );
    let targetIndex = attributesWithoutDropped.findIndex( attribute => attribute._id === target._id );
    
    if ( position === 'after' ) targetIndex++;
    
    setAttributes( [
      ...attributesWithoutDropped.slice( 0, targetIndex ),
      dropped,
      ...attributesWithoutDropped.slice( targetIndex )
    ] );
    
  };

  const onModalCardDoubleClick : BasketModalProps['onCardDoubleClick'] = attribute => {
    setDoubleClickedAttribute( attribute );
  };

  const onModalClose = () => {
    if ( doubleClickedAttribute ) {
      setDoubleClickedAttribute( undefined );
      setModalOpen( true );
    }
  };
  
  return (
    <div 
      className={[
        styles['basket']
      ].join( ' ' )}
      ref={dropRef}
      onClick={toggleModalOpen}
    >
      <div className={ [ styles[ 'basket-content' ], isOver ? styles[ 'over' ] : undefined ].join( ' ' ) } >
        <div className={styles['stars']}>
          {Array( stars ).fill( 0 ).map( ( v, index ) => <StarIcon key={index} height={12} width={12} /> )}
          {[ 1, 2 , 3 ].map( ( v, index ) => stars < index + 1 && <DisabledStarIcon key={index} height={12} width={12} /> )}
        </div>
        <h3>{title}</h3>
        <div className={styles['progress']}>
          <span>
            {required && attributes.length >= required ? (
              'Minimum reached!'
            ) : required && (
              `${attributes.length}${required ? `/${required}` : ''} cards`
            )}
          </span>
        </div>
        { !required && (
          <div style={ { height: 18 } }></div>
        ) }
      </div>
      <ReactModal
        isOpen={isModalOpen || !!forceOpenModal}
        style={modalStyles}
        appElement={document.getElementById( 'root' ) as HTMLDivElement}
        shouldCloseOnOverlayClick={false}
        overlayRef={el => el && ( el.style.zIndex = '1' )}
        className={styles['basket-modal-content']}
        onAfterClose={onModalClose}
        overlayClassName={styles['basket-modal-overlay']}
      >
        {doubleClickedAttribute ? (
          <div 
            style={{ height: '100%', display: 'flex', alignItems: 'center', padding: 16 }}
          >
            <CardView 
              attribute={doubleClickedAttribute}  
              onClick={e => e.stopPropagation()}
            />
          </div>
        ) : (
          <BasketModal
            title={title}
            stars={stars}
            required={required}
            attributes={attributes}
            tip={stars === 3 ? 'Rank each Essential attribute in importance by dragging and dropping, from most to least, with 1 being the most important.' : undefined}
            onClose={toggleModalOpen}
            onDeleteAttribute={onDeleteAttributeWrap}
            onDrop={onModalCardDrop}
            onCardDoubleClick={onModalCardDoubleClick}
          />
        )}
      </ReactModal>
    </div>
  );
};