import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiSliceState, AuthSliceState, localStoragePath } from './types';


const initialState : AuthSliceState = {
  accessToken: localStorage.getItem( localStoragePath.token ) || '',
  isAuthorized: !!localStorage.getItem( localStoragePath.token ),
  refreshToken: localStorage.getItem( localStoragePath.refreshToken ) || '',
  validated: false,
  error: undefined,
  isLoading: false,
  success: undefined
};

export const authSlice = createSlice( {
  initialState,
  name: 'authSlice',
  reducers: {
    setAccessToken: ( state, action: PayloadAction<string> ) => {
      state.accessToken = action.payload;
      localStorage.setItem( localStoragePath.token, action.payload );
      if ( action.payload ) state.error = undefined;
      state.isAuthorized = !!action.payload;
    },
    setIsAuthorized: ( state, action: PayloadAction<boolean> ) => {
      state.isAuthorized = action.payload;
      if ( !action.payload ) {
        state.refreshToken = '';
        state.accessToken = '';
        state.validated = true;
      }
    },
    setRefreshToken: ( state, action: PayloadAction<string> ) => {
      state.refreshToken = action.payload;
      localStorage.setItem( localStoragePath.refreshToken , action.payload );
    },
    setValidated: ( state, action: PayloadAction<boolean> ) => {
      state.validated = action.payload;
    },
    setError: ( state, action: PayloadAction<ApiSliceState['error']> ) => {
      state.error = action.payload;
      state.isLoading = false;
      state.success = false;
    },
    setSuccess: ( state, action: PayloadAction<ApiSliceState['success']> ) => {
      state.success = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
  }
} );


export const authActions = authSlice.actions;