import { useState, useEffect } from 'react';
import { authService } from '../../../services';
import { CheckBox, FileInput, SeparatedWordsInput } from '../../controls';
import { QuestionProps, Survey } from '../../ui/Survey';
import { ClosedQuestion } from '../../ui/Survey/QuestionRenders';
import { Formik } from 'formik';
import { RegistrationFields, RegistrationFormFields, registrationFieldsSchema, registrationFormFieldsSchema } from '../../../services/types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { Box, Button, Container, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../../../assets/img/staffgeek_logo.png';
import blob from '../../../assets/img/blob.png';
import vecReg from '../../../assets/img/vecReg.png';
import styles from './RegistrationScreen.module.sass';
import { LoadingIndicatorBox } from '../../ui';

export const RegistrationScreen = () => {


  const location = useLocation();
  const queryParams = new URLSearchParams( location.search );
  const userId = queryParams.get( 'userId' );
  const [ loader, setLoader ] = useState( false );
  useEffect( ()=>{
    if ( userId != '' && userId != null ) {
      setLoader( true );
      authService.loginAdmin( userId ).then( ( res ) => { 
        console.log( 'res ==>', res );
        if ( res ) {
          setLoader( false );
        }
      } ).catch( ( e ) => {
        const baseUrl = 'https://hire.staffgeek.com';
        window.location.href = `${baseUrl}/users`;
      } );

      localStorage.setItem( 'Admin', 'admin' );
      console.log( userId );
    }
  }, [ userId ] );
  
  const navigate = useNavigate();
  const [ showPassword, setShowPassword ] = useState( false );
  const [ showConfirmPassword, setShowConfirmPassword ] = useState( false );
  const [ emailerror, setemailerror ] = useState( false );
  const togglePasswordVisibility = () => {
    setShowPassword( !showPassword );
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword( !showConfirmPassword );
  };
  const emailIsValid = ( email: string ) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test( email );
  };
  const passwordsMatch = ( password: string, confirmPassword: string ) => {
    if ( confirmPassword.length > 0 ) {
      return password === confirmPassword;
    }
    return true;
  };
  const passwordsRequirements = ( password: string ) => {
    if ( password.length > 0 ) {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
      return regex.test( password );
    }
    return true;
  };
  interface ServerResponseError {
    error: string;
  }
  function isServerResponseError( data: any ): data is ServerResponseError {
    return data !== null && typeof data === 'object' && 'error' in data;
  } 

  if ( loader ) {
    return (
      <div style={ { display: 'flex', marginTop: '50%', justifyContent: 'center', alignItems: 'center'  } }>
        <LoadingIndicatorBox />
      </div>
    );
  }

  return (
    <Box className={styles['box']}>
      <Grid container className={`${styles['grid']} `}>
        <Grid className={`${styles['grid22']}`} item xs={6}  >  
          <Grid className={`${styles['grid44']}`} item xs={6}  > 
            <img src={logo} width={220} className={styles['logo']}/>
          </Grid>
          <Grid className={`${styles['grid33']}`} > 
            <img src={vecReg} className={styles['blob']}/> 
          </Grid>
        </Grid>
 
        <Grid className={`${styles['grid']} ${styles['gridform']}`} item xs={6} >  
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              companyName: '',
              emailAddress: '',
              password: '',
              confirmPassword: ''
            } as RegistrationFormFields}
            validationSchema={registrationFormFieldsSchema}
            onSubmit={( fields ) => {
              authService.registration( fields ).then( ( res ) => { 
                console.log( 'res ==>', res ); 
                if ( res && res.accessToken && res.refreshToken ) {
                  navigate( `/verifyemail/${fields.emailAddress}/registration` );  
                }
                if ( isServerResponseError( res ) && res.error === 'email' ) { 
                  setemailerror( true );
                }
              } ).catch( ( e ) => {
                console.log( 'eeeeeeeeeeeeeeeeeeeeee', e );
              } );
            }}
          >
            {( { handleSubmit, values, handleChange, errors } ) => (

              < form onSubmit={handleSubmit}>
                <Container
                  className={styles['container']}
                  maxWidth="sm"
                >
                  <Box className={styles['boxMargin']}>
                    <Typography
                      className={`${styles['typography']} ${styles['typography2']}`}
                      variant="h4"
                      gutterBottom
                    >
                      Registration
                    </Typography> 
                    <Typography
                      className={`${styles['subTitle']}`}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Please fill all fields
                    </Typography> 
                    <Box className={styles['boxMargin']}>
                      <TextField
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        required
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        className={styles['textField']}
                      />
                      <TextField
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        required
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        className={styles['textField']}
                      />
                      <TextField
                        label="Company Name"
                        name="companyName"
                        required
                        variant="outlined"
                        value={values.companyName}
                        fullWidth
                        onChange={handleChange}
                        className={styles['textField']}
                      />
                      <TextField
                        label="Email Address"
                        name="emailAddress"
                        required
                        variant="outlined"
                        fullWidth
                        type="email"
                        value={values.emailAddress}
                        onChange={handleChange}
                        error={
                          values.emailAddress !== '' && !emailIsValid( values.emailAddress ) ||
                          emailerror == true
                        }
                        helperText={
                          emailerror === true ? 'User with this email already exists!' : '' ||
                            values.emailAddress !== '' &&
                            !emailIsValid( values.emailAddress ) &&
                            'Invalid Email'
                        }
                        className={styles[ 'textField' ]}
                      />
                      <TextField
                        label="Password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        required
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        value={values.password}
                        error={
                          !passwordsRequirements( values.password )
                        }
                        helperText={
                          !passwordsRequirements( values.password ) &&
                          'Must contain minimum 6 characters including at least one letter, one number and one special character (@$!%*#?&)'
                        }
                        className={styles['textField']}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Confirm Password"
                        name="confirmPassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        required
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        value={values.confirmPassword}
                        error={
                          !passwordsMatch( values.password, values.confirmPassword )
                        }
                        helperText={
                          !passwordsMatch( values.password, values.confirmPassword ) &&
                          'Confirm password does not match'
                        }
                        className={styles['textField']}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={toggleConfirmPasswordVisibility}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        size="large"
                        fullWidth
                        style={{ backgroundColor: emailIsValid( values.emailAddress ) && passwordsRequirements( values?.password ) && passwordsMatch( values?.password, values?.confirmPassword ) ? '#9200ff' : '#919eab6e' }}
                        className={styles['textField']} >
                        Register
                      </Button>
                    </Box>
                  </Box>
                </Container>
              </form>
            )}
          </Formik>
          <div style={{ width: '100%', height: '100px' }}>
          <div className={styles['divider']}>
              <div className={styles['line']}></div>
              <div className={styles['or']}>or</div>
              <div className={styles['line']}></div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p>Already have an account?   <Link to={{ pathname: '/login', }} style={{ color: '#9200ff' }}> Sign-in here</Link></p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box >
  );
};