import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo, useState } from 'react';
import { RiFileCopyLine } from 'react-icons/ri';
import { routesPaths } from '../../../navigation/routes';
import { useGetAssessmentShareTokenQuery } from '../../../services';
import styles from './FitTechResultsScreen.module.sass';
import { toast } from 'react-toastify';
import { Button } from '../../controls';

export interface FitTechLinkProps {
  id: string
}

export const FitTechLink = ( { id } : { id: string } ) => {

  const shareToken = useGetAssessmentShareTokenQuery( id || skipToken );

  const [ showLink, setShowLink ] = useState( false );
  const [ isCopied, setCopied ] = useState( false );
  
  const fitTechUrl = useMemo( () => {
      if ( shareToken.data ) return window.location.origin + routesPaths.fitTechBefor.replace( '*', shareToken.data );
  }, [ shareToken.data ] );
  
  const copyLink = () => {
    if ( fitTechUrl ) {
      toast.success( 'Successfully copied Hiring Assessment link.', {
        position: 'top-right',
        autoClose: 3000,
      } );
      navigator.clipboard.writeText( fitTechUrl );
      setCopied( true );
    }
  };

  if ( !fitTechUrl ) return null;
  
  return (
    <div className={ styles[ 'flex' ] }>
  <div className={ styles[ 'share-link-container' ] }>
    <p>Your Hiring Assessment :</p>
    <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px' } }>

     <Button text='Copy' styleType='nice' onClick={ copyLink } style={ { position: 'relative', backgroundColor: '#8250FF', marginBottom: 6, marginLeft: 6, padding: '7px 20px' } }>
      { ' ' }
        </Button>  
    </div>
  </div>
</div>
  );
};