import { FieldTransformation, FieldTransformations, TableListProps } from './TableList';
import styles from './TableList.module.sass';
import { TableListItemControls } from './TableListItemControls';

export type TableListItemProps<Item extends object, CustomFields extends string = never> =
  Pick<TableListProps<Item, CustomFields>, 
    'fieldsToDisplay' | 'fieldTransformations' | 'onEdit' | 'editable' | 'customFields' | 'onItemClick'
  > & { item: Item };

const applyTransformation = <Item extends object,>( 
  item: Item, 
  transformations: FieldTransformations<Item> | undefined,
  field: keyof Item 
) : {fieldValue: string | JSX.Element, transformed: true} | {fieldValue: unknown, transformed: false} => {
  if ( transformations && transformations[field] ) {
    return { 
      fieldValue: ( transformations[field] as FieldTransformation<Item, typeof field> )( item[field] ), 
      transformed: true 
    };
  } else {
    return {
      fieldValue: item[field],
      transformed: false
    };
  }
};

const getCustomFieldValue = <Item extends object, CustomFields extends string,>(
  item: Item,
  customFields: Record<CustomFields, ( item: Item ) => string | JSX.Element>,
  field: CustomFields
) => {
  const fieldValue = customFields && typeof customFields[field] === 'function' && customFields[field]( item );
  const transformed = typeof fieldValue !== 'string';
  return { fieldValue, transformed };
};

export const TableListItem = <Item extends object, CustomFields extends string = never,>( 
  { 
    item, fieldsToDisplay, fieldTransformations, customFields,
    onItemClick, onEdit=() => null, editable=false
  } : TableListItemProps<Item, CustomFields> 
) => (
  <tr className={onItemClick ? styles['clickable'] : undefined} onClick={() => onItemClick && onItemClick( item )}>
    { editable &&
      <TableListItemControls 
        item={item}
        onEdit={onEdit}
      />
    }
    
    {fieldsToDisplay.map( ( field, index ) => {
      const isCustomField = !Object.keys( item ).includes( field as string );
      if ( isCustomField && !customFields ) return <td />;

      const { fieldValue, transformed } = isCustomField
          ? getCustomFieldValue<Item, CustomFields>( item, customFields as never, field as CustomFields )
          : applyTransformation<Item>( item, fieldTransformations, field as keyof Item );

      return (
        <td key={index} className={transformed && typeof fieldValue !== 'string' ? styles['transformed'] : undefined}>
          {fieldValue as ( string | JSX.Element )}
        </td> 
      );
    }
    )}
  </tr>
);