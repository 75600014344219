
import { useFormikContext } from 'formik';
import { FocusEventHandler, KeyboardEventHandler, useState } from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import styles from './SeparatedWordsInput.module.sass';

export interface SeparatedWordsInputProps {
  name: string,
  placeholder?: string,
  styleType?: 'transparent' | 'standard'
}

export const SeparatedWordsInput = ( { 
  name, 
  placeholder='Type your answer here...',
  styleType='standard'
} : SeparatedWordsInputProps ) => {

  const [ focus, setFocus ] = useState( false );
  
  const { setFieldValue, values } = useFormikContext();
  
  const words = ( values as Record<string, string[]> )[name];

  const deleteWord = ( index: number ) => () => {
    setFieldValue( name, [
      ...words.slice( 0, index ),
      ...words.slice( index+1 )
    ] );
  };

  const onBlur : FocusEventHandler<HTMLInputElement> = event => {
    if ( event.target.value ) setFieldValue( name, words.concat( event.target.value ) );
    setFocus( false );
    event.target.value = '';
  };

  const onEnterPress : KeyboardEventHandler<HTMLInputElement> = event => {
    if ( ![ 'Enter', 'Comma' ].includes( event.code ) ) return;
    const value = ( event.target as HTMLInputElement ).value.trim();
    if ( value ) {
      setFieldValue( name, words.concat( value ) );
      ( event.target as HTMLInputElement ).value = '';
    }
    event.preventDefault();
  };

  return (
    <div className={`${styles['separated-words-input']} ${styles[styleType]}`}>
      {placeholder && !words.length && !focus && (
        <div className={styles['placeholder']}>
          {placeholder}
        </div>
      )}
      {words.map( ( word, index ) => (
        <span key={index}>
          {word}
          <RiCloseCircleLine onClick={deleteWord( index )} />
        </span>
      ) )}
      <input 
        className={styles['word-input']}
        onFocus={() => setFocus( true )}
        onBlur={onBlur}
        onKeyDown={onEnterPress}
      />
    </div>
  );
};