import { MouseEventHandler, useState } from 'react';
import Modal from 'react-modal';
import { UserAccount } from '../../../services/models';
import { Button } from '../../controls';
import { useDeleteUserMutation } from '../../../services';
import { toast } from 'react-toastify';


export const DeleteButton = ( { user, anotherStyle } : { user: UserAccount, anotherStyle?: any } ) => {
  const [ deleteUserMutation ] = useDeleteUserMutation();
  const [ isModalOpen, setIsModalOpen ] = useState( false );

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = async () => {
    await deleteUserMutation( user._id ).then( () => {
      toast.success( 'This user has been deleted from the Organization.', {
        position: 'top-right',
        autoClose: 3000,
      } );
    } ).catch( ( error ) => {
      console.error( 'Error deleting user', error );
      toast.error( "Couldn't delete the user.", {
        position: 'top-right',
        autoClose: 3000,
      } );
    } ).finally( () => {
      setIsModalOpen( false );
    } );
  };

  const openModal = () => {
    setIsModalOpen( true );
  };

  const closeModal = () => {
    setIsModalOpen( false );
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      backgroundColor: '#fff'
    },
  };
  
  return (
    <>
      <Button
        text='X'
        onClick={openModal}
        styleType={'nice'}
        style={{
          width: 'auto',
          marginTop: '0',
          padding: '4px 12px',
          backgroundColor: 'red',
          ...anotherStyle
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Delete User Modal"
        shouldCloseOnOverlayClick={false}
      >
        <div
          style={{
            textAlign: 'center'
          }}
        >
            <p
              style={{
                marginBottom: 30,
                color: '#392B63'
              }}
            >
              Are you sure you want to delete this user?
            </p>
            <Button
              text='Delete'
              onClick={handleOnClick}
              styleType={'nice'}
              style={{
                width: 'auto',
                marginTop: '0',
                padding: '4px 12px',
                backgroundColor: 'red'
              }}
            />
            <Button
              text='Cancel'
              onClick={closeModal}
              styleType={'nice'}
              style={{
                width: 'auto',
                marginTop: '0',
                padding: '4px 12px',
                backgroundColor: '#392964',
                marginLeft: 10
              }}
            />
        </div>
      </Modal>
    </>
  );
};