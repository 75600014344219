import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateUserFields, createUserSchema, EditUserFields, editUserSchema, useChangePasswordMutation, useCreateUserMutation, useGetCurrentCompanyQuery, useGetCurrentUserQuery, useGetUserQuery, useUpdateCompanyMutation, useUpdateUserMutation } from '../../../services';
import { Button, TextArea, TextAreaChange, TextInput } from '../../controls';
import { SelectField } from '../../controls/Select/Select';
import { TextInputView } from '../../controls/TextInput/TextInputView';
import { LabeledField, ResponseMessage } from '../../ui';
import { LoadingIndicatorBox } from '../../ui/Boxes';
import styles from './UserEditScreen.module.sass';

export const UserEditScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [ createUser ] = useCreateUserMutation();
  const [ updateUser ] = useUpdateUserMutation();
  const [ updateCompany ] = useUpdateCompanyMutation();
  const [ changePassword, { isSuccess, isError } ] = useChangePasswordMutation();
  const currentCompany = useGetCurrentCompanyQuery();
  const currentUser = useGetCurrentUserQuery();

  const { data: userToEdit, isLoading } = useGetUserQuery( id || skipToken );
  const [ newPassword, setNewPassword ] = useState( '' );
  const [ recipients, setRecipients ] = useState( currentCompany.data?.recipients ? currentCompany.data?.recipients : '' );
  const [ recipientsInitial, setRecipientsInitial ] = useState( currentCompany.data?.recipients ? currentCompany.data?.recipients : '' );
  const [ emailValidationFailed, setEmailValidationFailed ] = useState( false ); // State to manage email validation error

  const onNewPasswordChange = ( value: string ) => setNewPassword( value );
  const onNewPasswordClick = () => {
    changePassword( newPassword ).unwrap().then( () => {
      setNewPassword( '' );
    } );
  };

  const isEditing = !!id && !!userToEdit;
  const isCurrentUser = currentUser.data?._id === userToEdit?._id;

  if ( !currentCompany.data || isLoading || currentUser.isLoading ) return <LoadingIndicatorBox />;

  const initialValues = isEditing ? {
    name: userToEdit.name,
    description: userToEdit.description,
    email: userToEdit.email,
    role: userToEdit.role,
    team: userToEdit.team
  } as EditUserFields : {
    name: '',
    email: '',
    description: '',
    role: 'admin',
    team: '',
  } as CreateUserFields;

  const validateEmails = ( value: string ) => {
    const emails = value.split( /[ ,|\n]+/ ).map( email => email.trim() ).filter( email => email !== '' ); // Filter out empty strings
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emails.filter( email => !emailRegex.test( email ) );
    console.log( 'invalidEmails:', invalidEmails );
    return invalidEmails.length > 0;
};


  const onSubmit = ( values: EditUserFields | CreateUserFields ) => {
    if ( validateEmails( recipients ) ) {
      setEmailValidationFailed( true ); // Set email validation error state
      return;
    }
    setEmailValidationFailed( false ); // Reset email validation error state

    if( currentCompany?.data && isEditing ) {
      updateCompany( { recipients } ).unwrap();
    }
    const promise = isEditing
      ? updateUser( { id, fields: values } ).unwrap()
      : createUser( values as CreateUserFields ).unwrap();
    promise.then( () => {
      navigate( -1 );
    } );
  };
  
  return (
    <>
      <h1 className={styles['title']}>Edit Profile</h1>
      <p className={styles['hint']}>Fill the form and click submit</p>
      <Formik
        initialValues={{ recipients: recipients,...initialValues }}
        onSubmit={onSubmit}
        validationSchema={isEditing ? editUserSchema : createUserSchema}
        validateOnMount={true}
      >
        <Form className={styles['form']}>
          <LabeledField label='Name*'>
            <TextInput
              name='name'
              placeholder='Enter Name'
            />
          </LabeledField>
          {currentUser.data?.role === 'admin' && (
            <LabeledField style={ { visibility: 'hidden' } } label='Which team is this user associated with?'>
              <SelectField
                isDisabled={true}
                name=''
                options={currentCompany.data?.teams.map( team => ( { label: team.name, value: team.name } ) ) || []}
                create
              />
            </LabeledField>
          )}
          <LabeledField label='Email*'>
            <TextInput
              name='email'
              placeholder='Enter e-mail'
              type='email'
            />
          </LabeledField>
          {currentUser.data?.role === 'admin' && (
            <LabeledField label='Role'>
              <SelectField
                name='role'
                options={[ 'admin', 'manager' ].map( role => ( { label: role, value: role } ) )}
              />
            </LabeledField>
          )}
          {/* <LabeledField label='Description' style={{ width: 'calc(100% - 24px)', visibility: 'hidden' }}>
            <TextArea name='description' placeholder='Enter description' />
          </LabeledField> */}
          {isCurrentUser && <LabeledField label='Email report recipients' style={{ width: 'calc(100% - 24px)' }}>
            <TextAreaChange
              name='recipients'
              placeholder='Recipient can be added by separating emails by comma or line break'
              handleChange={( v: any ) => {
                setRecipients( v );
              }}
              handleChangeV={( v: any ) => {
                setRecipientsInitial( v );
              }}
              inputValue={recipientsInitial}
            />
                      {emailValidationFailed && ( // Display error message if email validation fails
            <div className={styles['error-message']}>
              Invalid email format. Please enter valid email addresses.
            </div>
          )}
          </LabeledField>}
          {/* {!isEditing && (
            <>
              <LabeledField label='Password*' style={{ marginLeft: 0, marginRight: 24 }}>
                <TextInput
                  name='password'
                  placeholder='Enter password'
                  type='password'
                />
              </LabeledField>
              <LabeledField label='Confirm Password*'>
                <TextInput
                  name='confirmPassword'
                  placeholder='Confirm password'
                  type='password'
                />
              </LabeledField>
            </>
          )} */}
          <Button
            text='Save' 
            type='submit' 
            styleType='nice' 
            style={{ width: 150 }}
          />
          {isCurrentUser && (
            <div className={styles['change-password']}>
              <LabeledField label='Change Password'>
                <TextInputView 
                  name='change-password' 
                  onChange={onNewPasswordChange} 
                  value={newPassword}
                  placeholder='Type new password here'
                  type='password'
                />
              </LabeledField>
              <Button
                text='Change password'
                styleType='nice'
                style={{ width: 200 }}
                disabled={!( newPassword && newPassword.length > 5 )}
                onClick={onNewPasswordClick}
              />
              <ResponseMessage
                isSuccess={isSuccess}
                isError={isError}
                successLabel={'Password changed'}
                style={{ width: 'auto', marginLeft: 24 }}
                className={styles['response']}
              />
            </div>
          )}
          
        </Form>
      </Formik>
    </>
  );
};