
import { useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './Select.sass';

const themeColors = {
  primary: '#f26529',
  primary75: '#f28c61',
  primary50: '#f7ccba',
  primary25: '#f7ccba',
  danger: '#EF4444',
  dangerLight: '#FCA5A5',
  neutral90: '#3F3F46',
  neutral80: '#3F3F46',
  neutral70: '#52525B',
  neutral60: '#71717A',
  neutral50: '#A1A1AA',
  neutral40: '#D4D4D8',
  neutral30: '#E4E4E7',
  neutral20: '#E4E4E7',
  neutral10: '#F4F4F5',
  neutral5: '#FAFAFA',
  neutral0: '#fff',
};

export interface SelectProps {
  options: {
    label: string;
    value: string;
  }[],
  onChange: ( value: string ) => void
  placeholder?: string,
  defaultValue?: string,
  label?: string,
  big?: true,
  create?: boolean
  width?: string,
  isDisabled?: boolean
}

export const Select = (
  { options, placeholder, onChange, defaultValue, label, big, create, width, isDisabled } : SelectProps
) => {

  const SelectElement = useMemo( () => create ? CreatableSelect : ReactSelect, [ create ] );

  useEffect( () => {
    defaultValue && onChange( defaultValue );
  }, [ defaultValue ] );

  return (
    <div className="select-input">
      {label && <label>{label}</label>}
      <SelectElement
        className='select'
        options={options}
        theme={( base ) => ( {
          ...base,
          colors: themeColors,
        } )}
        components={{
          IndicatorSeparator: null
        }}
        styles={{
          control: base => ( { 
            ...base, 
            height: big ? '48px' : base.height, 
            border: '1px solid #d8d5de',
            background: '#f9f8fb',
            ':focus-within': {
              borderColor: 'var(--gray400)',
              backgroundColor: '#fff',
              boxShadow: 'none'
            },
            width: width? `${width}`: 'auto',
            padding: '4px 12px',
            borderRadius: '4px',
            marginBottom: '8px'
          } ),
          singleValue: base => ( {
            ...base,
            overflow: 'visible'
          } )
        }}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={value => value && onChange( value?.value )}
        defaultValue={defaultValue && options.find( opt => opt.value === defaultValue ) || undefined}
      />
    </div>
  );
  
};