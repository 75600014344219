import { IndexRouteProps, LayoutRouteProps, PathRouteProps, Route } from 'react-router-dom';
import { ReactComponent as ArchiveIcon } from '../assets/icons/supervisor_account.svg';
import { ReactComponent as CompetencyIcon } from '../assets/icons/Vector.svg';
import { ReactComponent as DashboardIcon } from '../assets/icons/icon-dashboard.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/icon-logout.svg';
import { ReactComponent as RegistrationIcon } from '../assets/icons/icon-registration.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/icon-profile.svg';
import { ReactComponent as UsersIcon } from '../assets/icons/icon-users.svg';
import { ReactComponent as CreateModelIcon } from '../assets/icons/icon-create-competency.svg';
import { ReactComponent as MarkEmailRead } from '../assets/icons/mark_email_read.svg';
import { ReactComponent as ModelTraining } from '../assets/icons/model_training.svg';
import { ReactComponent as Instructionicon  } from '../assets/icons/instruction.svg';
import { ReactComponent as Billing } from '../assets/icons/Billing.svg';
import { NavigationItemProps } from '../components/global';
import { useState } from 'react' ;
import {
  CulturalAssessmentArchiveScreen,
  CulturalAssessmentEditScreen, CulturalAssessmentFitTechListScreen, CulturalAssessmentGameScreen,
  CulturalAssessmentListScreen, CulturalAssessmentResultScreen, CulturalAssessmentScreen,
  CurrentUserProfileScreen,
  Dashboard,
  FitTechResultsScreen,
  FitTechScreen,
  GetStartedScreen, LoginScreen, PaymentScreen, RegistrationScreen, ProfileScreen, RestorePasswordConfirmScreen, RestorePasswordInitScreen, TeamsScreen, UserEditScreen, UsersScreen, VerifyemailScreen, FitTechthankyou, UserCreateScreen
} from '../components/screens';
import { AppSliceState } from '../state';
import { MainRouter } from './MainRouter'; 
import { BeforeStart } from '../components/screens/FitTechScreen/BeforeStart';
import { WelcomeCard } from '../components/screens/CulturalAssessmentGameScreen/WelcomeCard';
import { CulturalAssessmentInstructionsScreen } from '../components/screens/CulturalAssessmentInstructionsScreen';
import { FitTechInstructionsScreen } from '../components/screens/FitTechInstructionsScreen';
import { FitTechSubmissionsScreen } from '../components/screens/FitTechSubmissionsScreen';
import { GetHelpScreen } from '../components/screens/GetHelpScreen';


export type RoutesInfo = ( PathRouteProps | LayoutRouteProps | IndexRouteProps )[];

export const routesPaths = {
  login: '/login',
  guestHome: '/',
  verifyemail: '/verifyemail/:email/:status',
  getStarted: '/get-started',
  dashboard: '/dashboard',
  // culturalAssessments: '/cultural-assessments',
  createCulturalAssessment: '/cultural-assessment/create', //'/cultural-assessment/create'
  editCulturalAssessment: '/cultural-assessments/:id/edit',
  game: '/game/:token/*',
  gameWelcome: '/game/welcome/*',
  assessmentResult: '/Model',//'/'/cultural-assessments/:id'
  users: '/users',
  editUser: '/users/:id/edit',
  createUser: '/users/create',
  profile: '/users/:id',
  getHelp: '/GetHelp',
  myProfile: '/profile', //'/profile'
  teams: '/teams',
  culturalAssessmentSingle: '/cultural-assessments/result',
  culturalAssessmentInstructionsScreen: '/',
  fitTech: '/hiringAssessment/*',
  fitTechBefor: '/fitTechBefor/*',
  fitTechInstructions: '/assessments/Instructions',
  fitTechSubmissions: '/assessments/Submissions',
  fitTechResults: '/assessments/hiringAssessment',
  archivedAssessments: '/archive',
  assessmentThank: '/hiringAssessment-thankyou',
  payment: '/assessments/payment',
  passwordRestoreInit: '/restore-password',
  passwordRestoreConfirm: '/restore-password/confirm/*'
};

export const routesTitles: {
  [routeName in keyof typeof routesPaths]: string
} = {
  assessmentThank: '',
  guestHome: 'Registration',
  getStarted: 'Start Your Cultural Assessment',
  login: 'Login',
  verifyemail: 'verifyemail',
  dashboard: 'Dashboard',
  // culturalAssessments: 'View Cultural Assessments',
  createCulturalAssessment: 'Create Cultural Assessment',
  editCulturalAssessment: 'Edit Cultural Assessment Model',
  game: 'Game',
  gameWelcome: 'Game',
  culturalAssessmentSingle: 'Cultural Assessment',
  culturalAssessmentInstructionsScreen: 'Cultural Assessment Instructions',
  users: 'Users',
  editUser: 'Edit User',
  createUser: 'Create User',
  profile: 'Profile',
  getHelp: 'Get Help',
  myProfile: 'Account',
  teams: 'Teams',
  assessmentResult: 'Cultural Assessment Result',
  fitTechBefor: 'Hiring Assessment',
  fitTech: 'Hiring Assessment',
  fitTechInstructions: 'Hiring Assessment Instructions',
  fitTechSubmissions: 'Hiring Assessment Submission',
  fitTechResults: 'Hiring Assessment',
  archivedAssessments: 'Archive',
  // assessmentFitTechList: 'FitTech',
  payment: 'Hiring Assessment Payment',
  passwordRestoreConfirm: 'Restore Password',
  passwordRestoreInit: 'Restore Password'
};

export const rootRoutesAuthorized: RoutesInfo = [
  
  {
    path: '*',
    element: <MainRouter />
  },
  {
    path: routesPaths.game,
    element: <CulturalAssessmentGameScreen/>
  },
  {
    path: routesPaths.gameWelcome,
    element: <WelcomeCard />
  },
  {
    path: routesPaths.fitTech,
    element: <FitTechScreen />
  },
  {
    path: routesPaths.assessmentThank,
    element: <FitTechthankyou />
  },
  {
    path: routesPaths.fitTechBefor,
    element: <BeforeStart />
  },
];

export const rootRoutesUnauthorized: RoutesInfo = [
  {
    path:  routesPaths.verifyemail,
    element: <VerifyemailScreen />
  },
  {
    path: routesPaths.login,
    element: <LoginScreen />,
  },
  {
    path: routesPaths.passwordRestoreInit,
    element: <RestorePasswordInitScreen />
  },
  {
    path: routesPaths.passwordRestoreConfirm,
    element: <RestorePasswordConfirmScreen />
  },
  {
    path: routesPaths.guestHome,
    element: <RegistrationScreen />,
  },
  ...rootRoutesAuthorized
];

export const unauthorizedRoutes: RoutesInfo = [

  // {
  //   path: routesPaths.guestHome,
  //   element: <GetStartedScreen />,
  // },
  // {
  //   path: routesPaths.guestHome,
  //   element: <>Welcome Guest!</>,
  // },

];

export const authorizedRoutes: ( role: 'admin' | 'contributor' | 'manager' ) => RoutesInfo = ( role ) => {

  let routes = [
    {
      path: routesPaths.myProfile,
      element: <CurrentUserProfileScreen />
    },
    {
      path: routesPaths.dashboard,
      element: <Dashboard />
    },

    {
      path: routesPaths.profile,
      element: <ProfileScreen />
    },
    {
      path: routesPaths.getHelp,
      element: <GetHelpScreen />
    },
  ];

  if ( role === 'admin' ) {
    routes = routes.concat( [
      {
        path: routesPaths.createUser,
        element: <UserCreateScreen />
      },
      {
        path: routesPaths.teams,
        element: <TeamsScreen />
      },
      {
        path: routesPaths.archivedAssessments,
        element: <CulturalAssessmentArchiveScreen />
      }
    ] );
  }
  if ( role !== 'contributor' ) {
    routes = routes.concat( [
      {
        path: routesPaths.assessmentResult,
        element: <CulturalAssessmentResultScreen />
      },
      {
        path: routesPaths.fitTechResults,
        element: <FitTechResultsScreen />
      },
      // {
      //   path: routesPaths.culturalAssessments,
      //   element: <CulturalAssessmentListScreen />
      // },
      {
        path: routesPaths.createCulturalAssessment,
        element: <CulturalAssessmentEditScreen />
      },
      {
        path: routesPaths.editCulturalAssessment,
        element: <CulturalAssessmentEditScreen />
      },
      {
        path: routesPaths.culturalAssessmentSingle,
        element: <CulturalAssessmentScreen />
      },
      {
        path: routesPaths.culturalAssessmentInstructionsScreen,
        element: <CulturalAssessmentInstructionsScreen />
      },
      {
        path: routesPaths.users,
        element: <UsersScreen />
      },
      {
        path: routesPaths.editUser,
        element: <UserEditScreen />
      },
      {
        path: routesPaths.fitTechInstructions,
        element: <FitTechInstructionsScreen />
      },
      {
        path: routesPaths.fitTechSubmissions,
        element: <FitTechSubmissionsScreen />
      },
      // {
      //   path: routesPaths.assessmentFitTechList,
      //   element: <CulturalAssessmentFitTechListScreen />
      // },
      {
        path: routesPaths.payment,
        element: <PaymentScreen />
      },
      
    ] );
  }

  return routes;

};

export const renderRoutes = ( routes: RoutesInfo ) => {
  return routes.map( ( route, index ) => <Route key={index} {...route} /> );
};

export const unauthorizedNavigationItems: NavigationItemProps[] = [
  { icon: RegistrationIcon, label: 'Registration', to: routesPaths.guestHome },
  { icon: LogoutIcon, label: 'Login', to: routesPaths.login },
];

export const authorizedNavigationItems: (
  role: 'admin' | 'contributor' | 'manager', appState: AppSliceState
) => NavigationItemProps[] = ( role, appState ) => {

  let items: NavigationItemProps[] = [
    // { icon: DashboardIcon, label: 'Dashboard', to: routesPaths.dashboard },
  ]; 

  if ( role !== 'contributor' ) {
    if ( appState.isShown ) {
      if ( appState.hasFitTech ) {
        items = items.concat( [
          {
            icon: CompetencyIcon,
            label: 'Culture',
            to: routesPaths.culturalAssessmentInstructionsScreen,
            isActive: window.location.pathname === routesPaths.culturalAssessmentSingle || window.location.pathname === routesPaths.assessmentResult || window.location.pathname === routesPaths.assessmentResult,
            subItems: [
              {
                icon: Instructionicon,
                label: 'Instructions',
                to: routesPaths.culturalAssessmentInstructionsScreen,
              },
              {
                icon: ModelTraining,
                label: 'Model',
                to: routesPaths.assessmentResult,
              },
              {
                icon: MarkEmailRead,
                label: 'Submissions',
                to: routesPaths.culturalAssessmentSingle,
              }
            ]
          },
          { icon: ArchiveIcon, label: 'Hiring Assessment', to: routesPaths.fitTechResults },
          { icon: Billing, label: 'Billing', to: routesPaths.payment, disabled: false },

        ] );
      } else {
        items = items.concat( [
          {
            icon: CompetencyIcon,
            label: 'Culture',
            to: routesPaths.culturalAssessmentInstructionsScreen,
            isActive: window.location.pathname === routesPaths.culturalAssessmentSingle || window.location.pathname === routesPaths.assessmentResult || window.location.pathname === routesPaths.assessmentResult,
            subItems: [
              {
                icon: Instructionicon,
                label: 'Instructions',
                to: routesPaths.culturalAssessmentInstructionsScreen,
              },
              {
                icon: ModelTraining,
                label: 'Model',
                to: routesPaths.assessmentResult,
              },
              {
                icon: MarkEmailRead,
                label: 'Submissions',
                to: routesPaths.culturalAssessmentSingle,
              }
            ]
          },
        ] );
      }
    } else {
      if ( appState.hasFitTech ) {
        items = items.concat( [
          {
            icon: CompetencyIcon,
            label: 'Culture',
            to: routesPaths.culturalAssessmentInstructionsScreen,
            isActive: window.location.pathname === routesPaths.culturalAssessmentSingle || window.location.pathname === routesPaths.assessmentResult || window.location.pathname === routesPaths.culturalAssessmentInstructionsScreen, 
            subItems: [
              {
                icon: Instructionicon,
                label: 'Instructions',
                to: routesPaths.culturalAssessmentInstructionsScreen,
              },
              {
                icon: ModelTraining,
                label: 'Model',
                to: routesPaths.assessmentResult,
              },
              {
                icon: MarkEmailRead,
                label: 'Submissions',
                to: routesPaths.culturalAssessmentSingle,
              }
            ]
          },
          { 
            icon: ArchiveIcon, label: 'Hiring', to: routesPaths.fitTechInstructions,
            isActive: window.location.pathname === routesPaths.fitTechInstructions || window.location.pathname === routesPaths.fitTechResults || window.location.pathname === routesPaths.fitTechSubmissions,
            subItems: [
              {
                icon: Instructionicon,
                label: 'Instructions',
                to: routesPaths.fitTechInstructions,
              }, 
              {
                icon: ModelTraining,
                label: 'Assessment',
                to: routesPaths.fitTechResults,
              }, 
              // {
              //   icon: MarkEmailRead,
              //   label: 'Submissions',
              //   to: routesPaths.fitTechSubmissions,
              // }, 
            ]
          },
          { icon: Billing, label: 'Billing', to: routesPaths.payment, disabled: false },
        ] );
      } else {
        items = items.concat( [
          {
            icon: CompetencyIcon,
            label: 'Culture',
            to: routesPaths.culturalAssessmentInstructionsScreen,
            isActive: window.location.pathname === routesPaths.culturalAssessmentSingle || window.location.pathname === routesPaths.assessmentResult || window.location.pathname === routesPaths.culturalAssessmentInstructionsScreen,
            subItems: [
              {
                icon: Instructionicon,
                label: 'Instructions',
                to: routesPaths.culturalAssessmentInstructionsScreen,
              },
              {
                icon: ModelTraining,
                label: 'Model',
                to: routesPaths.assessmentResult,
              },
              {
                icon: MarkEmailRead,
                label: 'Submissions',
                to: routesPaths.culturalAssessmentSingle,
              }
            ]
          },
        ] );
      }
    }
  }

  if ( role === 'admin' ) {
    items = items.concat( [
      // { icon: UsersIcon, label: 'Teams', to: routesPaths.teams },
      // { icon: UsersIcon, label: 'Users', to: routesPaths.users },
      // { icon: ArchiveIcon, label: 'Archive', to: routesPaths.archivedAssessments },
    ] );
  }

  // items.push(
  //   { icon: ProfileIcon, label: 'Account', to: routesPaths.myProfile },
  // );

  return items;
};

export const noBackgroundPaths = [
  routesPaths.fitTechResults,
  routesPaths.assessmentResult,
  routesPaths.culturalAssessmentSingle,
  routesPaths.culturalAssessmentInstructionsScreen,
  routesPaths.dashboard,
  routesPaths.payment
];