import ReactModal from 'react-modal';
import { Button } from '../../../controls';
import styles from './SuccessModal.module.sass';

export interface SuccessModalProps {
  onConfirm: () => void,
  isOpen: boolean,
  text: string
}

export const SuccessModal = ( { onConfirm, isOpen, text } : SuccessModalProps ) => {

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: 400,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: 300,
          padding: 0,
          border: 'none',
          background: 'none',
          display: 'flex',
          flexDirection: 'column'
        },
        overlay: {
          background: 'rgba(0,0,0,.5)'
        }
      }}
      appElement={document.getElementById( 'root' ) as HTMLDivElement}
    >
      <div className={styles['header']}>
        Success
      </div>
      <div className={styles['body']}>
        <p>{text}</p>
        <Button text='OK' onClick={onConfirm} styleType='nice' />
      </div>
    </ReactModal>
  );
};