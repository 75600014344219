
import { Search } from './Search';
import './ListControls.sass';
import { Select, SelectProps } from '../Controls/Select/';

export interface ListControlsProps {
  onSearch?: ( term: string ) => void,
  filters?: Record<string, SelectProps>
}

export const ListControls = (
  { filters, onSearch } : ListControlsProps
) => {

  return (
    <div className="list-controls">
      {filters && Object.values( filters ).map( ( props, index ) => (
        <Select key={index} {...props} big />
      ) )}
      {onSearch && <Search onChange={onSearch} />}
    </div>
  );
};