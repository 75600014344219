import { FitTechSubmissionSort } from './types';


const byFirstName : FitTechSubmissionSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.firstName > b.firstName ? 1 : -1 )
    : items.sort( ( a,b ) => a.firstName < b.firstName ? 1 : -1 );
};

const byLastName : FitTechSubmissionSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.lastName > b.lastName ? 1 : -1 )
    : items.sort( ( a,b ) => a.lastName < b.lastName ? 1 : -1 );
};

const byEmail : FitTechSubmissionSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.email > b.email ? 1 : -1 )
    : items.sort( ( a,b ) => a.email < b.email ? 1 : -1 );
};

const byScore : ( index: number ) => FitTechSubmissionSort = ( index ) => ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.scores[index].score > b.scores[index].score ? 1 : -1 )
    : items.sort( ( a,b ) => a.scores[index].score < b.scores[index].score ? 1 : -1 );
};

const bySubmitDate : FitTechSubmissionSort = ( items, order = 'desc' ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.createdAt > b.createdAt ? 1 : -1 )
    : items.sort( ( a,b ) => a.createdAt < b.createdAt ? 1 : -1 );
};

const byOverallScore : FitTechSubmissionSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.overallScore > b.overallScore ? 1 : -1 )
    : items.sort( ( a,b ) => a.overallScore < b.overallScore ? 1 : -1 );
};



export const fitTechSubmissionsSortings = {
  byFirstName,
  byLastName,
  byEmail,
  byScore,
  bySubmitDate,
  byOverallScore
};