import { createApi } from '@reduxjs/toolkit/query/react';
import Stripe from 'stripe';
import { axiosBaseQuery } from '../baseInstance';
import { CheckoutSessionResponse, PaymentIntentResponse, RefundPaymentIntentResponse } from '../models';

export const paymentApi = createApi( {
  baseQuery: axiosBaseQuery,
  reducerPath: 'payments',
  tagTypes: [ 'fitTech' ],
  endpoints: build => ( {
    getFitTechPaymentSecret: build.query<PaymentIntentResponse, string>( {
      query: assessmentId => ( {
        url: '/payments/fitTech',
        params: { assessmentId },
        method: 'get'
      } ),
      providesTags: ( res, err, id ) => err ? [] : [ { type: 'fitTech', id } ]
    } ),
    fitTechRefund: build.mutation<RefundPaymentIntentResponse, string>( {
      query: assessmentId => ( {
        url: '/payments/fitTech/refund',
        params: { assessmentId },
        method: 'post'
      } ),
      invalidatesTags: ( res, err, id ) => err ? [] : [ { type: 'fitTech', id } ]
    } ),

    checkoutSession: build.query<CheckoutSessionResponse, string>( {
      query: assessmentId => ( {
        url: '/payments/checkout-session',
        params: { assessmentId },
        method: 'post'
      } ),
      providesTags: ( res, err, id ) => err ? [] : [ { type: 'fitTech', id } ]
    } )

  } ),
  
} );

export const {
  useGetFitTechPaymentSecretQuery,
  useFitTechRefundMutation,
  useCheckoutSessionQuery
} = paymentApi;