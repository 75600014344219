import { ReactComponent as DividerIcon } from '../../../assets/icons/divider.svg';
import styles from './CulturalAssessmentGameScreen.module.sass';


export const EssentialsMinimumCard = () => {


  return (
    <div className={[
      styles['card'], styles['no-drag']
    ].join( ' ' )}>
      <h1>Minimum not reached!</h1>
      <DividerIcon className={styles['divider']} />
      <p className={styles['description']}>
        Essential bucket requires minimum 12 cards, please reassign some of the cards.
      </p>
    </div>
  );
};