import axios from 'axios';
import { routesPaths } from '../navigation/routes';
import { store } from '../state';
import { authActions } from '../state/slices/authSlice';
import { usersApi } from './apis';
import { authorizedAxiosInstance, baseUrl } from './baseInstance';
import { getHelpFields, isServerResponse, LoginFields, RegistrationFields, RegistrationFormFields, ServerResponseError, TokensPair, UploadFileResponse } from './types';



export const authService = {
  register,
  registration,
  sendHelpRequest,
  login,
  Emailverify,
  resendEmailverify,
  logout,
  loginAdmin,
  logoutAdmin,
  validate,
  refresh,
  restorePasswordInit,
  restorePasswordConfirm,
  restorePasswordValidateToken,
  restorePasswordUserEmail
};

const unauthorizedClient = axios.create( {
  baseURL: baseUrl
} );

async function register ( registerFields: RegistrationFields ) {

  const logoResponse = registerFields.logo 
    ? await uploadCompanyLogo( registerFields.logo, registerFields.companyName )
    : { url: '' };
  if ( !logoResponse ) {
    store.dispatch( authActions.setError( 'Could not upload logo' ) );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { logo, ...fields } = registerFields;
    const response = await unauthorizedClient.post<TokensPair | ServerResponseError>( 
      '/auth/register',
      { ...fields, logoUrl: logoResponse.url }
    );
    if ( response.status === 200 && !isServerResponse( response.data ) ) {
      store.dispatch( authActions.setAccessToken( response.data.accessToken ) );
      store.dispatch( authActions.setRefreshToken( response.data.refreshToken ) );
      return true;
    } else {
      const error = isServerResponse( response.data ) 
        ? response.data.message
        : 'Failed to register';
      store.dispatch( authActions.setError( error ) );
      return false;
    }
  }
  
}

async function registration ( registerFields: RegistrationFormFields ) {
  const { ...fields } = registerFields;
  const body = {
    userName: fields.firstName + ' ' + fields.lastName,
    userEmail: fields.emailAddress,
    userPassword: fields.password,
    companyName: fields.companyName,
    assessmentGroups: [],
    assessmentTargetParticipants: '0',
    cultureNeeds: 'fixed',
    logoUrl: '',
    siteUrl: ' ',
  };
    const response:any = await unauthorizedClient.post<TokensPair | ServerResponseError>( 
      '/auth/register',
      body
    ); 
    if ( response.status === 200 &&  response.data.message == 'email' ) {
      return { 'error':'email' };
    }
    if ( response.status === 200 && !isServerResponse( response.data ) ) {
      // store.dispatch( authActions.setAccessToken( response.data.accessToken ) );
      // store.dispatch( authActions.setRefreshToken( response.data.refreshToken ) );
      return response.data;
    } else {
      const error = isServerResponse( response.data ) 
        ? response.data.message
        : 'Failed to register';
      store.dispatch( authActions.setError( error ) );
      return false;
    }
  
}

async function resendEmailverify ( email: any ) {
  const response:any = await unauthorizedClient.post<TokensPair | ServerResponseError>( 
    '/auth/resendEmailverify', 
    { email:email }
  ); 
  if ( response.status === 200 &&  response.data ) {    
    return response.data ; 
  }
}

async function Emailverify ( fields: any ) {
  const response:any = await unauthorizedClient.post<TokensPair | ServerResponseError>( 
    '/auth/verifyemail', 
    fields
  ); 
  if ( response.status === 200 &&  response.data ) {    
    store.dispatch( authActions.setAccessToken( response.data.tokensPair.accessToken ) );
    store.dispatch( authActions.setRefreshToken( response.data.tokensPair.refreshToken ) ); 
    usersApi.util.invalidateTags( [ 'current' ] );
    return true ; 
  } else if ( response.status === 201 ) {
    return response.data;
  }
}

async function login ( loginFields: LoginFields ) {
  const response:any = await unauthorizedClient.post<TokensPair | ServerResponseError>( 
    '/auth/login', 
    loginFields
  );
  if ( response.status === 200 &&  response.data.message == 'password' ) {
    return { 'error':'password' };
  }
  if ( response.status === 200 && !isServerResponse( response.data ) ) {
    if ( response.data.user.verifiedat !== null ) {
      store.dispatch( authActions.setAccessToken( response.data.tokensPair.accessToken ) );
      store.dispatch( authActions.setRefreshToken( response.data.tokensPair.refreshToken ) );
      usersApi.util.invalidateTags( [ 'current' ] );
      return true;
    } else {
      return response.data.user;
    }
  } else {
    const error = isServerResponse( response.data ) 
      ? response.data.message
      : 'Failed to login';
    store.dispatch( authActions.setError( error ) );
    return false;
  }
}

async function sendHelpRequest ( fields: getHelpFields ) {
  const response:any = await unauthorizedClient.post<TokensPair | ServerResponseError>(
    '/users/sendHelpRequest',
    fields
  );
  console.log( 'response ====> ',response );

} 


async function loginAdmin ( id: string ) {
  console.log( 'id', id );
  const response:any = await unauthorizedClient.post<TokensPair | ServerResponseError>( 
    '/auth/loginAdmin', 
    { id }
  );
  if ( response.status === 200 && !isServerResponse( response.data ) ) {
    if ( response.data.user.verifiedat !== null ) {
      store.dispatch( authActions.setAccessToken( response.data.tokensPair.accessToken ) );
      store.dispatch( authActions.setRefreshToken( response.data.tokensPair.refreshToken ) );
      usersApi.util.invalidateTags( [ 'current' ] );
      return true;
    } else {
      return response.data.user;
    }
  } else {
    const error = isServerResponse( response.data ) 
      ? response.data.message
      : 'Failed to login';
    store.dispatch( authActions.setError( error ) );
    return false;
  }
}

async function validate () {
  try {
    const { data } = await authorizedAxiosInstance.get( '/auth/valid' );
    if ( data ) store.dispatch( authActions.setIsAuthorized( true ) );
    else logout();
  } catch ( error ) {
    logout();
  } finally {
    store.dispatch( authActions.setValidated( true ) );
  }
}

function logout () {
  store.dispatch( authActions.setIsAuthorized( false ) );
  localStorage.clear();
  window.location.reload();
}

function logoutAdmin () {
  const baseUrl = 'https://hire.staffgeek.com';
  window.location.href = `${baseUrl}/users`;
 setTimeout( ()=>{
  localStorage.clear();
  store.dispatch( authActions.setIsAuthorized( false ) );
 }, 25 );
}

async function refresh() {
  try {
    const refreshToken = store.getState().authSlice.refreshToken;
    if ( refreshToken ) {
      const { data, status } = await unauthorizedClient.post( '/auth/refresh', { refreshToken } );
      if ( status === 200 ) {
        store.dispatch( authActions.setAccessToken( data.accessToken ) );
        store.dispatch( authActions.setRefreshToken( data.refreshToken ) );
      }
      else logout();
    } else {
      logout();
    }
  } catch {
    logout();
  }
}

async function uploadCompanyLogo( file: File, companyName: string ) {

  const formData = new FormData();
  formData.append( 'file', file );
  formData.append( 'companyName', companyName );

  const { data, status } = await unauthorizedClient.post( '/upload/companyLogo', formData );

  if ( status !== 200 ) return null;
  return data as UploadFileResponse;
}

async function restorePasswordInit ( email: string ) {
  try {
    const { status } = await unauthorizedClient.post( '/users/restorePassword/init', {
      email, redirectUrl: `${window.location.origin}${routesPaths.passwordRestoreConfirm.replace( '/*', '' )}`
    } );
    
    return status === 200;
  } catch ( err: unknown ) {
    if ( axios.isAxiosError( err ) ) {
      return err.response?.data as ServerResponseError || false;
    }
    return false;
  }
}

async function restorePasswordConfirm ( token: string, newPassword: string ) {
  try {
    const { status } = await unauthorizedClient.post( '/users/restorePassword/confirm', {
      token, newPassword
    } );
    
    return status === 200;
  } catch ( err: unknown ) {
    if ( axios.isAxiosError( err ) ) {
      return err.response?.data as ServerResponseError || false;
    }
    return false;
  }
}
async function restorePasswordUserEmail ( token: string ) {
  try {
    const { data } = await unauthorizedClient.post( '/users/restorePasswordUserEmail', { token } ); 
    return data;
  } catch ( err: unknown ) {
    if ( axios.isAxiosError( err ) ) {
      return err.response?.data as ServerResponseError || false;
    }
    return false;
  }
}


async function restorePasswordValidateToken ( token: string ) {
  try {
    const { status } = await unauthorizedClient.post( '/users/restorePassword/validate', {
      token
    } );
    
    return status === 200;
  } catch ( err: unknown ) {
    if ( axios.isAxiosError( err ) ) {
      return err.response?.data as ServerResponseError || false;
    }
    return false;
  }
}
