import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { authService, isServerResponse, restorePasswordConfirmSchema } from '../../../services';
import loginScreenStyles from '../LoginScreen/LoginScreen.module.sass';
import {  Box, Button, Container, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import styles from './RestorePasswordConfirmScreen.module.sass'; 
import logo from '../../../assets/img/staffgeek_logo.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import vecPass from '../../../assets/img/vecPass.png'; 
import vecCheck from '../../../assets/img/vecCheck.png'; 
import { LoadingIndicator } from '../../ui';


export const RestorePasswordConfirmScreen = () => {

  const [ token, setToken ] = useState<string>();
  const [ isValid, setValid ] = useState<boolean>();
  const [ isSuccess, setSuccess ] = useState<boolean>();
  const [ showPassword, setshowPassword ] = useState<boolean>(  );
  const [ showConfirmPassword, setshowConfirmPassword ] = useState<boolean>( false );
  const [ email, setEmail ] = useState <string | undefined>();
  const [ emailStatus, setEmailStatus ] = useState <boolean>( false );
  
  console.log( 'showPassword',showPassword );
  console.log( 'showConfirmPassword',showConfirmPassword );
   
  const togglePasswordVisibility = () => {
    console.log( '*************************************************1111111111111111111111111111' );
    
    setshowPassword( !showPassword );
  };
  const toggleConfirmPasswordVisibility = () => {
    console.log( '*************************************************2222222222222222222222222222222' ); 
    setshowConfirmPassword( !showConfirmPassword );
  };

  useEffect( () => {
    const match = window.location.href.match( new RegExp( 
      `${routesPaths.passwordRestoreConfirm.replace( '/*', '' )}/(.*)` 
    ) );
    if ( match && match[1] ) {
      setToken( match[1] );
    }
  }, [] );

  useEffect( () => {
    if ( token ) {
      authService.restorePasswordValidateToken( token ).then( response => {
        if ( isServerResponse( response ) ) {
          setValid( false );
        } else {
          setValid( response );
        }
      } );
    }
  }, [ token ] );

    useEffect( () => {
      if ( token ) {
        authService.restorePasswordUserEmail( token )
          .then( response => {
            setEmail( response as any );
            setEmailStatus( true );
          } )
          .catch( error => {
            console.error( error );
          } );
      }
    }, [ token ] );


  const passwordsRequirements = ( password: string ) => {
    if ( password.length > 0 ) {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
      return regex.test( password );
    }
    return true;
  };
  
  const passwordsMatch = ( password: string, confirmPassword: string ) => {
    if ( confirmPassword.length > 0 ) {
      return password === confirmPassword;
    }
    return true;
  };
 
  const form = useMemo( () => (
    <Grid className={`${styles['grid']} ${styles['gridform']}`} item xs={6} >  
      { emailStatus === false ? 
        <LoadingIndicator style={{ width: 64, height: 64, margin: '32px auto', borderWidth: 6 }} />
       :   
    <Formik
      initialValues={{
        email: email,
        password: '',
        confirmPassword: ''
      }}
      onSubmit={( { password } ) => {
        if ( token ) {
          authService.restorePasswordConfirm( token, password ).then( response => {
            if ( isServerResponse( response ) ) {
              setSuccess( false );
            } else {
              setSuccess( response );
            }
          } );
        }
      }}
      validationSchema={restorePasswordConfirmSchema}
      >
        {( { handleSubmit, values, handleChange, errors ,isValid } ) => (

          < form onSubmit={handleSubmit}>
            <Container
              className={styles['container']}
              maxWidth="sm"
            >
              <Box className={styles['boxMargin']}>
                <img src={logo} width={220} className={styles['logo']}/>
                <Typography
                  className={`${styles['typography']} ${styles['typography2']}`}
                  variant="h4"
                  gutterBottom
                >
                  Password Reset
                </Typography> 
                <p className={styles['typography3']}>Please enter your new password.</p>
                <Box className={styles['boxMargin']}>
                    <TextField
                    disabled
                    label={'Email'}
                    name="email"
                    type= 'text'
                    variant="outlined"
                    value={`${ email }`}
                    fullWidth                     
                    className={styles['textField']}
                />
                <TextField
                        label="Password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        required
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        value={values.password}
                        error={
                          !passwordsRequirements( values.password )
                        }
                        helperText={
                          !passwordsRequirements( values.password ) &&
                          'Must contain minimum 6 characters including at least one letter, one number and one special character (@$!%*#?&)'
                        }
                        className={styles['textField']}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Confirm Password"
                        name="confirmPassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        required
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        value={values.confirmPassword}
                        error={
                          !passwordsMatch( values.password, values.confirmPassword )
                        }
                        helperText={
                          !passwordsMatch( values.password, values.confirmPassword ) &&
                          'Confirm password does not match'
                        }
                        className={styles['textField']}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={toggleConfirmPasswordVisibility}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
 
                
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    size="large"
                    fullWidth   
                    style={{ backgroundColor: isValid ? '#9200ff' : '#919eab6e' }} 
                    disabled={!isValid}
                    className={` ${styles['textField2']}`} >
                     Restore password
                  </Button> 
                    </Box>
              </Box>
            </Container>
          </form>
        )}
    </Formik>
}
    {/* <div style={{ width: '100%', height: '100px' }}>
      <div className={styles['divider']}>
        <div className={styles['line']}></div>
        <div className={styles['or']}>or</div>
        <div className={styles['line']}></div>
      </div> 
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p>Did not receive the email? <Button > Resend email</Button></p>
      </div>
    </div>   */}
    
</Grid>
  ), [ token, setSuccess, showConfirmPassword, showPassword, emailStatus ] );

  return (
     
    <Box className={styles['box']}>
    <Grid container className={`${styles['grid']} `}>
      <Grid className={`${styles['grid22']}`} item xs={6}  > 
        <Grid className={`${styles['grid33']}`} > 
          <img src={vecPass} className={styles['blob']}/> 
        </Grid>
      </Grid>

      <>
             {isValid ? (
        isSuccess === undefined ? (
          form
        ) : (
          isSuccess ? (
            <> 
            <Grid className={styles['messageSent']}> 
              <img src={logo} width={220} className={styles['logo2']}/>
              <h3  className={styles['messageSent_header']}>Password Reset</h3>
              <img src={vecCheck} className={styles['vacMess']}/> 
              <p className={styles['messageSent_text']}>Password changed successfully. Please <Link to={routesPaths.login} style={{ marginTop: 20, fontSize: '1.2em', color:'#9200ff' }}>Login</Link> with your new password</p>
            </Grid>
           </>
          ) : (
            <div className={loginScreenStyles['message']}>
              Some error occurred. Please reload the page and try again
            </div>
          )
        )
      ) : isValid === false ? (
        <div className={loginScreenStyles['message']}>
          The link is invalid
        </div>
      ) : null}
        </>
    </Grid> 
  </Box >
  );
};