import { IconType } from 'react-icons';
import './IconButton.css';

export interface IconButtonProps {
  icon: IconType,
  onClick?: ( e: React.MouseEvent | React.KeyboardEvent ) => void 
}

export const IconButton = ( { icon: Icon, onClick } : IconButtonProps ) => (
  <div className="icon-button" onClick={onClick}>
    <Icon />
  </div>
);