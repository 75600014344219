import { Button } from '../../controls';
import styles from './CulturalAssessmentGameScreen.module.sass';  
import { useState } from 'react' ;
import { RiArrowRightLine, RiArrowLeftLine } from 'react-icons/ri';

export interface GuideCardProps {
  onClick: () => void
}

export const GuideCard = ( { onClick } : GuideCardProps ) => {
  const [ steps, setSteps ] = useState( [ 1, 2, 3, 4 , 5 ] );
  const [ stepsTexts, setStepsTexts ] = useState( [ 'Review the content and start', 'Review each card and flip for more details', 'Drag and drop each card into your chosen bucket', 'Rank the cards in the Essential bucket by importance', 'Submit your culture assessment' ] );
  const [ currentStep, setCurrentStep ] = useState( 1 );

  


  return (
    <div className={[
      styles['card1'], styles['no-drag'], { background: 'unset !important' }
    ].join( ' ' )}>
      <h1 style={ { fontSize: 20, textAlign: 'start' } }>How to use the Culuture Assessment</h1>   
      <p className={styles['step']}>
        <span>Step {currentStep}:</span> {stepsTexts[ currentStep - 1 ]}
      </p>
      <div className={styles['step-card']}>
        { ' ' }
      </div>
      <div style={ { display: 'flex', justifyContent: 'space-between', width: '90%' } }>
        { currentStep > 1 ? <Button
            onClick={ () => setCurrentStep( currentStep - 1 ) }
            text='Previous Step'
            styleType='mainText'
            isChildrenBefore={true}
            disabled={ currentStep < 1  }
            children={<RiArrowLeftLine />}
            style={{ backgroundColor: 'transparent', fontSize: 12, width: '30%', padding: 0, textAlign: 'start' }} 
        /> : <div></div> }
        <Button
            onClick={ () => setCurrentStep( currentStep + 1 ) }
            text='Next Step'
            styleType='mainText'
            disabled={ currentStep === steps.length }
            children={<RiArrowRightLine />}
            style={{ backgroundColor: 'transparent', fontSize: 12, width: '20%', padding: 0, textAlign: 'end' }} 
        /> 
      </div>
      <div style={{ display:'flex',justifyContent:'center', width: '100%' }}>
        <Button
          onClick={onClick}
          text='Back to Culture Assessment'
          styleType='whiteText'
          style={{ backgroundColor: '#8250FF' , fontSize: 14, textTransform: 'uppercase', width: '90%' }} 
        /> 
      </div>
      <p><em>If you have any questions, please contact </em><a href="mailto:Support@StaffGeek.com">Support@StaffGeek.com</a>.</p>
    </div>
  );
};