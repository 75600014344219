import { CSSProperties, ReactChild, ReactNode } from 'react';
import styles from './Box.module.sass';

export interface BoxProps {
  children: ReactChild | ReactNode,
  style?: CSSProperties
}

export const Box = ( { children, style } : BoxProps ) => (
  <div className={styles['box']} style={style}>
    {children}
  </div>
);