import { SubmissionSort } from './types';


const byName : SubmissionSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.name > b.name ? 1 : -1 )
    : items.sort( ( a,b ) => a.name < b.name ? 1 : -1 );
};

const byEmail : SubmissionSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.email > b.email ? 1 : -1 )
    : items.sort( ( a,b ) => a.email < b.email ? 1 : -1 );
};


const byGroup : SubmissionSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.group > b.group ? 1 : -1 )
    : items.sort( ( a,b ) => a.group < b.group ? 1 : -1 );
};


const byCreationDate : SubmissionSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.createdAt > b.createdAt ? 1 : -1 )
    : items.sort( ( a,b ) => a.createdAt < b.createdAt ? 1 : -1 );
};


export const submissionsSortings = {
  byCreationDate,
  byName,
  byEmail,
  byGroup
};
