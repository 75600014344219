import { RiArrowUpSFill } from 'react-icons/ri';
import { TableListProps } from './TableList';
import styles from './TableList.module.sass';

export type TableListHeaderProps<Item extends object, CustomFields extends string = never> = 
  Pick<TableListProps<Item, CustomFields>, 'headings' | 'fieldsToDisplay' | 'editable' | 'onHeadingSort' | 'sortedBy'>

export const TableListHeader = <Item extends object, CustomFields extends string = never,>( 
  { headings, fieldsToDisplay, editable, onHeadingSort, sortedBy } : TableListHeaderProps<Item, CustomFields> 
) => (
  <thead>
    <tr>
      { editable && <td className={styles['controls-container']}></td> }
      {fieldsToDisplay.map( ( field, index ) => 
        <th 
          key={index} 
          onClick={onHeadingSort && ( () => onHeadingSort( field ) )}
          className={onHeadingSort && styles['sortable']}
        >
          {headings[field]}
          {sortedBy && sortedBy.col === field && (
            <RiArrowUpSFill
              className={[
                styles['sort-icon'],
                sortedBy.order === 'desc' ? styles['desc'] : undefined
              ].join( ' ' )}
            />
          )}
        </th> 
      )}
    </tr>
  </thead>
);