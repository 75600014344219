import { Formik } from 'formik';
import * as yup from 'yup';
import { useSetFitTechRoleMutation, useGetAssessmentByCompanyQuery } from '../../../services';
import { Button, TextInput } from '../../controls';
import { LabeledField } from '../../ui';
import { useEffect } from 'react';

export const FitTechSetRole = ( { refetchData }: { refetchData: () => void } ) => {

  const assessment = useGetAssessmentByCompanyQuery( '1' );
  const [ setFitTechRole ] = useSetFitTechRoleMutation();

  useEffect( () => {
    if ( !assessment.data?._id ) return;

    setFitTechRole( {
      assessmentId: assessment.data?._id,
      role: 'firstrole', // Set the role to 'firstrole'
    } );
    refetchData();
  }, [ assessment.data?._id, refetchData, setFitTechRole ] );

  return (
    <div style={{ display: 'none' }}>
    <Formik
      initialValues={{ role: '' }}
      onSubmit={( { role } ) => {
        if ( !assessment.data?._id ) return;
        setFitTechRole( {
          assessmentId: assessment.data?._id,
          role,
        } );
        refetchData();
      }}
      validationSchema={yup.object( { role: yup.string().required() } )}
    >
      {( { isValid, handleSubmit } ) => (
        <form onSubmit={handleSubmit}>
          <LabeledField label='What role are you hiring for?'>
            <TextInput
              name='role'
              styleType='standard'
              placeholder='Hiring Assessment role'
            />
          </LabeledField>
          <Button text='Confirm' type='submit' disabled={!isValid} styleType='nice' style={{ width: 'auto' }} />
        </form>
      )}
    </Formik>
    </div>
  );
};