import { ReactChildren, ReactNode } from 'react';
import styles from './LabeledField.module.sass';

export interface LabeledFieldProps {
  label: string,
  children: ReactChildren | ReactNode,
  id?: string,
  style?: React.CSSProperties
}

export const LabeledField = ( { label, children, id, style } : LabeledFieldProps ) => (
  <div className={styles['labeled-field']} style={style}>
    <label htmlFor={id}>{label}</label>
    {children}
  </div>
);