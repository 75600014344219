import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppSliceState } from './types';



const initialState : AppSliceState = {
  isShown: true,
  hasFitTech: false,
  title: undefined
};

export const appSlice = createSlice( {
  initialState,
  name: 'appSlice',
  reducers: {
    setIsShown: ( state, action: PayloadAction<boolean> ) => {
      state.isShown = action.payload;
    },
    setHasFitTech: ( state, action: PayloadAction<boolean> ) => {
      state.hasFitTech = action.payload;
    },
    setTitle: ( state, action: PayloadAction<AppSliceState['title']> ) => {
      state.title = action.payload;
    }
  }
} );

export const appActions = appSlice.actions;