const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString( value: unknown ): boolean {
  return !!value && typeof value === 'string' && isoDateFormat.test( value );
}

export function handleDates( body: unknown ) {
  if ( body === null || body === undefined || typeof body !== 'object' )
    return body;

  for ( const key of Object.keys( body ) ) {
    const value = ( body as Record<string, unknown> )[key];
    if ( isIsoDateString( value ) ) ( body as Record<string, unknown> )[key] = new Date( value as string );
    else if ( typeof value === 'object' ) handleDates( value );
  }
}