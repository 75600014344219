import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg';
import { ReactComponent as BannerIcon } from '../../../assets/img/banner-bg.svg';
import vecAssfrom from '../../../assets/img/vecAss.png';
import { routesPaths } from '../../../navigation/routes';
import { useGetAssessmentShareTokenQuery, useGetAssessmentSubmissionsQuery, useGetCurrentCompanyQuery, useGetAssessmentByCompanyQuery, useGetFitTechSubmissionsQuery, useFitTechRefundMutation } from '../../../services';
import { CulturalAssessmentSubmission } from '../../../services/models';
import { useClickAwayListener, useScreenTitle } from '../../../utils';
import { submissionsSortings } from '../../../utils/sortFunctions';
import { Button } from '../../controls';
import { LoadingIndicatorBox, PopUp, ProgressCircle, TableListQuery } from '../../ui';
import styles from './CulturalAssessmentScreen.module.sass';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appActions } from '../../../state';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../state';
import { FiEye } from 'react-icons/fi';
import ReactModal from 'react-modal';

export const CulturalAssessmentScreen = (  ) => {

  const modalStyles : ReactModal.Styles = {
    content: { maxWidth: '420px', left: '50%', transform: 'translateX(-50%)', top: 20 }
  };

  const [ id, setId ] = useState( '' );
  const appState = useTypedSelector( state => state.appSlice );

  const navigate = useNavigate(  );
  const dispatch = useDispatch(  );

  const [ isResultsPopupOpen, setResultsPopupOpen ] = useState( false );  
  const resultsPopupRef = useRef<HTMLElement>( null );
  const sharePopupRef = useRef<HTMLElement>( null ); 
  const [ isCopied, setCopied ] = useState( false );
  // const submissions = useGetAssessmentSubmissionsQuery( id || skipToken );
  const { data : submissions , refetch } = useGetAssessmentSubmissionsQuery( id || skipToken ); 
 
  const [ fitTechRefund ] = useFitTechRefundMutation();

  const handleRefundClick = async () => {
    fitTechRefund( id ).then( () => {
      navigate( routesPaths.fitTechInstructions );
      window.location.replace( window.location.href );
      window.location.reload();
    } );
  };

  // useEffect( () => { 
  //   const intervalId = setInterval( () => {  
  //     refetch();
  //   }, 30000 ); 
 
  //   return () => clearInterval( intervalId );
  // }, [] );

  const shareToken = useGetAssessmentShareTokenQuery( id || skipToken ); 
  const currentCompany = useGetCurrentCompanyQuery(  );
  const assessment = useGetAssessmentByCompanyQuery( '1' ); 

  useEffect( (  ) => {
    console.log( 'submissions', submissions );
    console.log( 'submissions length', submissions?.length );

    assessment.refetch(  );
  }, [  ] );

  useEffect( (  ) => {
    refetch();
  }, [ id ] );

  const screenTitle = useMemo( (  ) => {
    if ( assessment.data?._id && assessment.data?.targetParticipants > 0 ) {
      dispatch( appActions.setIsShown( false ) );
      setId( assessment.data?._id );
    } else {
      if ( !assessment.isFetching && !assessment.isLoading && appState.isShown ) {
        setTimeout( (  ) => {
          navigate( routesPaths.createCulturalAssessment );
        }, 500 );
      }
    }
    if ( !currentCompany.data || !assessment.data ) return '';
    if ( assessment.data.groups && !assessment.data.groups.length ) return currentCompany.data.name;
    return `${currentCompany.data.name} - ${assessment.data.groups ? assessment.data.groups.join( ', ' ) : ''}`;
  }, [ currentCompany.data, assessment ] );

  useScreenTitle( screenTitle );

  const assessmentUrl = useMemo( () => (
    typeof shareToken.data === 'string'
      ? window.location.origin + routesPaths.fitTechBefor.replace( '*', shareToken.data )
      : undefined
  ), [ shareToken.data ] );

  const assessmentPath = useMemo( () => assessmentUrl?.replace( window.location.origin, '' ) || '', [ assessmentUrl ] );
  const goToAssessment = () => {
    navigate( assessmentPath );
  };
 
  
  const gameUrl = useMemo( () => (
    typeof shareToken.data === 'string'
      ? window.location.origin + routesPaths.gameWelcome.replace( '*', shareToken.data )
      : undefined
  ), [ shareToken.data ] );

  const copyAssessmentLink = (  ) => {
    if ( gameUrl ) {
      toast.success( 'Successfully copied Cultural Assessment link.', {
        position: 'top-right',
        autoClose: 3000,
      } );
      navigator.clipboard.writeText( gameUrl );
      setCopied( true );
    }
  };

  const gamePath = useMemo( (  ) => gameUrl?.replace( window.location.origin, '' ) || '', [ gameUrl ] );
  const toggleResultsPopup = ( open: boolean ) => (  ) => setResultsPopupOpen( open );  
  useClickAwayListener( resultsPopupRef, isResultsPopupOpen ? toggleResultsPopup( false ) : null );
  useClickAwayListener( sharePopupRef, isResultsPopupOpen ? toggleResultsPopup( false ) : null );
 
  // if ( submissions?.isLoading || assessment.isLoading || shareToken.isLoading ) return <LoadingIndicatorBox />;
  // if ( !submissions?.data || !assessment.data || !shareToken.data ) return null;
  
  const [ isOpen, setisOpen ] = useState( false );
  const [ isChecked, setChecked ] = useState( false );
 
  const handleCheckboxChange = () => {
    setChecked( !isChecked );  
  };
  return ( 
    <div className={styles[ 'container' ]}>
        <div className={ styles[ 'flex' ] }>
          <div className={ styles[ 'share-link-container' ] }>
          <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px' } }>
              <p>Your Cultural Assessment :</p>
            </div>
            <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px' } }>

             <Button text='Copy' styleType='nice' onClick={ copyAssessmentLink } style={ { position: 'relative', backgroundColor: '#8250FF', marginBottom: 6, marginLeft: 6, padding: '7px 20px' } }>
              { ' ' }
             </Button>  
            </div>
          </div>
        </div>
      <section className={ styles['container22'] }>
        <div className={styles[ 'assessment-model' ]}>
          {submissions &&
            <div className={styles[ 'table' ]}> 
              {submissions.length > 0 ? ( 
                <TableListQuery<CulturalAssessmentSubmission>
                  queryHook={useGetAssessmentSubmissionsQuery.bind( null, id || skipToken ) as never}
                  fieldsToDisplay={[ 'name', 'email', 'createdAt' ]}
                  headings={{ 
                    email: 'Email', 
                    name: 'Name', 
                  // group: 'Group', 
                    createdAt: 'Timestamp' 
                  }}
                  hidePagination={true}
                  fieldTransformations={{
                    createdAt: date => DateTime.fromJSDate( date ).toFormat( 'LL.dd.yyyy hh:mm' )
                  }}
                  title='Submissions'
                  sortFunctions={{
                    email: submissionsSortings.byEmail,
                    name: submissionsSortings.byName,
                    // group: submissionsSortings.byGroup,
                    createdAt: submissionsSortings.byCreationDate,
                  }}
                />
              ) : ( 
                <div className={styles[ 'no-submissions' ]}>
                  <h1>You currently have no submissions.</h1>
                  <p>
                    {
                      currentCompany.data && currentCompany.data.cultureNeeds === 'protected'
                        ? ( 
                          <>
                            Please <Link to={gamePath} target='_blank'>take the assessment yourself</Link> and <Link to={gamePath} target='_blank'>share the link</Link> to your team.
                          </>
                        ) : ( 
                          'Please send the link to members of the executive team only.'
                        )
                    }
                  </p>
                </div>
              )}
            </div>}
        </div>
        <div className={ styles[ 'containerModal' ] } style={ submissions && !submissions.length ? { display: 'flex', justifyContent: 'center' } : {} }>
          <div className={ styles[ 'banner' ] }>
            <div className={ styles[ 'box' ] }>
              <article className={ styles[ 'cta' ] }>
                <div className={ styles[ 'submissions' ] }>
                  <p className={ styles[ 'Nosubmissions' ] }>{ submissions && submissions.length}</p>
                  <p className={ styles[ 'submissionsTexr' ] }>Submissions</p> 
                  { submissions && submissions.length > 0 &&  
                      <Button text='Create Hiring Assessment' styleType='nice' onClick={ ( )=> { setisOpen( true ); } } style={ { position: 'relative', backgroundColor: '#8250FF', marginBottom: 30 } }>
                      { ' ' }
                        </Button> 
                  }
                </div> 
                <img src={ vecAssfrom } width={ 220 } className={ styles[ 'logo' ] } />

              </article>
            </div>
          </div>
        </div> 
      </section>
      {/* <ToastContainer /> */}
      <ReactModal
        isOpen={isOpen}
        style={{
          content: {
            width: 600,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: 'max-content', 
            padding: 50,
            border: 'none',
            background: '#fff',
            display: 'flex',
            flexDirection: 'column',
            borderRadius : 10
          },
          overlay: {
            background: 'rgba(0,0,0,.5)'
          }
        }}
        appElement={document.getElementById( 'root' ) as HTMLDivElement}
      >
      <div className={styles['header']}>
        <h2>We’re ready to create your Hiring Assessment, are you?</h2>
      </div>
      <div className={styles['body']}>
      <label>
        <input style={{ marginRight: 7 }} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
         Our culture model is finalized, we are ready to move forward and we agree to the <a href='https://staffgeek.com/terms-of-service/' target='_blank'>Terms</a>
        </label>  
        <div style={{ display : 'flex',width: '100%', justifyContent: 'space-between', marginTop: 10 }}>
          <Button text='Go Back' onClick={ () => { setisOpen( false ); } } styleType='nice' style={{ backgroundColor: '#fff', color: '#8250ff', border: '1px solid #8250ff',width: '48%', }} />
          <Button text='Agree  ' onClick={handleRefundClick} disabled={!isChecked}  styleType='nice' style={{ backgroundColor: isChecked ? '#8250ff' : '#b4a0e6', color: '#fff',width: '48%', }} />
        </div>
        <p>Have questions? <a href="/getHelp">Get help here</a></p>
      </div>
    </ReactModal>
    </div>
  );
};