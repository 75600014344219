import { useMemo } from 'react';
import styles from './CulturalAssessmentGameScreen.module.sass';

export interface ProgressBarProps {
  total: number,
  done: number
}

export const ProgressBar = ( { done, total } : ProgressBarProps ) => {

  const percentage = useMemo( () => {
    return Math.round( done / total * 100 );
  }, [ done, total ] );
  
  return (
    <div className={styles['progress-bar']}>
      <div className={styles['bar']} style={{ width: `${percentage}%` }}></div>
      <span>{done} of {total} COMPLETE</span>
    </div>
  );
};