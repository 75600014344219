import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { updateTeamsSchema, useGetCurrentCompanyQuery, useUpdateTeamsMutation } from '../../../services';
import { Button, TextArea, TextInput } from '../../controls';
import { LabeledField } from '../../ui';
import { TableList } from '../../ui/TableList/TableList';

export const TeamsScreen = () => {

  const currentCompany = useGetCurrentCompanyQuery();
  const [ updateTeams ] = useUpdateTeamsMutation();

  const [ formOpen, setFormOpen ] = useState( false );

  if ( !currentCompany.data ) return null;

  return  (
    <>
      {formOpen ? (
        <Formik
          initialValues={{ name: '', description: '' }}
          onSubmit={( values, { resetForm } ) => {
            updateTeams( values ).unwrap().finally( () => {
              resetForm();
              setFormOpen( false );
            } );
          }}
          validationSchema={updateTeamsSchema}
        >
          {( { handleSubmit, submitForm } ) => (
            <form onSubmit={handleSubmit}>
              <LabeledField label='Name' style={{ marginBottom: 18 }}>
                <TextInput name='name' placeholder="Enter team's name" />
              </LabeledField>
              <LabeledField label='Description' style={{ marginBottom: 18 }}>
                <TextArea name='description' placeholder="Enter team's description" />
              </LabeledField>
              <Button text='Submit' onClick={submitForm} styleType='nice' style={{ width: 150 }} />
            </form>
          )}
        </Formik>
      ) : (
        <>
          <div style={{ float: 'right' }}>
            <Button styleType='alter' text='Create Team' onClick={() => setFormOpen( true )} />
          </div>
          <TableList<{name: string, description: string, createdAt: Date}>
            fieldsToDisplay={[ 'name', 'description', 'createdAt' ]}
            headings={{
              name: 'Name',
              createdAt: 'Created',
              description: 'Description'
            }}
            items={currentCompany.data.teams}
            fieldTransformations={{
              createdAt: date => DateTime.fromJSDate( date ).toFormat( 'LL.dd.yyyy hh:mm' ),
              description: text => <span style={{ maxWidth: 750, display: 'inline-block' }}>{text}</span>
            }}
          />
        </>
      )}
    </>
  );
};