import { Routes } from 'react-router-dom';
import { useTypedSelector } from '../state';
import { renderRoutes, rootRoutesAuthorized, rootRoutesUnauthorized } from './routes';

export const RootRouter = () => {

  const { isAuthorized } = useTypedSelector( state => state.authSlice ); 

  return (
    <Routes>
      {renderRoutes( isAuthorized ? rootRoutesAuthorized : rootRoutesUnauthorized )}
    </Routes>
  );
};