import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo, useState, useRef, ChangeEvent } from 'react';
import { useGetAssessmentResultByCompanyQuery, useGetCurrentCompanyQuery, useGetAssessmentByCompanyQuery, useGetAssessmentSubmissionsQuery, useGetAssessmentShareTokenQuery, useGetAssessmentDocumentFileNameQuery, useCreateAssessmentMutation, useGetFitTechSubmissionsQuery, useGetCurrentUserQuery } from '../../../services';
import { Button } from '../../controls';
import styles from './CulturalAssessmentResultScreen.module.sass';
import { DownloadDocModal } from './DownloadDocModal';
import { FitTechBanner } from './FitTechBanner';
import { ModelAttribute } from './ModelAttribute';
import { FiEye } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import { routesPaths } from '../../../navigation/routes';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingIndicatorBox, PopUp, ProgressCircle, Select, TableListQuery } from '../../ui';
import { useClickAwayListener, useScreenTitle } from '../../../utils';
import vecAssfrom from '../../../assets/img/vecAss.png';
import { useDispatch } from 'react-redux';
import { appActions } from '../../../state';
import { useTypedSelector } from '../../../state';
import { RiFileCopyLine } from 'react-icons/ri';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Coaching from '../../../assets/img/Coaching 1.png';
import { CulturalAssessment } from '../../../services/models';

type FormFields = Pick<CulturalAssessment, 'groups' | 'targetParticipants'> & {orgName: string, role?: string};

export const CulturalAssessmentResultScreen = () => {

  const appState = useTypedSelector( state => state.appSlice );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ id, setId ] = useState( '' );
  const modelFileName = useGetAssessmentDocumentFileNameQuery( id || skipToken );
  const assessmentResult = useGetAssessmentResultByCompanyQuery( id || skipToken );
  const currentCompany = useGetCurrentCompanyQuery();
  const currentUser = useGetCurrentUserQuery();
  const assessment = useGetAssessmentByCompanyQuery( '1' );
  const submissions = useGetAssessmentSubmissionsQuery( id || skipToken );
  const fittechSubmissions = useGetFitTechSubmissionsQuery( id || skipToken ); 
  const shareToken = useGetAssessmentShareTokenQuery( id || skipToken );
  const [ shareButtonElement, setShareButtonElement ] = useState<HTMLElement>();
  const [ isSharePopupOpen, setSharePopupOpen ] = useState( false );
  const sharePopupRef = useRef<HTMLElement>( null );
  const resultsPopupRef = useRef<HTMLElement>( null );
  const [ isResultsPopupOpen, setResultsPopupOpen ] = useState( false );
  const [ showLink, setShowLink ] = useState( false );
  const [ isCopied, setCopied ] = useState( false );
  const [ selectedValue, setSelectedValue ] = useState( 'pdf' );
  // const [ fitTechH, setFitTechH ] = useState( false );
  const [ createCulturalAssessment, { data: creationResponse, ...creationInfo } ] = useCreateAssessmentMutation();
  // if ( fittechSubmissions && fittechSubmissions.data ) {
  //   setFitTechH( true );
  // } else {
  //   setFitTechH( false );
  // }
  //console.log( 'fittechSubmissions', fittechSubmissions?.data.length );
  const handleSelectChange = ( event: ChangeEvent<HTMLSelectElement> ) => {
    setSelectedValue( event.target.value );
  };

  // console.log( 'submissions', submissions.data?.length );
  const [ download, setDownload ] = useState( false ); 
  const handleDownload = () => {
    setDownload( !download );
    let downloadUrl = '';

    if ( selectedValue === 'pdf' && modelFileName.data && modelFileName.data.pdf && download ) {
      downloadUrl = modelFileName.data.pdf;
    } else if ( selectedValue === 'pptx' && modelFileName.data && modelFileName.data.pptx ) {
      downloadUrl = modelFileName.data.pptx;
    }

    if ( downloadUrl ) {
      window.open( downloadUrl, '_blank' );
      setSelectedValue( 'pdf' );
      downloadUrl = '';
    }
  };

  const toggleResultsPopup = ( open: boolean ) => () => setResultsPopupOpen( open );
  const toggleSharePopup = ( open: boolean ) => () => setSharePopupOpen( open );

  useClickAwayListener( resultsPopupRef, isResultsPopupOpen ? toggleResultsPopup( false ) : null );
  useClickAwayListener( sharePopupRef, isResultsPopupOpen ? toggleResultsPopup( false ) : null );

  const gameUrl = useMemo( () => (
    typeof shareToken.data === 'string'
      ? window.location.origin + routesPaths.gameWelcome.replace( '*', shareToken.data )
      : undefined
  ), [ shareToken.data ] );

  const assessmentUrl = useMemo( () => (
    typeof shareToken.data === 'string'
      ? window.location.origin + routesPaths.fitTechBefor.replace( '*', shareToken.data )
      : undefined
  ), [ shareToken.data ] );

  const copyAssessmentLink = () => {
    if ( gameUrl ) {
      toast.success( 'Successfully copied Cultural Assessment link.', {
        position: 'top-right',
        autoClose: 3000,
      } );
      navigator.clipboard.writeText( gameUrl );
      setCopied( true );
    }
  };

  const gamePath = useMemo( () => gameUrl?.replace( window.location.origin, '' ) || '', [ gameUrl ] );
  const assessmentPath = useMemo( () => assessmentUrl?.replace( window.location.origin, '' ) || '', [ assessmentUrl ] );
  const goToGame = () => {
    if ( gameUrl ) window.open( gamePath, '_blank' );
  };
  const goToAssessment = () => {
    if ( assessmentUrl ) navigate( assessmentPath );
  };
  const goToHAssessment = () => {
    if ( assessmentUrl ) navigate( '/assessments/hiringAssessment' );
  };

  const screenTitle = useMemo( () => {
    if ( assessment.data?._id && assessment.data?.targetParticipants > 0 ) {
      dispatch( appActions.setIsShown( false ) );
      setId( assessment.data?._id );
      // console.log( 'assessment', assessment );
      
    }
    if ( !currentCompany.data || !assessment.data ) return '';
    if ( assessment.data.groups && !assessment.data.groups.length ) return currentUser?.data ? currentUser.data.name : '';
    return `${ currentUser?.data ? currentUser.data.name : '' } - ${ assessment.data.groups ? assessment.data.groups.join( ', ' ) : '' }`;
  }, [ currentCompany.data, assessment ] );

  useScreenTitle( screenTitle );


  useMemo( () => {
    if ( assessment.data?._id ) {
      setId( assessment.data?._id );
    }
  }, [ assessment.data ] );

  const [ isModalOpen, setModalOpen ] = useState( false );

  const rolesLabel = useMemo( () => {
    if ( !assessment.data?.groups?.length ) return '';
    return assessment.data.groups.join( ', ' );
  }, [] );

  if ( !assessmentResult.data || !submissions.data ) return null;

  return submissions.data.length == 0 ? (
 <>
<div className={ styles[ 'flex' ] }>
  <div className={ styles[ 'share-link-container' ] }>
    <p>Your Cultural Assessment :</p>
    <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px' } }>

     <Button text='Copy' styleType='nice' onClick={ copyAssessmentLink } style={ { position: 'relative', backgroundColor: '#8250FF', marginBottom: 6, marginLeft: 6, padding: '7px 20px' } }>
      { ' ' }
        </Button>  
    </div>
  </div>
</div>
<section className={ styles['container'] }>
  <div className={ styles['assessment-model'] }>
      <h1 className={ styles[ 'title' ] }>
      Your Cultural Assessment Model will updated after each submission in the table below             
      </h1>
          <div className={ styles['assessment-model1'] }>
          <div className={styles[ 'model-attribute' ]}>
        <p className={styles[ 'title' ]}>
          <img src={Coaching} />
          <span>Achievement</span>
        </p>
        <p className={styles[ 'description' ]}>
        Hard working; oriented and full of energy for the things he/she sees as challenging; able to act with a minimum of planning; seizes more opportunities than others.
        </p>
      </div>
      <div className={styles[ 'model-attribute' ]}>
        <p className={styles[ 'title' ]}>
        <img src={Coaching} />
          <span>Creativity</span>
        </p>
        <p className={styles[ 'description' ]}>
        Comes up with a lot of new and unique ideas; easily makes connections among previously unrelated notions; tends to be seen as original and value-added in brainstorming settings.              </p>
      </div>
      <div className={styles[ 'model-attribute' ]}>
        <p className={styles[ 'title' ]}>
        <img src={Coaching} />
          <span>Conscientious</span>
        </p>
        <p className={styles[ 'description' ]}>
        Level of industriousness, and attention to detail. Shared emphasis on quality and speed of execution.              </p>
      </div>
      <div className={styles[ 'model-attribute' ]}>
        <p className={styles[ 'title' ]}>
          <img src={Coaching} />
          <span>Empowering</span>
        </p>
        <p className={styles[ 'description' ]}>
        Motivates others; can assess each person’s drivers and use them to get results; makes each individual feel his/her work is important; is someone people like working for and with.              </p>
      </div>
      <div className={styles[ 'model-attribute' ]}>
        <p className={styles[ 'title' ]}>
          <img src={Coaching} />
          <span>Perseverance</span>
        </p>
        <p className={styles[ 'description' ]}>
        Pursues everything with energy, drive, and a need to finish; seldom gives up before finishing, especially in the face of resistance or setbacks. Shows a level of grit.              </p>
      </div>
      <div className={styles[ 'model-attribute' ]}>
        <p className={styles[ 'title' ]}>
          <img src={Coaching} />
          <span>Tech Savvy</span>
        </p>
        <p className={styles[ 'description' ]}>
        Familiarity and comfort with different forms of technology commonly found in the workplace, particularly computers.              </p>
      </div>
          </div>
  </div>
  {/* <div className={ styles[ 'container2' ] } style={ !submissions.data.length ? { display: 'flex', justifyContent: 'center' } : {} }>
    <div className={ styles[ 'banner' ] }>
      <div className={ styles[ 'box' ] }>
        <article className={ styles[ 'cta' ] }>
          <div className={ styles[ 'submissions' ] }>
            <p className={ styles[ 'Nosubmissions' ] }>{submissions.data.length}</p>
            <p className={ styles[ 'submissionsTexr' ] }>Submissions</p>
            <Link to={'/cultural-assessments/result'} className={ styles[ 'viewsubmissions' ] }>
              <FiEye className={ styles[ 'viewsubmissions_icon' ] } />
              <p className={ styles[ 'viewsubmissions_p' ] }>View submissions</p>
            </Link>
            <Button text='Create Hiring Assessment' styleType='nice' style={ { position: 'relative', backgroundColor: '#919eab6e', cursor: 'default', boxShadow: 'unset', marginBottom: 30 } }>
              { ' ' }
            </Button> 
          </div>
          { shareButtonElement && isSharePopupOpen &&
            <PopUp
              text='Share this link to invite others to take this Culture Assessment' //'Send an email with just recommended content'
              title='Invite your team to take the Culture Assessment'
              confirm={ {
                text: 'Copy Link',
                onClick: copyAssessmentLink,
              } }
              dismiss={ {
                text: 'dismiss',
                onClick: toggleSharePopup( false )
              } }
              parent={ shareButtonElement }
              popupRef={ sharePopupRef }
            />
          }
          <img src={ vecAssfrom } width={ 220 } className={ styles[ 'logo' ] } />

        </article>
      </div>
    </div>
  </div> */}
</section>
<ToastContainer />
</> 
  ) : (
    <>
      <div className={ styles[ 'flex' ] }>
  <div className={ styles[ 'share-link-container' ] }>
    <p>Your Cultural Assessment :</p>
    <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px' } }>

     <Button text='Copy' styleType='nice' onClick={ copyAssessmentLink } style={ { position: 'relative', backgroundColor: '#8250FF', marginBottom: 6, marginLeft: 6, padding: '7px 20px' } }>
      { ' ' }
        </Button>  
    </div>
  </div>
</div>
      <section className={ styles['container'] }>
        <div className={ styles['assessment-model'] }>
          { currentCompany.data && (
            <h1 className={ styles[ 'title' ] }>
              <span>{ currentCompany?.data ? currentCompany?.data.name: '' } { rolesLabel ? ` - ${rolesLabel}` : undefined }</span> Assessment Model
              {modelFileName.isLoading? ( null ):(
                <span className={styles[ 'span' ]}>
                <div className={styles[ 'custom-select' ]} style={ { display: download? '': 'none' } }>
                  <select className={styles[ 'select-input' ]} value={selectedValue} onChange={handleSelectChange}>
                    <option value='pdf'>PDF</option>
                    <option value='pptx'>PPTX</option>
                  </select>
                </div>
                <ArrowCircleDownIcon className={styles[ 'download-icon' ]} onClick={handleDownload} />
                <br />
                <small style={ { color: '#505861' } }> Model will update with each submission </small>
                </span>
              )}
            </h1>
          ) }
          { assessmentResult.data.map( attribute => (
            <ModelAttribute key={ attribute._id } attribute={ attribute } />
          ) ) }
        </div>
        {/* <div className={ styles[ 'container2' ] } style={ !submissions.data.length ? { display: 'flex', justifyContent: 'center' } : {} }>
          <div className={ styles[ 'banner' ] }>
            <div className={ styles[ 'box' ] }>
              <article className={ styles[ 'cta' ] }>
                <div className={ styles[ 'submissions' ] }>
                  <p className={ styles[ 'Nosubmissions' ] }>{submissions.data.length}</p>
                  <p className={ styles[ 'submissionsTexr' ] }>Submissions</p>
                  <Link to={'/cultural-assessments/result'} className={ styles[ 'viewsubmissions' ] }>
                    <FiEye className={ styles[ 'viewsubmissions_icon' ] } />
                    <p className={ styles[ 'viewsubmissions_p' ] }>View submissions</p>
                  </Link>
                  { submissions.data.length > 0 &&  
                  (

                    fittechSubmissions && fittechSubmissions.data && fittechSubmissions.data.length > 0
                    ?
                    <Button text='View Hiring Assessment' styleType='nice' onClick={ goToHAssessment } style={ { position: 'relative', backgroundColor: '#8250FF', marginBottom: 30 } }>
                    { ' ' }
                      </Button> :
                      <Button text='Create Hiring Assessment' styleType='nice' onClick={ goToAssessment } style={ { position: 'relative', backgroundColor: '#8250FF', marginBottom: 30 } }>
                      { ' ' }
                        </Button> 
                  ) 
                  }
                </div>
                { shareButtonElement && isSharePopupOpen &&
                  <PopUp
                    text='Share this link to invite others to take this Culture Assessment' //'Send an email with just recommended content'
                    title='Invite your team to take the Culture Assessment'
                    confirm={ {
                      text: 'Copy Link',
                      onClick: copyAssessmentLink,
                    } }
                    dismiss={ {
                      text: 'dismiss',
                      onClick: toggleSharePopup( false )
                    } }
                    parent={ shareButtonElement }
                    popupRef={ sharePopupRef }
                  />
                }
                <img src={ vecAssfrom } width={ 220 } className={ styles[ 'logo' ] } />

              </article>
            </div>
          </div>
        </div> */}
      </section>
      { /* {
        assessment.data && ( 
          !assessment.data.fitTechStatus.paid && assessment.data.fitTechStatus.trial === 'notActivated' 
        ) && (
          <FitTechBanner />
        )
      }
      <section className={styles['ask-question']}>
        <p>Have A Question About Your DNA? Let’s Talk  <a href='https://calendly.com/staffgeek/demo' target='_blank'>Ask A Question</a></p>
      </section> */ }
      {/* <ToastContainer /> */}
    </>
  );
};









