import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAssessmentsQuery } from '../../services';
import { appActions } from '../../state';



export const AppStateController = () => {
  const assessments = useGetAssessmentsQuery(); 

  const dispatch = useDispatch();

  useEffect( () => {
    if ( assessments.data ) {
      const hasFitTech = assessments.data.reduce( ( result, assessment ) => {
        return result || ( 
          assessment.fitTechStatus.paid || 
          assessment.fitTechStatus.trial === 'active' || 
          assessment.fitTechStatus.trial === 'over' 
        );
      }, false );
      dispatch( appActions.setHasFitTech( hasFitTech ) );
    }
  }, [ assessments.data ] );
  
  return null;
};