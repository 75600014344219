import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { Button } from '../../controls';
import styles from './FitTechResultsScreen.module.sass';
import { ReactComponent as Arrow } from '../../../assets/icons/Arrow_11.svg';
import { ReactComponent as ArrowTop } from '../../../assets/icons/arrow_top.svg';
import { useActivateFitTechMutation, useGetAssessmentByCompanyQuery } from '../../../services';
import { Callback } from 'yup/lib/types';

export interface FitTechSubscriptionBannerProps {
  end: DateTime,
  id: string,
  subscriptionsExpired: boolean,
  setSubscriptionExpired: ( ( state: boolean ) => void )
}

export const FitTechSubscriptionBanner = ( { end, id, subscriptionsExpired, setSubscriptionExpired } : { end: DateTime, id: string, subscriptionsExpired: boolean, setSubscriptionExpired: ( ( state: boolean ) => void ) } ) => {

  const navigate = useNavigate();

  const [ activateFitTech ] = useActivateFitTechMutation();

  // const [ subscriptionsExpired, setSubscriptionExpired ] = useState( false );

  const assessment = useGetAssessmentByCompanyQuery( '1' );

  const daysLeft = useMemo( () => {
    const days = Math.round( end.diff( DateTime.now(), 'days' ).days );
    return days > 0 ? days : 0;
  }, [ end ] );

  const hoursLeft = useMemo( () => {
    const days = Math.round( end.diff( DateTime.now(), 'days' ).hours );
    return days > 0 ? days : 0;
  }, [ end ] );

  const goPay = () => {
    if ( id ) navigate( routesPaths.payment );
  };
  
  useEffect( () => {
    const checkSubscription = () => {
      if ( end < DateTime.now() ) {
        setSubscriptionExpired( true );
      }
    };
    checkSubscription();
    const interval = setInterval( checkSubscription, 5000 );
    return () => {
      clearInterval( interval );
    };
  }, [ end ] );

  const onUnlockFitTech = () => {
    navigate( routesPaths.payment );
    // if ( assessment.data?._id ) {
    //   activateFitTech( assessment.data?._id ).unwrap().then( () => {
    //   } );
    // }
  };
  
  return (
    <div className={styles['fit-tech-subscription']} style={ !subscriptionsExpired ? { background: 'white' } : { } }>
      <div className={styles['main']}>
        <div className={styles['header']} style={ !subscriptionsExpired ? { alignItems: 'flex-start' } : { } }>
          <p style={ !subscriptionsExpired ? { color: '#1C1C1C', fontSize: '16px', fontWeight: 550, marginTop: 0 } : { } }>
            {
              !subscriptionsExpired
                ? 'Free trial ending in :'
                : 'Your free trial has ended :'
            }
          </p>
          <div className={styles['d-flex']}>
            { !subscriptionsExpired && ( 
              <div className={styles['day-counter-box']}>
                <div className={styles['day-counter']}>
                  <div className={styles['day-box']}>
                    <span>{daysLeft}</span>
                  </div>
                  <span>Days</span>
                </div>
                {/* <div className={styles['day-counter']}>
                  <div className={styles['day-box']}>
                    <span>{hoursLeft}</span>
                  </div>
                  <span>Hours</span>
                </div> */}
              </div>
            ) }
            <Button
              text={'Upgrade'}
              style={
                daysLeft
                  ? {
                      background: 'white',
                      color: '#8250FF',
                      border: '1px solid #8250FF',
                      borderRadius: '10px',
                      fontSize: '16px',
                      fontWeight: 600,
                      marginTop: 0,
                    }
                  : {
                      background: '#8250FF',
                      color: 'white',
                      borderRadius: '10px',
                      fontSize: '16px',
                      fontWeight: 600,
                      marginTop: 0,
                    }
              }
              styleType='alter-light'
              onClick={onUnlockFitTech}
              // disabled={!daysLeft} // Disable the button if daysLeft is falsy (user is upgraded)
            />
          </div>
          { !subscriptionsExpired ? (
            <div style={ { position: 'absolute', top: '-4px', right: '22%' } }>
              <ArrowTop />
            </div>
          ) : (
          <div style={ { position: 'absolute', bottom: '-4px', right: '9%' } }>
            <Arrow />
          </div>
          ) }
          
        </div>
        {/* <div className={styles['progress']}>
          <p>-{daysLeft} days</p>
          <div className={styles['progress-bar']}>
            <div 
              className={styles['bar']} 
              style={{ width: `calc(100% / ${daysCount} * ${daysLeft} - 8px)` }}
              data-days-left={daysLeft}
            >
            </div>
            {Array( daysCount ).fill( 0 ).map( ( _, index ) => (
              <div className={styles['dot']} key={index}></div>
            ) )}
          </div>
        </div> */}
      </div>
      {/* <div className={styles['date-range']}>
        <div className={styles['date']}>
          <span>Start:</span><span>{start.toFormat( 'DDD T' )}</span>
        </div>
        <div className={styles['date']}>
          <span>Finish:</span><span>{end.toFormat( 'DDD T' )}</span>
        </div>
      </div> */}
    </div>
  );
};