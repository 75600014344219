import { NavLink, useLocation } from 'react-router-dom';
import styles from './Navigation.module.sass';

export interface NavigationSubItemProps {
  icon: React.FunctionComponent,
  label: string,
  to: string,
  isActive?: boolean,
  showWhen?: RegExp
}

export const NavigationSubItem = ( { icon: Icon, label, to, showWhen } : NavigationSubItemProps ) => {

  const location = useLocation();

  if ( showWhen && !showWhen.test( location.pathname ) ) return null;

  return (
    <NavLink 
      to={to} 
      className={( { isActive } ) => [
        styles['sub-item'],
        isActive || showWhen ? styles['active'] : undefined
      ].join( ' ' )} 
    >
      <Icon />
      <span>{label}</span>
    </NavLink>
  );
};