import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateUserFields, createUserSchema, EditUserFields, editUserSchema, useChangePasswordMutation, useCreateUserMutation, useGetCurrentCompanyQuery, useGetCurrentUserQuery, useGetUserQuery, useUpdateCompanyMutation, useUpdateUserMutation } from '../../../services';
import { Button, TextInput } from '../../controls';
import { SelectField } from '../../controls/Select/Select';
import { TextInputView } from '../../controls/TextInput/TextInputView';
import { LabeledField, ResponseMessage } from '../../ui';
import { LoadingIndicatorBox } from '../../ui/Boxes';
import styles from './UserEditScreen.module.sass';

export const UserCreateScreen = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const [ createUser ] = useCreateUserMutation();
  // const [ changePassword, { isSuccess, isError } ] = useChangePasswordMutation();
  const currentCompany = useGetCurrentCompanyQuery();
  const currentUser = useGetCurrentUserQuery();

  const { data: userToEdit, isLoading } = useGetUserQuery( id || skipToken );
  const [ submitted, isSubmitted ] = useState( false );

  const [ theFirstName, setTheFirstName ] = useState( '' );
  const [ theLastName, setTheLastName ] = useState( '' );

  if ( !currentCompany.data || isLoading || currentUser.isLoading ) return <LoadingIndicatorBox />;

  const hideNameField = true;
  
  const initialValues = {
    name: '',
    email: '',
    description: '',
    role: 'admin',
    team: '',
    // password: '',
    // confirmPassword: '',
    firstName: '',
    lastName: ''
  } as CreateUserFields;

  const onSubmit = ( values: CreateUserFields ) => {
    isSubmitted( true );
    values.name = `${theFirstName} ${theLastName}`;
    // console.log( { values } );
    const promise = createUser( values as CreateUserFields ).unwrap();
    promise.then( () => {
      navigate( -1 );
    } );
  };
  
  return (
    <>
      <h1 className={styles['title']}>Add New User</h1>
      <p className={styles['hint']}>Fill the form and click submit</p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={createUserSchema}
        validateOnMount={true}
      >
        <Form className={styles['form']}>
          {!hideNameField && <LabeledField label='Name*'>
            <TextInput
              customOnChange={() => 'name' }
              name='name'
              placeholder='Enter Name'
            />
          </LabeledField>}
          <LabeledField label='First Name*'>
            <TextInput
              name='firstName'
              customOnChange={( v ) => {
                setTheFirstName( v );
              }}
              placeholder='Enter First Name*'
              isSubmitForm={submitted}
            />
          </LabeledField>
          <LabeledField label='Last Name*'>
            <TextInput
              name='lastName'
              customOnChange={( v ) => {
                setTheLastName( v );
              }}
              placeholder='Enter Last Name*'
              isSubmitForm={submitted}
            />
          </LabeledField>
          <LabeledField label='Email*'>
            <TextInput
              name='email'
              placeholder='Enter e-mail'
              type='email'
              isSubmitForm={submitted}
            />
          </LabeledField>
          {currentUser.data?.role === 'admin' && (
            <LabeledField label='Role'>
              <SelectField
                name='role'
                options={[ 'admin', 'manager' ].map( role => ( { label: role, value: role } ) )}
              />
            </LabeledField>
          )}
          {/* <LabeledField label='Description' style={{ width: 'calc(100% - 24px)', visibility: 'hidden' }}>
            <TextArea name='description' placeholder='Enter description' />
          </LabeledField> */}
          {/* <LabeledField label='Password*' style={{ marginLeft: 0, marginRight: 24 }}>
            <TextInput
              name='password'
              placeholder='Enter password'
              type='password'
            />
          </LabeledField>
          <LabeledField label='Confirm Password*'>
            <TextInput
              name='confirmPassword'
              placeholder='Confirm password'
              type='password'
            />
          </LabeledField> */}
          <Button
            text='Save' 
            type='submit' 
            styleType='nice' 
            style={{ width: 150 }}
            onClick={() => isSubmitted( true )}
          />
          
          {/* <ResponseMessage
            isSuccess={isSuccess}
            isError={isError}
            successLabel={'Password changed'}
            style={{ width: 'auto', marginLeft: 24 }}
            className={styles['response']}
          /> */}
        </Form>
      </Formik>
    </>
  );
};