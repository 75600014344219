import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { RiDownload2Line, RiShareLine } from 'react-icons/ri';
import { IoEyeOutline } from 'react-icons/io5';
import { ReactComponent as Show } from '../../../assets/icons/Show.svg';
import { useGetAssessmentByCompanyQuery, useGetAssessmentsQuery, useGetFitTechSubmissionsQuery, useSetFitTechRoleMutation } from '../../../services';
import { FitTechSubmission } from '../../../services/models';
import { useScreenTitle } from '../../../utils';
import { fitTechSubmissionsSortings } from '../../../utils/sortFunctions';
import { LoadingIndicator, TableListQuery } from '../../ui';
import { FitTechLink } from './FitTechLink';
import styles from './FitTechResultsScreen.module.sass';
import { FitTechSetRole } from './FitTechSetRole';
import { FitTechSubscriptionBanner } from './FitTechSubscriptionBanner';
import { Button } from '../../controls';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { ToastContainer, toast } from 'react-toastify';

export const FitTechResultsScreen = () => {

  const navigate = useNavigate();

  const [ id, setId ] = useState( '' );
  const submissions = useGetFitTechSubmissionsQuery( id || skipToken );
  const assessment = useGetAssessmentByCompanyQuery( '1' );

  const [ isPaid, setIsPaid ] = useState( false );

  const assessments = useGetAssessmentsQuery(); 
  
  useMemo( () => {
    if ( assessments.data ) {
      const paid = assessments.data.some( ( assessment ) => assessment.fitTechStatus.paid );

      setIsPaid( paid );
    }
  }, [ assessments.data ] );

  const [ subscriptionsExpired, setSubscriptionExpired ] = useState<boolean>( true );

  const subscriptionEnd = useMemo( () => {
    if( assessment.data?._id ){
      setId( assessment.data?._id );
    }
    if ( assessment.data ) {
      if ( assessment.data.fitTechStatus.paid === false && assessment.data.fitTechStatus.trial === 'over' ) {
        return DateTime.now().minus( { minute: 1 } );
      }
      return DateTime.fromJSDate( assessment.data.fitTechStatus.trialStart ).plus( { days: 14 } );
    }
  }, [ assessment.data ] );

  const sortedAttributes = useMemo( () => {
    if ( !submissions.data || !submissions.data.length ) return;
    return [ ...submissions.data[0].scores ]
      .sort( ( a,b ) => a.attributeId.title > b.attributeId.title ? 1 : -1 )
      .map( score => score.attributeId );
  }, [ submissions.data ] );
  
  const attributesFields : {
    attr1: ( submission: FitTechSubmission ) => string,
    attr2: ( submission: FitTechSubmission ) => string,
    attr3: ( submission: FitTechSubmission ) => string,
    attr4: ( submission: FitTechSubmission ) => string,
    attr5: ( submission: FitTechSubmission ) => string,
    attr6: ( submission: FitTechSubmission ) => string,
  } = useMemo( () => {
    return Array( 6 ).fill( null ).reduce( ( fields, _, index ) => ( {
      ...fields,
      [`attr${index+1}`]: ( submission: FitTechSubmission ) => {
        if ( !sortedAttributes ) return '';
        const words = { 'A': 'Great', 'B': 'Good', 'C': 'Average', 'D': 'Poor' };
        const key = sortedAttributes[index].key;
        const submissionScore = submission.scores.find( score => score.attributeId.key === key )?.score as FitTechSubmission['scores'][0]['score'];
        const scoreName = words[submissionScore];
        return (
          <span className={styles['attribute']} data-test={`${key}|${scoreName}`}>
            {`${scoreName}`}
          </span>
        );
      }
    } ), {} );
  }, [ sortedAttributes ] );



  const copyLink = ( link: string ) => {
    toast.success( 'Successfully copied Hiring Assessment link.', {
      position: 'top-right',
      autoClose: 3000,
    } );
    navigator.clipboard.writeText( link );
  };

  if ( subscriptionsExpired ) {
    document.body.oncopy = () => false;
    document.body.oncut = () => false;
  }
  // console.log( 'subscriptionEnd', subscriptionEnd );
  // console.log( 'SubscriptionExpired', subscriptionsExpired );
  

  useEffect( () => {
    if ( assessment.data?.fitTechStatus.paid ) {
      setSubscriptionExpired( false );
      return;
    }
    if ( subscriptionEnd ) {
      const checkSubscription = () => {
        if ( subscriptionEnd ) {
          setSubscriptionExpired( subscriptionEnd < DateTime.now() );
        }
      };
      checkSubscription();
      const interval = setInterval( checkSubscription, 5000 );
      return () => {
        clearInterval( interval );
      };
    }
  }, [ subscriptionEnd, assessment.data, setSubscriptionExpired ] );

  console.log( 'subscriptionEnd', subscriptionEnd );
  console.log( 'SubscriptionExpired', subscriptionsExpired );

  const refetchData = ( ) =>{
    assessment.refetch();
  };

  if ( assessment.data && !assessment.data.fitTechStatus.roleEntered ) return <FitTechSetRole refetchData={refetchData} />;

  // useEffect( () => {
  //   if ( assessment.data && !assessment.data.fitTechStatus.roleEntered ) {
  //     const [ setFitTechRole ] = useSetFitTechRoleMutation();
  //     setFitTechRole( {
  //       assessmentId: assessment.data?._id,
  //       role: 'First Role'
  //     } );
  //   } else {
  //     return;
  //   }
  // }, [ assessment.data?._id ] );
  // const daysLeft = useMemo( () => {
  //   const end = subscriptionEnd ? subscriptionEnd : DateTime.now();
  //   const days = Math.round( end.diff( DateTime.now(), 'days' ).days );
  //   return days > 0 ? days : 0;
  // }, [ subscriptionEnd ] );
  // console.log( 'daysLeft', daysLeft );
  console.log( 'subscriptionsExpired', subscriptionsExpired, 'isPaid', isPaid );
  return (
    <>
      <div 
          className={styles['fit-tech-wrapper']}
          // data-locked={subscriptionsExpired && !isPaid}
        >
          <div style={ { display: 'flex', justifyContent: 'space-between', marginTop: '16px' } }>
            <div style={ { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } } className={styles['fit-tech-wrapper']} data-locked={subscriptionsExpired && !isPaid}>
              <div className={styles['top-bar']}>
              </div>
              <FitTechLink id={id} />
            </div>
            {assessment.data && !isPaid && subscriptionEnd ? (
              <FitTechSubscriptionBanner
                  end={ subscriptionEnd ? subscriptionEnd : DateTime.now() }
                  id={id}
                  subscriptionsExpired={subscriptionsExpired}
                  setSubscriptionExpired={setSubscriptionExpired}
              />
            ) : <></>}
          </div>
      {subscriptionsExpired && !isPaid ? <></> : submissions.data?.length ? (
        
          <TableListQuery<FitTechSubmission, 'attr1' | 'attr2' | 'attr3' | 'attr4' | 'attr5' | 'attr6' >
            queryHook={useGetFitTechSubmissionsQuery.bind( null, id || skipToken ) as never}
            fieldsToDisplay={[ 
              'firstName', 'lastName', 'email',
              'attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'overallScore', 'createdAt', 'pdfName'
            ]}
            headings={{
              firstName: 'First Name',
              lastName: 'Last Name',
              email: 'Email',
              attr1: sortedAttributes ? sortedAttributes[0].title : '',
              attr2: sortedAttributes ? sortedAttributes[1].title : '',
              attr3: sortedAttributes ? sortedAttributes[2].title : '',
              attr4: sortedAttributes ? sortedAttributes[3].title : '',
              attr5: sortedAttributes ? sortedAttributes[4].title : '',
              attr6: sortedAttributes ? sortedAttributes[5].title : '',
              pdfName: 'Action',
              createdAt: 'Submitted',
              overallScore: 'Overall'
            }}
            customFields={{
              ...attributesFields
            }}
            className={styles['list']}
            fieldTransformations={{
              pdfName: fileName => (
                <>
                  <a className={styles['action']} href={fileName} target={'_blank'}>
                    <IoEyeOutline />
                  </a>
                  <span className={styles['action']} onClick={() => copyLink( fileName )}>
                    <RiShareLine />
                  </span>
                </>
              ),
              createdAt: date => DateTime.fromJSDate( date ).toFormat( 'LL.dd.yyyy hh:mm' ),
              overallScore: score => {
                const words = { 'A': 'Great', 'B': 'Good', 'C': 'Average', 'D': 'Poor' };
                return `${words[score]}`;
              }
            }}
            sortFunctions={{
              firstName: fitTechSubmissionsSortings.byFirstName,
              lastName: fitTechSubmissionsSortings.byLastName,
              email: fitTechSubmissionsSortings.byEmail,
              attr1: fitTechSubmissionsSortings.byScore( 0 ),
              attr2: fitTechSubmissionsSortings.byScore( 1 ),
              attr3: fitTechSubmissionsSortings.byScore( 2 ),
              attr4: fitTechSubmissionsSortings.byScore( 3 ),
              attr5: fitTechSubmissionsSortings.byScore( 4 ),
              attr6: fitTechSubmissionsSortings.byScore( 5 ),
              createdAt: fitTechSubmissionsSortings.bySubmitDate,
              overallScore: fitTechSubmissionsSortings.byOverallScore
            }}
            defaultSort={{ col: 'createdAt', order: 'desc' }}
          />
      ) : (
        <div className={styles['no-submissions']}>
          {
            submissions.isLoading ? (
              <LoadingIndicator />
            ) : (
              'Hiring assessment submissions will appear here once the first assessment is completed.'
            )
          }
        </div>
      )}
      </div>

    </>
  );

};