import { Formik } from 'formik';
import { useState } from 'react'; 
import { authService, isServerResponse, restorePasswordInitSchema } from '../../../services';
import { Box, Button, Container, Grid, IconButton, InputAdornment, TextField, Typography,  } from '@mui/material'; 
 
import styles from './RestorePasswordInitScreen.module.sass';  
import logo from '../../../assets/img/staffgeek_logo.png';
import vecPass from '../../../assets/img/vecPass.png'; 
import vacMess from '../../../assets/img/vacMess.png'; 
import { FiAlertCircle } from 'react-icons/fi';

export const RestorePasswordInitScreen = () => {

  const [ isMessageSent, setMessageSent ] = useState<boolean>();
  const [ error, setError ] = useState( '' );

  const emailIsValid = ( email: string ) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test( email );
  };

  return (
    <Box className={styles['box']}>
      <Grid container className={`${styles['grid']} `}>
        <Grid className={`${styles['grid22']}`} item xs={6}  > 
          <Grid className={`${styles['grid44']}`} item xs={6}  > 
            <img src={logo} width={220} className={styles['logo']}/>
          </Grid>
          <Grid className={`${styles['grid33']}`} > 
            <img src={vecPass} className={styles['blob']}/> 
          </Grid>
        </Grid>
 
        <Grid className={`${styles['grid']} ${styles['gridform']}`} item xs={6} >  
        { isMessageSent == true 
          ?
          <Grid className={styles['messageSent']}> 
            <img src={logo} width={220} className={styles['logo2']}/>
            <h3  className={styles['messageSent_header']}>Password Reset</h3>
            <img src={vacMess} className={styles['vacMess']}/> 
            <p className={styles['messageSent_text']}>Email sent! Please check your email for instructions on resetting your password</p>
          </Grid>
          :
          <>
            <Formik
                initialValues={{
                  email: ''
                }}
                onSubmit={( { email } ) => {
                  authService.restorePasswordInit( email ).then( response => {
                    if ( isServerResponse( response ) ) {
                      setMessageSent( false );
                      setError( response.message );

                    } else {
                      setMessageSent( response );
                    }
                    
                  } );
                }}
                validationSchema={restorePasswordInitSchema}
              >
                {( { handleSubmit, values, handleChange, errors ,isValid } ) => (

                  < form onSubmit={handleSubmit}>
                    <Container
                      className={styles['container']}
                      maxWidth="sm"
                    >
                      <Box className={styles['boxMargin']}> 
                        <Typography
                          className={`${styles['typography']} ${styles['typography2']}`}
                          variant="h4"
                          gutterBottom
                        >
                          Password Reset
                        </Typography> 
                        <p>Please enter the email address associated with your account and we’ll send you a link to reset your password.</p>
                        <Box className={styles['boxMargin']}>
                        <TextField
                          label="Registered Email"
                          name="email"
                          value={values.email}
                          required
                          variant="outlined"
                          fullWidth
                          onChange={handleChange} 
                          className={`${styles['textField']}`}
                        />
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            size="large"
                            fullWidth  
                            style={{ backgroundColor: emailIsValid( values.email ) ? '#9200ff' : '#919eab6e' }} 
                            disabled={!isValid}
                            className={`${styles['textField']} ${styles['textField2']}`} >
                            Password Reset
                          </Button> 
                        </Box>
                      </Box>
                    </Container>
                  </form>
                )}
            </Formik>
        
            <div style={{ width: '100%', height: '100px' }}>
              <div className={styles['divider']}>
                <div className={styles['line']}></div>
                <div className={styles['or']}>or</div>
                <div className={styles['line']}></div>
              </div> 
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p>Did not receive the email? <Button > Resend email</Button></p>
              </div>
            </div> 
            </>
             }
        </Grid>
      </Grid> 
    </Box >
  );
};