import { useDragLayer, XYCoord } from 'react-dnd';
import { CardView } from './CardView';
import { GameDragTypes } from './types';
import styles from './CulturalAssessmentGameScreen.module.sass';

export interface CardDragLayerProps {
  cardClickOffset?: XYCoord
}

export const CardDragLayer = ( { cardClickOffset } : CardDragLayerProps ) => {

  const { currentOffset, isDragging, item, itemType } = 
    useDragLayer( monitor => ( {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    } ) );


    // console.log( { some, currentOffset, initialOffset } );
  
  const renderItem = () => {
    switch ( itemType ) {
      case GameDragTypes.CARD:
        return <div
        className={[
          styles['card'],
          styles['preview']
        ].join( ' ' )}
      >
        <img src={item.iconUrl}/>
        <h1>{item.title}</h1>
      </div>;
      default:
        return null;
    }
  };

  if ( !isDragging ) return null;

  return (
    <div className={styles['drag-layer']}>
      <div
        style={getItemStyles( currentOffset, cardClickOffset )}
      >
        {renderItem()}
      </div>
    </div>
  );
};

function getItemStyles ( offset: XYCoord | null, cardClickOffset: XYCoord | undefined ) : React.CSSProperties {
  if ( !offset || !cardClickOffset ) return {
    display: 'none'
  };

  const x = offset.x + cardClickOffset.x - 75;
  const y = offset.y + cardClickOffset.y - 75;
  const transform = `translate(${x}px, ${y}px)`;
  return { transform };
}