import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { getStartedFormSchema, RegistrationFields } from '../../../services';

export interface AnsweredQuestionsObserverProps {
  onCountChange: ( answeredQuestionsCount: number ) => void,
  onInvalidFieldsChange?: ( invalidFields: string[] ) => void,
  onSubmit?: () => void
}

export const AnsweredQuestionsObserver = ( { 
  onCountChange, onInvalidFieldsChange, onSubmit
} : AnsweredQuestionsObserverProps ) => {

  const { values, isSubmitting, errors } = useFormikContext<RegistrationFields & {confirmPassword: string}>();
  
  useEffect( () => {
    countAnswers();
  }, [ values ] );

  useEffect( () => {
    if ( onInvalidFieldsChange ) {
      const fieldNames = Object.keys( errors );
      onInvalidFieldsChange( fieldNames );
    }
  }, [ errors, onInvalidFieldsChange ] );

  useEffect( () => {
    if ( isSubmitting && onSubmit ) onSubmit();
  }, [ isSubmitting, onSubmit ] );

  const countAnswers = async () => {
    const fields = Object.keys( values );
    let count = 0;
    for ( const fieldName of fields ) {
      try {
        await getStartedFormSchema.validateAt( fieldName, values )
          .then( () => {
            count++;
          } )
          .catch( () => null );
      } catch {/** */}
    }
    onCountChange( count );
  };
  
  return null;
};