import styles from './CulturalAssessmentGameScreen.module.sass';
import { ReactComponent as DividerIcon } from '../../../assets/icons/divider.svg';
import { CulturalAssessmentAttribute } from '../../../services/models';

export interface CardViewProps {
  attribute : CulturalAssessmentAttribute,
  className?: string,
  cardRef?: React.Ref<HTMLElement>,
  onMouseDown?: React.MouseEventHandler,
  onTouchStart?: React.TouchEventHandler,
  onClick?: React.MouseEventHandler
}

export const CardView = ( { 
  attribute, className, cardRef, onMouseDown, onTouchStart, onClick
} : CardViewProps ) => {

  
  return (
    <div
      className={[
        styles['card'],
        className
      ].join( ' ' )}
      ref={cardRef as never}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onClick={onClick}
    >
      <img src={attribute.iconUrl}/>
      <span>Attribute</span>
      <h1>{attribute.title}</h1>
      <p className={styles['description']}>{attribute.description}</p>
    </div>
  );
};