import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo, useState, useEffect } from 'react';
import { useGetAssessmentByCompanyQuery, useGetFitTechSubmissionsQuery, useSetFitTechRoleMutation } from '../../../services';
import styles from './FitTechSubmissionsScreen.module.sass';  
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useScreenTitle } from '../../../utils'; 
import { LoadingIndicator, TableListQuery } from '../../ui';
import { FitTechSubmission } from '../../../services/models';
import { IoEyeOutline } from 'react-icons/io5';
import { RiShareLine } from 'react-icons/ri';
import { fitTechSubmissionsSortings } from '../../../utils/sortFunctions';
import { DateTime } from 'luxon';
import { FitTechSetRole } from '../FitTechResultsScreen/FitTechSetRole';

export const FitTechSubmissionsScreen = () => { 

  const navigate = useNavigate(); 
  const [ id, setId ] = useState( '' );
  const assessment = useGetAssessmentByCompanyQuery( '1' ); 
  const submissions = useGetFitTechSubmissionsQuery( id || skipToken ); 
  useScreenTitle( 'Hiring Assessment Submissions' ); 

  const [ subscriptionsExpired, setSubscriptionExpired ] = useState<boolean>( true );

  const subscriptionEnd = useMemo( () => {
    if( assessment.data?._id ){
      setId( assessment.data?._id );
    }
    if ( assessment.data ) {
      if ( assessment.data.fitTechStatus.paid === false && assessment.data.fitTechStatus.trial === 'over' ) {
        return DateTime.now().minus( { minute: 1 } );
      }
      return DateTime.fromJSDate( assessment.data.fitTechStatus.trialStart ).plus( { days: 14 } );
    }
  }, [ assessment.data ] );

  const sortedAttributes = useMemo( () => {
    if ( !submissions.data || !submissions.data.length ) return;
    return [ ...submissions.data[0].scores ]
      .sort( ( a,b ) => a.attributeId.title > b.attributeId.title ? 1 : -1 )
      .map( score => score.attributeId );
  }, [ submissions.data ] );
  
  const attributesFields : {
    attr1: ( submission: FitTechSubmission ) => string,
    attr2: ( submission: FitTechSubmission ) => string,
    attr3: ( submission: FitTechSubmission ) => string,
    attr4: ( submission: FitTechSubmission ) => string,
    attr5: ( submission: FitTechSubmission ) => string,
    attr6: ( submission: FitTechSubmission ) => string,
  } = useMemo( () => {
    return Array( 6 ).fill( null ).reduce( ( fields, _, index ) => ( {
      ...fields,
      [`attr${index+1}`]: ( submission: FitTechSubmission ) => {
        if ( !sortedAttributes ) return '';
        const words = { 'A': 'Great', 'B': 'Good', 'C': 'Average', 'D': 'Poor' };
        const key = sortedAttributes[index].key;
        const submissionScore = submission.scores.find( score => score.attributeId.key === key )?.score as FitTechSubmission['scores'][0]['score'];
        const scoreName = words[submissionScore];
        return (
          <span className={styles['attribute']} data-test={`${key}|${scoreName}`}>
            {`${scoreName}`}
          </span>
        );
      }
    } ), {} );
  }, [ sortedAttributes ] );



  const copyLink = ( link: string ) => {
    toast.success( 'Successfully copied Hiring Assessment link.', {
      position: 'top-right',
      autoClose: 3000,
    } );
    navigator.clipboard.writeText( link );
  };

  if ( subscriptionsExpired ) {
    document.body.oncopy = () => false;
    document.body.oncut = () => false;
  }
  useEffect( () => {
    if ( assessment.data?.fitTechStatus.paid ) {
      setSubscriptionExpired( false );
      return;
    }
    if ( subscriptionEnd ) {
      const checkSubscription = () => {
        if ( subscriptionEnd ) {
          setSubscriptionExpired( subscriptionEnd < DateTime.now() );
        }
      };
      checkSubscription();
      const interval = setInterval( checkSubscription, 5000 );
      return () => {
        clearInterval( interval );
      };
    }
  }, [ subscriptionEnd, assessment.data, setSubscriptionExpired ] );

  const refetchData = ( ) =>{
    assessment.refetch();
  };

  if ( assessment.data && !assessment.data.fitTechStatus.roleEntered ) return <FitTechSetRole refetchData={refetchData} />;
  // useEffect( () => {
  //   if ( assessment.data && !assessment.data.fitTechStatus.roleEntered ) {
  //     const [ setFitTechRole ] = useSetFitTechRoleMutation();
  //     setFitTechRole( {
  //       assessmentId: assessment.data?._id,
  //       role: 'First Role'
  //     } );
  //   }
  // }, [ assessment.data?._id ] );
  
  return ( 
    <div className={styles[ 'container' ]}> 
      <div className={styles[ 'container2' ]}> 
              {submissions.data?.length ? (
              
              <TableListQuery<FitTechSubmission, 'attr1' | 'attr2' | 'attr3' | 'attr4' | 'attr5' | 'attr6' >
                queryHook={useGetFitTechSubmissionsQuery.bind( null, id || skipToken ) as never}
                fieldsToDisplay={[ 
                  'firstName', 'lastName', 'email',
                  'attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'overallScore', 'createdAt', 'pdfName'
                ]}
                headings={{
                  firstName: 'First Name',
                  lastName: 'Last Name',
                  email: 'Email',
                  attr1: sortedAttributes ? sortedAttributes[0].title : '',
                  attr2: sortedAttributes ? sortedAttributes[1].title : '',
                  attr3: sortedAttributes ? sortedAttributes[2].title : '',
                  attr4: sortedAttributes ? sortedAttributes[3].title : '',
                  attr5: sortedAttributes ? sortedAttributes[4].title : '',
                  attr6: sortedAttributes ? sortedAttributes[5].title : '',
                  pdfName: 'Action',
                  createdAt: 'Submitted',
                  overallScore: 'Overall'
                }}
                customFields={{
                  ...attributesFields
                }}
                className={styles['list']}
                fieldTransformations={{
                  pdfName: fileName => (
                    <>
                      <a className={styles['action']} href={fileName} target={'_blank'}>
                        <IoEyeOutline />
                      </a>
                      <span className={styles['action']} onClick={() => copyLink( fileName )}>
                        <RiShareLine />
                      </span>
                    </>
                  ),
                  createdAt: date => DateTime.fromJSDate( date ).toFormat( 'LL.dd.yyyy hh:mm' ),
                  overallScore: score => {
                    const words = { 'A': 'Great', 'B': 'Good', 'C': 'Average', 'D': 'Poor' };
                    return `${words[score]}`;
                  }
                }}
                sortFunctions={{
                  firstName: fitTechSubmissionsSortings.byFirstName,
                  lastName: fitTechSubmissionsSortings.byLastName,
                  email: fitTechSubmissionsSortings.byEmail,
                  attr1: fitTechSubmissionsSortings.byScore( 0 ),
                  attr2: fitTechSubmissionsSortings.byScore( 1 ),
                  attr3: fitTechSubmissionsSortings.byScore( 2 ),
                  attr4: fitTechSubmissionsSortings.byScore( 3 ),
                  attr5: fitTechSubmissionsSortings.byScore( 4 ),
                  attr6: fitTechSubmissionsSortings.byScore( 5 ),
                  createdAt: fitTechSubmissionsSortings.bySubmitDate,
                  overallScore: fitTechSubmissionsSortings.byOverallScore
                }}
                defaultSort={{ col: 'createdAt', order: 'desc' }}
              />
          ) : (
            <div className={styles['no-submissions']}>
              {
                submissions.isLoading ? (
                  <LoadingIndicator />
                ) : (
                  'Hiring assessment submissions will appear here once the first assessment is completed.'
                )
              }
            </div>
          )} 
      </div> 
    </div>
  );
};