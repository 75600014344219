import { ChangeEventHandler } from 'react';
import styles from './TextInput.module.sass';


export interface TextInputViewProps {
  name: string,
  placeholder?: string,
  type?: 'text' | 'password' | 'number' | 'email' | 'url',
  styleType?: 'transparent' | 'standard',
  disabled?: boolean,
  value: string,
  onChange: ( value: string ) => void,
  isSubmitForm?: boolean,
}

export const TextInputView = ( { 
  name, placeholder, type, styleType='standard', disabled, value, onChange
} : TextInputViewProps ) => {

  const onInputChange : ChangeEventHandler<HTMLInputElement> = event => {
    onChange( event.target.value );
  };
  

  return (
    <input
      className={`${styles['text-input']} ${styles[styleType]}`}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onInputChange}
      id={name}
      type={type}
      disabled={disabled}
    />
  );
};