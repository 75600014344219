import { ReactComponent as DividerIcon } from '../../../assets/icons/divider.svg';
import styles from './CulturalAssessmentGameScreen.module.sass';
import logo from '../../../assets/img/staffgeek_logo.png';

export const ThanksCard = () => (
  <div className={[
    styles['card'], styles['no-drag']
  ].join( ' ' )}>
    <h1>Thank You!</h1>
    <p>
      Your results have been submitted and will be calculated towards the role's competency model. Thank you. Your input is greatly appreciated.
    </p>
    <img src={logo} style={{ width: '50%', marginTop: 24 }} />
  </div>
);