import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { CulturalAssessment } from '../../../services/models';
import { Button } from '../../controls';


export const EditButton = ( { assessment } : {assessment: CulturalAssessment} ) => {
  const navigate = useNavigate();

  const goToEdit : MouseEventHandler<HTMLButtonElement> = ( e ) => {
    e.stopPropagation();
    navigate( routesPaths.editCulturalAssessment.replace( ':id', assessment._id ) );
  };
  
  return (
    <Button
      text='Edit'
      onClick={goToEdit}
      styleType={'nice'}
      style={{
        width: 'auto',
        marginTop: '0',
        padding: '4px 12px'
      }}
    />
  );
};