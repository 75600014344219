import styles from './Survey.module.sass';

export interface DescriptionProps {
  title: string,
  paragraphs: string[]
}

export const Description = ( { title, paragraphs } : DescriptionProps ) => (
  <div className={styles['form-description']}>
    <h2>{title}</h2>
    {paragraphs.map( ( text, index ) => <p key={index}>{text}</p> )}
  </div>
);