import { useMemo } from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import styles from './Survey.module.sass';

export interface NavigationProps {
  totalCount: number,
  completedCount: number,
  goNext: () => void,
  goPrev: () => void,
  notCompletedExtra?: number,
  ignoreCount?: number
}

export const Navigation = ( { 
  totalCount, completedCount, goNext, goPrev, notCompletedExtra, ignoreCount
} : NavigationProps ) => {
  
  const percentage = useMemo( () => {
    return Math.round( ( 
      completedCount 
        - ( notCompletedExtra || 0 ) 
        - ( ignoreCount || 0 ) 
      ) / ( 
        totalCount - ( ignoreCount || 0 ) 
      ) * 100 
    );
  }, [ completedCount, notCompletedExtra, totalCount ] );
  
  return (
    <div className={styles['navigation']}>
      <div className={styles['progress']}>
        <p>{percentage}% completed</p>
        <div className={styles['bar']}>
          <span style={{ width: percentage + '%' }}></span>
        </div>
      </div>
      <div className={styles['next']} onClick={goNext}>
        <RiArrowDropDownLine />
      </div>
      <div className={styles['prev']} onClick={goPrev}>
        <RiArrowDropUpLine />
      </div>
    </div>
  );
};