import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authService } from '../../services';
import { useTypedSelector } from '../../state';
import { authActions } from '../../state/slices/authSlice';


export const AuthController = () => {

  const isAuthorized = useTypedSelector( state => state.authSlice.isAuthorized );
  const validated = useTypedSelector( state => state.authSlice.validated );
  const accessToken = useTypedSelector( state => state.authSlice.accessToken );
  const refreshToken = useTypedSelector( state => state.authSlice.refreshToken );
  
  const dispatch = useDispatch();

  useEffect( () => {
    if ( accessToken && !validated && !isAuthorized ) {
      authService.validate();
    }
  } );

  useEffect( () => {
    if ( validated && !isAuthorized && refreshToken ) {
      authService.refresh();
    }
    if ( !( validated || accessToken || refreshToken ) ) {
      dispatch( authActions.setValidated( true ) );
    }
  }, [ validated, refreshToken, isAuthorized ] );
  
  return null;
};