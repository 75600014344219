import { loadStripe, Stripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from '../state';

export const useStripe = () => {
  const [ stripe, setStripe ] = useState<Stripe | null>( null );
  
  useEffect( () => {
    ( async () => {
      setStripe(
        await loadStripe( 'pk_test_tM1mvsVnjxHsYhF4gH9Ozn8R' )
      );
    } )();
  }, [] );
  return stripe;
};


export const useClickAwayListener = ( 
  target: React.RefObject<HTMLElement> | null | undefined,
  onClickAway: ( () => void ) | null,
  element?: HTMLElement | null
) => {
  useEffect( () => {
    if ( onClickAway && ( ( target && target.current ) || element ) ) {
      const clickAwayListener : EventListener = event => {
        const targetElement = target?.current || element;
        if ( event.target !== targetElement ) {
          onClickAway();
        }
      };
      document.addEventListener( 'click', clickAwayListener );
      return () => {
        document.removeEventListener( 'click', clickAwayListener );
      };
    }
  }, [ onClickAway, target ] );
  return null;
};

export const useScreenTitle = ( title: string | undefined ) => {
  
  const dispatch = useDispatch();

  useEffect( () => {
    dispatch( appActions.setTitle( title ) );
    return () => {
      dispatch( appActions.setTitle( undefined ) );
    };
  }, [ title ] );

  return null;
};