import { CulturalAssessmentAttribute } from '../../../services/models';
import styles from './CulturalAssessmentResultScreen.module.sass';

export interface ModelAttributeProps {
  attribute: CulturalAssessmentAttribute
}

export const ModelAttribute = ( { attribute } : ModelAttributeProps ) => {

  return (
    <div className={styles['model-attribute']}>
      <p className={styles['title']}>
        <img src={attribute.iconUrl} />
        <span>{attribute.title}</span>
      </p>
      <p className={styles['description']}>
        {attribute.description}
      </p>
    </div>
  );
};