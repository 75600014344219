import { MouseEventHandler } from 'react';
import { useArchiveAssessmentMutation } from '../../../services';
import { CulturalAssessment } from '../../../services/models';
import { Button } from '../../controls';


export const ArchiveButton = ( { assessment } : {assessment: CulturalAssessment} ) => {

  const [ archiveAssessment ] = useArchiveAssessmentMutation();

  const onClick : MouseEventHandler<HTMLButtonElement> = ( e ) => {
    e.stopPropagation();
    archiveAssessment( assessment._id );
  };
  
  return (
    <Button
      text='Archive'
      onClick={onClick}
      styleType={'nice'}
      style={{
        width: 'auto',
        marginTop: '0',
        padding: '4px 12px',
        marginLeft: 8
      }}
    />
  );
};