import React, { Fragment, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { RiQuestionnaireFill } from 'react-icons/ri';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/icon-logout.svg';
import logo from '../../../assets/img/staffgeek_logo.png';
import { authService, useGetAssessmentQuery, useGetAssessmentsQuery, useGetCurrentUserQuery } from '../../../services';
import { useTypedSelector } from '../../../state';
import { DrawerWrapper } from './DrawerWrapper';
import styles from './Navigation.module.sass';
import { NavigationItem, NavigationItemProps } from './NavigationItem';
import { Button } from '@mui/material';
import { routesPaths } from '../../../navigation/routes';
import { Link, useNavigate } from 'react-router-dom';
import { useGetAssessmentByCompanyQuery, useGetAssessmentSubmissionsQuery } from '../../../services';
import { ReactComponent as Upgrade } from '../../../assets/icons/upgrade.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/icon-profile.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/icon-users.svg';

export interface NavigationPanelProps {
  items: NavigationItemProps[]
}

export const NavigationPanel = ( { items } : NavigationPanelProps ) => {
  const [ screenHeight, setScreenHeight ] = useState( window.innerHeight );

  const navigate = useNavigate();
  const admin = localStorage.getItem( 'Admin' );
  const [ id, setId ] = useState( '' );
  const [ isPaid, setIsPaid ] = useState( false );

  const assessments = useGetAssessmentsQuery(); 
  
  useMemo( () => {
    if ( assessments.data ) {
      const paid = assessments.data.some( ( assessment ) => assessment.fitTechStatus.paid );

      setIsPaid( paid );
    }
  }, [ assessments.data ] );
  console.log( 'isPaid:', isPaid );

  const assessment = useGetAssessmentByCompanyQuery( '1' );
  const submissions = useGetAssessmentSubmissionsQuery( id || skipToken );

  const subscriptionEnd = useMemo( () => {
    if( assessment.data?._id ){
      setId( assessment.data?._id );
    }
  }, [ assessment.data ] );

  const { isAuthorized } = useTypedSelector( state => state.authSlice );

  // const currentUser = useGetCurrentUserQuery( !isAuthorized ? skipToken : undefined );

  const isTouchDevice =  ( 'ontouchstart' in window );

  const Wrapper = useMemo( () => isTouchDevice ? DrawerWrapper : React.Fragment, [ isTouchDevice ] );  
  const goPay = () => {
    console.log( { id } );
    
    if ( id ) navigate( routesPaths.payment );
  };
  if ( !submissions.data ) return null;
  return (
    <Wrapper>
      <nav className={[
        styles['navigation-panel'],
        isTouchDevice ? styles['touch'] : undefined
      ].join( ' ' )}>
        <img src={logo} />
        <ul>
          {items.map( ( item, index ) => <NavigationItem key={index} {...item} /> )}
          { isAuthorized && (
            <>
            <p className={styles['Tools']}>Tools</p>
              {/* <li className={styles['navigation-item']}>
                <a target='_blank' href='https://calendly.com/staffgeek/demo'>
                  <RiQuestionnaireFill size={24} className={styles['ri']} />
                  Get Help
                </a>
              </li> */}
              <li className={styles['navigation-item']}> 
                <Link to='/getHelp'>
                  <RiQuestionnaireFill size={24} className={styles['ri']} />
                  Get Help
                </Link>
              </li>
              <li className={styles['navigation-item']}>
                {/* "https://staging-sg-product-frontend-f3705ac0fc2f.herokuapp.com/profile" */}
            {/* <li> */}
                <Link to='/profile'>
                  <ProfileIcon />
                  Account
                </Link>
            {/* </li> */}
            <ul>
              <li>
                <a style={{ paddingTop: 1, paddingBottom: 1 }}>
                  <Link to='/users'>
                    <UsersIcon />
                    Users
                  </Link>
                </a>
              </li>
            </ul>
          </li>

              <li className={styles['navigation-item']}>
                <a onClick={admin ? authService.logoutAdmin: authService.logout}>
                  <LogoutIcon />
                  {admin? 'Logout Admin' : 'Logout'}
                  
                </a>
              </li>
              <div style={ { width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20, } }>
                {
                  !isPaid ?
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    size="large"
                    fullWidth
                    disabled={!submissions.data || submissions.data.length === 0}
                    onClick={() => { if ( submissions.data && submissions.data.length >= 0 ) { goPay(); } } }
                    style={{ textTransform: 'inherit', backgroundColor: submissions.data.length > 0 ? '#8250FF' : '#919eab6e', cursor: submissions.data.length == 0 ? 'default' : 'pointer', width: '66%', boxShadow: 'unset', ...( screenHeight > 815 ? { top: '100%', transform: 'translate(0%, -100%)', position: 'absolute' } : {} ) }}
                    className={styles['textField']} >
                    Upgrade
                  </Button> : <Fragment></Fragment>
                }
              </div>
            </>
          )}
        </ul>
      </nav>
    </Wrapper>
  );
};