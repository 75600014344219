import { useFormikContext } from 'formik';
import styles from './TextArea.module.sass';


export interface TextAreaProps {
  name: string,
  placeholder?: string,
}

export const TextArea = ( { name, placeholder } : TextAreaProps ) => {

  const { values, handleChange } = useFormikContext();

  const inputValue = ( values as Record<string, string> )[name];
  
  return (
    <textarea
      className={styles['text-area']}
      placeholder={placeholder}
      name={name}
      onChange={handleChange}
      id={name}
      value={inputValue}
    />
  );
};

export const TextAreaChange = ( { name, placeholder, handleChange, inputValue, handleChangeV } : { name: string, handleChange: ( v: any ) => void, placeholder: string, handleChangeV: ( v: any ) => void, inputValue?: string } ) => {

  const handleTextAreaChange = ( e: React.ChangeEvent<HTMLTextAreaElement> ) => {
    let newValue = e.target.value.replace( / /g, '' );
    newValue = newValue.replace( /\n/g, ',' );
    if ( newValue.endsWith( ',' ) ) {
      newValue = newValue.slice( 0, -1 );
    }
    handleChange( newValue );
    handleChangeV( e.target.value );
  };

  return (
    <textarea
      className={styles['text-area']}
      placeholder={placeholder}
      name={name}
      onChange={handleTextAreaChange}
      id={name}
      value={inputValue || ''}
    />
  );
};