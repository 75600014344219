import ReactModal from 'react-modal';
import { Button } from '../../controls';
import styles from './CulturalAssessmentGameScreen.module.sass';

export interface SortEssentialsCardProps {
  isOpen: boolean,
  onClick: () => void
}

export const SortEssentialsModal = ( { isOpen,  onClick } : SortEssentialsCardProps ) => {


  return (
    <ReactModal
        isOpen={isOpen}
        appElement={document.getElementById( 'root' ) as HTMLDivElement}
        shouldCloseOnOverlayClick={false}
        className={styles['sort-essentials-content']}
        overlayClassName={styles['final-form-overlay']}
      >
        <p>Now that you have assigned all of your cards, don't forget to rank each Attribute in the Essential bucket.</p>
        <Button 
          text='Rank Essential Bucket'
          onClick={onClick}
          styleType='nice' 
        />
      </ReactModal>
  );
};