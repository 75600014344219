import { CSSProperties, useEffect, useState } from 'react';
import styles from './ResponseMessage.module.sass';

export interface ResponseMessageProps {
  isSuccess?: boolean,
  isError?: boolean,
  style?: CSSProperties,
  successLabel?: string,
  errorLabel?: string,
  className?: string
}

export const ResponseMessage = ( { isError, isSuccess, style, errorLabel, successLabel, className } : ResponseMessageProps ) => {

  const [ isShown, setShown ] = useState<boolean>( false );

  useEffect( () => {
    setShown( !!( isSuccess || isError ) );
  }, [ isSuccess, isError ] );

  useEffect( () => {
    if ( isShown ) {
      const timeout = setTimeout( () => {
        setShown( false );
      }, 3000 );
      return () => clearTimeout( timeout );
    }
  }, [ isShown ] );

  return (
    <div 
      className={[ styles['response-message'], className ].join( ' ' )} 
      data-status={isError ? 'error' : isSuccess ? 'success' : undefined}
      data-shown={isShown}
      style={style}
    >
      {isSuccess && !isError && ( successLabel || 'Success' )}
      {isError && ( errorLabel || 'Some error occurred' )}
    </div>
  );
};