import { TableListHeader } from './TableListHeader';
import { TableListItem } from './TableListItem';
import styles from './TableList.module.sass';
import React from 'react';

export interface TableListProps<Item extends object, CustomFields extends string = never> {
  items: Item[],
  fieldsToDisplay: ( keyof Item | CustomFields )[],
  headings: Partial<{[key in keyof Item]: string} & {[key in CustomFields]: string}>,
  fieldTransformations?: FieldTransformations<Item>,
  onEdit?: ( value: Item ) => void,
  editable?: boolean,
  customFields?: Record<CustomFields, ( item: Item ) => string | JSX.Element>,
  className?: string,
  onItemClick?: ( item: Item ) => void,
  onHeadingSort?: ( col: keyof Item | CustomFields ) => void,
  sortedBy?: {
    col: keyof Item | CustomFields,
    order: 'asc' | 'desc'
  },
  style?: React.CSSProperties
}

export type FieldTransformation<Item extends object, key extends keyof Item> = ( value: Item[key] ) => string | JSX.Element;
export type FieldTransformations<Item extends object> = Partial<{
  [key in keyof Item]: FieldTransformation<Item, key>
}>;

export const TableList = <Item extends object, CustomFields extends string = never>( 
  { 
    items, fieldsToDisplay, headings, fieldTransformations, 
    onEdit, editable, className, customFields, onItemClick,
    onHeadingSort, sortedBy, style
  } : TableListProps<Item, CustomFields> 
) => (
  <table 
    className={[ styles['table-list'], styles[className || ''], className ].join( ' ' )}
    style={style}
  >
    <TableListHeader 
      headings={headings} 
      fieldsToDisplay={fieldsToDisplay} 
      editable={editable} 
      onHeadingSort={onHeadingSort}
      sortedBy={sortedBy}
    />
    <tbody>
      {items.map( ( item, index ) => 
        <TableListItem<Item, CustomFields>
          key={index} 
          item={item} 
          fieldsToDisplay={fieldsToDisplay}
          fieldTransformations={fieldTransformations}
          onEdit={onEdit}
          editable={editable}
          customFields={customFields}
          onItemClick={onItemClick}
        /> 
      )}
    </tbody>
  </table>
);