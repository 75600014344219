import * as yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { CompanyAccount } from './models';

export interface JwtPayload {
  uid: string,
  companyId: string
}

export interface TokensPair {
  accessToken: string,
  refreshToken: string,
}

export interface ServerResponseSuccess {
  success: true,
  status: ServerStatus,
  message?: string
}

export interface ServerResponseError {
  success: false,
  status: ServerStatus,
  message: string
}

export enum ServerStatus {
  SUCCESS = 1000,
  INVALID_CREDENTIALS = 1001,
  VALIDATION_ERROR = 1002,
  SAVING_ERROR = 1003,
  NOT_FOUND = 1004,
  UNKNOWN_ERROR = 1005,
  SENDING_MESSAGE_ERROR = 1006,
  FORBIDDEN = 1007
}

export type ServerResponse = ServerResponseSuccess | ServerResponseError;

export const isServerResponse = ( obj: unknown ): obj is ServerResponse =>
  ( obj as ServerResponse ).success !== undefined;

export const registrationFieldsSchema = yup.object( {
  companyName: yup.string().required(),
  userName: yup.string().required(),
  userEmail: yup.string().email().required(),
  assessmentGroups: yup.mixed().when( 'cultureNeeds', {
    is: 'protected',
    then: yup.array( yup.string().required() ).min( 1 ).required(),
    otherwise: yup.mixed()
  } ),
  assessmentTargetParticipants: yup.number().min( 2 ).required(),
  userPassword: yup.string().required(),
  siteUrl: yup.string().required(),
  cultureNeeds: yup.string().oneOf( [ 'fixed', 'protected' ] ).required(),
  logo: yup.mixed(),
} );

export const getStartedFormSchema = registrationFieldsSchema.concat( yup.object( {
  confirmPassword: yup.string().equals( [ yup.ref( 'userPassword' ) ] ).required(),
  agreed: yup.boolean().isTrue().required()
} ) );

export type RegistrationFields = yup.InferType<typeof registrationFieldsSchema>;
export type GetStartedFormSchema = yup.InferType<typeof getStartedFormSchema>;

export const loginFieldsSchema = yup.object( {
  email: yup.string().email().required(),
  password: yup.string().required()
} );

export type LoginFields = yup.InferType<typeof loginFieldsSchema>;

export const getHelpFieldsSchema = yup.object( {
  name: yup.string(),
  email: yup.string().email(),
  message: yup.string(),  
} );

export type getHelpFields = yup.InferType<typeof getHelpFieldsSchema> ;
export type getHelpField2 = yup.InferType<typeof getHelpFieldsSchema> & { role: 'admin' | 'manager' | 'contributor' };


export const registrationFormFieldsSchema = yup.object( {
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  companyName: yup.string().required(),
  emailAddress: yup.string().email().required(),
  password: yup.string().min( 6 ).required(),
  confirmPassword: yup.string().min( 6 ).required(),
} );
export type RegistrationFormFields = yup.InferType<typeof registrationFormFieldsSchema>;

export const editCulturalAssessmentSchema = ( cultureNeeds: CompanyAccount['cultureNeeds'] ) => yup.object( {
  targetParticipants: yup.number().min( 1 ).required(),
  role: yup.string(),
  groups: cultureNeeds === 'protected'
    ? yup.array( yup.string().required() ).min( 1, 'At least one group is required' ).required()
    : yup.mixed()
} );
export type EditCulturalAssessmentFields = yup.InferType<ReturnType<typeof editCulturalAssessmentSchema>>;

export const submitGameSchema = yup.object( {
  email: yup.string().email().required(),
  name: yup.string().required(),
  group: yup.string().when( 'cultureNeeds', { is: 'protected', then: yup.string().required() } ),
  cultureNeeds: yup.string().oneOf( [ 'fixed', 'protected' ] ).required()
} );
export type SubmitGameFields = yup.InferType<typeof submitGameSchema>;


export const createUserSchema = yup.object( {
  name: yup.string(),
  team: yup.string(),
  email: yup.string().email().required(),
  password: yup.string(),
  description: yup.string(),
  role: yup.string().oneOf( [ 'admin', 'manager', 'contributor' ] ).required(),
  confirmPassword: yup.string().equals( [ yup.ref( 'password' ) ] ),
  lastName: yup.string().required( 'Last Name is required' ),
  firstName: yup.string().required( 'First Name is required' ),
} );
export type CreateUserFields = yup.InferType<typeof createUserSchema> & { role: 'admin' | 'manager' | 'contributor' };


export const editUserSchema = yup.object( {
  name: yup.string(),
  team: yup.string(),
  email: yup.string().email(),
  description: yup.string(),
  role: yup.string().oneOf( [ 'admin', 'manager', 'contributor' ] )
} ).noUnknown( true );
export type EditUserFields = yup.InferType<typeof editUserSchema> & { role: 'admin' | 'manager' | 'contributor' };


export const updateTeamsSchema = yup.object( {
  name: yup.string().required(),
  description: yup.string()
} );
export type UpdateTeamsFields = yup.InferType<typeof updateTeamsSchema>;


export type FitTechSubmitFields = {
  email: string,
  firstName: string,
  lastName: string,
  gender: string,
  age: string,
  ethnicity: string,
} & Record<string, string>

export const fitTechSubmitSchema = ( questionIds: string[] ) => {
  const baseSchema = yup.object( {
    email: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    gender: yup.string(),
    age: yup.string(),
    ethnicity: yup.string()
  } );
  const questionsSchema: Record<string, RequiredStringSchema<string | undefined>> = {};
  for ( const id of questionIds ) questionsSchema[id] = yup.string().required();
  return baseSchema.concat( yup.object( questionsSchema ) );
};

export interface UploadFileResponse extends ServerResponseSuccess {
  url: string
}


export const restorePasswordInitSchema = yup.object( {
  email: yup.string().email().required()
} );

export const restorePasswordConfirmSchema = yup.object( {
  password: yup.string().required(),
  confirmPassword: yup.string().equals( [ yup.ref( 'password' ) ] ).required()
} );