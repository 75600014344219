import { useFormikContext } from 'formik';
import { CSSProperties, ReactChild, useEffect, useMemo, useRef, useState } from 'react';
import { RiArrowRightLine, RiCheckFill } from 'react-icons/ri';
import { Button, TextInput, TextInputProps } from '../../controls';
import styles from './Survey.module.sass';
import arrowTurnDownLeft from '../../../assets/img/arrowTurnDownLeft.png';

interface ViewportComponentProps {
  id: string;
}
export interface QuestionProps {
  label: string | JSX.Element,
  number: number,
  name: string,
  placeholder?: string,
  hideButton?: boolean,
  type?: TextInputProps['type'],
  additionalInput?: {
    placeholder: string,
    name: string,
    type?: TextInputProps['type'],
  },
  render?: ( props: QuestionCustomRenderProps ) => JSX.Element,
  children?: ReactChild,
  onButtonClick: () => void,
  styleType?: TextInputProps['styleType'],
  style?: CSSProperties,
  fade?: boolean,
  isCurrent?: boolean,
  scrollToQuestion?: ( index: number ) => () => void,
  specificButtonText?: string,
  skipIndex?: boolean
}

export type QuestionCustomRenderProps = Pick<QuestionProps,
  'name' | 'placeholder' | 'styleType' | 'scrollToQuestion' | 'number'
>;

export const Question = ( {
  label, name, number, placeholder,
  additionalInput, hideButton, render: RenderComponent,
  children, type = 'text', onButtonClick, styleType, style,
  fade, isCurrent, scrollToQuestion, specificButtonText, skipIndex=false
}: QuestionProps ) => {
  const [ visibleId, setVisibleId ] = useState<string | null>( null );
  const spanRef = useRef<HTMLSpanElement | null>( null );

  const ViewportComponent: React.FC<ViewportComponentProps> = ( { id } ) => {

    const componentRef = useRef<HTMLDivElement | null>( null );

    useEffect( () => {
      const observer = new IntersectionObserver( ( entries ) => {
        entries.forEach( ( entry ) => {
          if ( entry.isIntersecting ) {
            setVisibleId( id );
          }
        } );
      } );

      if ( componentRef.current ) {
        observer.observe( componentRef.current );
      }

      return () => {
        if ( componentRef.current ) {
          observer.unobserve( componentRef.current );
        }
      };
    } );

    return (
      <div ref={componentRef}></div>
    );
  };
  const questionRef = useRef<HTMLDivElement>( null );

  const className = useMemo( () => {
    const classes = [ styles['question'] ];
    if ( fade ) classes.push( styles['fade'] );
    if ( isCurrent ) classes.push( styles['current'] );
    return classes.join( ' ' );
  }, [ fade, isCurrent ] );

  const { errors } = useFormikContext<Record<string, string>>();
  const [ dnumber, setdnumber ] = useState<any | null>( null );
  const [ classes, setclasses ] = useState<any | null>( null );
  useEffect( () => {
    const observerCallback = ( mutationsList: MutationRecord[] ) => {
      // const ids: string[  ] = [  ];

      // mutationsList.forEach( ( mutation ) => {
      //   if ( mutation.target instanceof HTMLElement ) {
      //     if ( 
      //       mutation.target.classList.contains( 'Survey_question__TeRHR' ) &&
      //       mutation.target.classList.contains( 'Survey_current__j-Cvp' )
      //     ) {
      //       ids.push( mutation.target.id );
      //     }
      //   }
      // } );

      // console.log( ids );

    };

    const observer = new MutationObserver( observerCallback );

    const surveyDivs = document.querySelectorAll(
      'section.Survey_survey__Y9Ox1 form div.Survey_question__TeRHR'
    );

    surveyDivs.forEach( ( div ) => {
      observer.observe( div, { attributes: true } );
    } );

    return () => {
      observer.disconnect();
    };
  }, [] );


  // useEffect(() => {
  //   document.addEventListener('keydown', handleKeyPress);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);

  useEffect( () => {
    const classes:any = document?.querySelectorAll( 'form div' )[ 0 ]?.getAttribute( 'class' )?.split( ' ' );
    setclasses( classes );
  }, [] );

  useEffect( () => {
     
    const handleKeyPress = async ( event: KeyboardEvent ) => { 
      if ( event.key === 'Enter' ) {
        const targetDiv = document.querySelector(
          `.${classes[1]}`
        ) as HTMLDivElement;

        if ( targetDiv ) {
          const button = targetDiv.querySelector( 'button' );
          if ( button ) {
            setTimeout( () => {
              button.click();
            }, 500 );
          }
        }
      }
      if ( event.key === 'a' ) {
        const targetDiv = document.querySelector(
          'div > section > form > div.'+classes[1]
        ) as HTMLDivElement;

        if ( targetDiv ) {
          const spans = targetDiv.querySelectorAll( 'span' );

          spans.forEach( ( span ) => {
            if ( span.textContent === 'A' ) {
              span.classList.add( 'highlight' ); // Add the 'highlight' class to the span
              span.click();
              console.log( 'Element clicked:', span.textContent );
            }
          } );
        }
      }
      if ( event.key === 'b' ) {
        const targetDiv = document.querySelector(
          'div > section > form > div.'+classes[1]
        ) as HTMLDivElement;

        if ( targetDiv ) {
          const spans = targetDiv.querySelectorAll( 'span' );

          spans.forEach( ( span ) => {
            if ( span.textContent === 'B' ) {
              span.classList.add( 'highlight' ); // Add the 'highlight' class to the span
              span.click();
              console.log( 'Element clicked:', span.textContent );
            }
          } );
        }
      }
      if ( event.key === 'c' ) {
        const targetDiv = document.querySelector(
          'div > section > form > div.'+classes[1]
        ) as HTMLDivElement;

        if ( targetDiv ) {
          const spans = targetDiv.querySelectorAll( 'span' );

          spans.forEach( ( span ) => {
            if ( span.textContent === 'C' ) {
              span.classList.add( 'highlight' ); // Add the 'highlight' class to the span
              span.click();
              console.log( 'Element clicked:', span.textContent );
            }
          } );
        }
      }
      if ( event.key === 'd' ) {
        const targetDiv = document.querySelector(
          'div > section > form > div.'+classes[1]
        ) as HTMLDivElement;

        if ( targetDiv ) {
          const spans = targetDiv.querySelectorAll( 'span' );

          spans.forEach( ( span ) => {
            if ( span.textContent === 'D' ) {
              span.classList.add( 'highlight' ); // Add the 'highlight' class to the span
              span.click();
              console.log( 'Element clicked:', span.textContent );
            }
          } );
        }
      }
      if ( event.key === 'e' ) {
        const targetDiv = document.querySelector(
          'div > section > form > div.'+classes[1]
        ) as HTMLDivElement;

        if ( targetDiv ) {
          const spans = targetDiv.querySelectorAll( 'span' );

          spans.forEach( ( span ) => {
            if ( span.textContent === 'E' ) {
              span.classList.add( 'highlight' ); // Add the 'highlight' class to the span
              span.click();
              console.log( 'Element clicked:', span.textContent );
            }
          } );
        }
      }
      if ( event.key === 'f' ) {
        const targetDiv = document.querySelector(
          'div > section > form > div.'+classes[1]
        ) as HTMLDivElement;

        if ( targetDiv ) {
          const spans = targetDiv.querySelectorAll( 'span' );

          spans.forEach( ( span ) => {
            if ( span.textContent === 'F' ) {
              span.classList.add( 'highlight' ); // Add the 'highlight' class to the span
              span.click();
              console.log( 'Element clicked:', span.textContent );
            }
          } );
        }
      }
      if ( event.key === 'g' ) {
        const targetDiv = document.querySelector(
          'div > section > form > div.'+classes[1]
        ) as HTMLDivElement;

        if ( targetDiv ) {
          const spans = targetDiv.querySelectorAll( 'span' );

          spans.forEach( ( span ) => {
            if ( span.textContent === 'G' ) {
              span.classList.add( 'highlight' ); // Add the 'highlight' class to the span
              span.click();
              console.log( 'Element clicked:', span.textContent );
            }
          } );
        }
      }
    };

    window.addEventListener( 'keydown', handleKeyPress );

    return () => {
      window.removeEventListener( 'keydown', handleKeyPress );
    };
  } );

  const onBlur = () => {
    setdnumber( number );
    if ( !errors[name] && scrollToQuestion && isCurrent && questionRef.current && isInViewport( questionRef.current ) )
      scrollToQuestion( number + 1 )();
  };

  const onFocus = () => {
    if ( scrollToQuestion ) scrollToQuestion( number )();
  };
  const simulateClick = () => {
    if ( spanRef.current ) {
      spanRef.current.click();
    }
  };

  useEffect( ()=>{
    const handleKeyPress = ( event:KeyboardEvent ) => {
      if ( !hideButton && specificButtonText && event.key === 'Enter' ) {
        onButtonClick();
      }
    };

    if( skipIndex ) {
      window.addEventListener( 'keydown', handleKeyPress );

      return () => {
        window.removeEventListener( 'keydown', handleKeyPress );
      };
    }
  }, [ skipIndex, specificButtonText ] );

  return (
    <div
      className={className}
      id={`question-${number}`}
      style={style}
      onBlur={onBlur}
      onFocus={onFocus}
      ref={questionRef}
      tabIndex={number}

    >
      <ViewportComponent id={`${number}`} />
      <label htmlFor={RenderComponent ? undefined : name}>
        <span onClick={() => {
            if( !skipIndex ) simulateClick();
          }} className={number >= 100 ? styles['three-digit'] : undefined}>
          {skipIndex ? 
          <>
          <p className={ styles['firstComma'] } style={{ fontFamily: 'Open Sans, Segoe UI Bold, Tahoma, Geneva, Verdana, sans-serif' }}>،</p><p style={{ fontFamily: 'Open Sans, Segoe UI Bold, Tahoma, Geneva, Verdana, sans-serif' }} className={ styles['secondComma'] }>،</p>
          </>: <>{number} <RiArrowRightLine /></>}
        </span>
        {label}
      </label>
      {RenderComponent ? (
        <RenderComponent
          name={name}
          placeholder={placeholder || 'Type your answer here...'}
          styleType={styleType}
          scrollToQuestion={scrollToQuestion}
          number={number}
        />
      ) : (
        <TextInput styleType='transparent' type={type} name={name} placeholder={placeholder || 'Type your answer here...'} />
      )}
      {additionalInput && (
        <TextInput
          name={additionalInput.name}
          placeholder={additionalInput.placeholder}
          type={additionalInput.type}
          styleType='transparent'

        />
      )}
      {!hideButton && !specificButtonText && <Button text='OK' children={<RiCheckFill />} onClick={onButtonClick} />}
      {!hideButton && specificButtonText && <div className={ styles['pressEnterContainer'] }>
        <Button text={specificButtonText} onClick={onButtonClick} />
        <span className={ styles['pressEnter'] }>press <b>Enter</b></span>
        <img className={ styles['pressEnterImg'] } src={arrowTurnDownLeft} />
      </div>}
      {children}
    </div>
  );
};

function isInViewport( element: HTMLElement ) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight ) &&
    rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
  );
}