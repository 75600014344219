import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { tableListFiltersToControlsFilters } from '../../../utils';
import { ErrorBox } from '../Boxes/ErrorBox';
import { LoadingIndicatorBox } from '../Boxes/LoadingIndicatorBox';
import { SelectProps } from '../Controls/Select';
import { ListControls, ListControlsProps } from '../ListControls';
import { Pagination } from '../Pagination';
import { TableList, TableListProps } from './TableList';
import styles from './TableList.module.sass';

export type TableListQueryProps<Item extends object, CustomFields extends string = never> = 
  Omit<TableListProps<Item, CustomFields>, 'items' | 'sortedBy' | 'onHeadingSort'> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryHook: UseQuery<QueryDefinition<void, any, any, Item[], any>>,
    searchFn?: ( term: string, items: Item[] ) => Item[],
    filters?: Record<string, TableListQueryFilterProps<Item>>,
    onItemsCountChange?: ( count: number ) => void,
    hidePagination?: boolean,
    title?: string,
    sortFunctions?: Partial<{
      [key in ( keyof Item | CustomFields ) ]: ( items: Item[], order: 'desc' | 'asc' ) => Item[]
    }>
    defaultSort?: { col: keyof Item | CustomFields; order: 'asc' | 'desc' };
}

export type TableListQueryFilterProps<Item> = 
  Required<Pick<SelectProps, 'options' | 'label' | 'defaultValue'>> & {
    filterFn: ( val: string, items: Item[] ) => Item[]
  }

export const TableListQuery = <Item extends object, CustomFields extends string = never,>(
  { 
    fieldsToDisplay, headings, queryHook, 
    fieldTransformations, customFields, className,
    searchFn, filters, onItemsCountChange, onItemClick,
    editable, onEdit, hidePagination, title,
    sortFunctions, defaultSort 
  } : TableListQueryProps<Item, CustomFields>
) => {

  const { data, isLoading, isError, error, refetch } = queryHook();
  const [ filteredItems, setFilteredItems ] = useState( data );
  const [ paginatedItems, setPaginatedItems ] = useState( filteredItems );
  const [ appliedFilters, setAppliedFilters ] = 
    useState<Record<string, ( 
      Pick<TableListQueryFilterProps<Item>, 'filterFn'> & {val: string} 
    )>>( {} );

  const [ sorting, setSorting ] = useState<keyof Item | CustomFields>();
  const [ sortingOrder, setSortingOrder ] = useState<'asc' | 'desc'>( 'desc' );

  useEffect( () => {
    if ( filteredItems?.length !== undefined && onItemsCountChange ) 
      onItemsCountChange( filteredItems.length );
  }, [ filteredItems ] );
  
  useEffect( () => {
    if ( !data ) return;
    let tmpItems : Item[] = data;
    for ( const filter of Object.values( appliedFilters ) ) {
      tmpItems = filter.filterFn( filter.val, tmpItems );
    }
    setFilteredItems( tmpItems );
  }, [ appliedFilters, data ] );

  useEffect( () => {
    if ( defaultSort ) {
      setSorting( defaultSort.col );
      setSortingOrder( defaultSort.order );
    }
  }, [ defaultSort ] );

  const onFilter = useCallback( ( 
    filterName: string, 
    filterProps: Pick<TableListQueryFilterProps<Item>, 'filterFn'>
  ) => ( value: string ) => {
    setAppliedFilters( prevState => ( {
      ...prevState,
      [filterName]: { filterFn: filterProps.filterFn, val: value }
    } ) );
  }, [ appliedFilters, setAppliedFilters ] );


  const onPaginate = ( items: Item[] ) => {
    setPaginatedItems( items );
  };

  const controlsFilters : ListControlsProps['filters'] = useMemo( (
    tableListFiltersToControlsFilters( filters, onFilter )
  ), [ filters ] );

  const sortedItems = useMemo( () => {
    if ( sorting && sortFunctions && sortFunctions[sorting] ) {
      const sortFunc = sortFunctions[sorting];
      if ( sortFunc ) return sortFunc( [ ...( filteredItems || [] ) ], sortingOrder );
      else return filteredItems;
    } else {
      return filteredItems;
    }
  }, [ filteredItems, sorting, sortingOrder ] );

  // const visibleItems = useMemo( () => {
  //   let tmpItems = ( hidePagination ? filteredItems : paginatedItems ) || [];
  //   if ( sorting && sortFunctions && sortFunctions[sorting] ) {
  //     const sortFunc = sortFunctions[sorting];
  //     if ( sortFunc ) tmpItems = sortFunc( [ ...tmpItems ], sortingOrder );
  //   }
  //   return tmpItems;
  // }, [ hidePagination, filteredItems, paginatedItems, sorting, sortingOrder ] );

  const onHeadingSort = ( col: keyof Item | CustomFields ) => {
    if ( sortFunctions && Object.keys( sortFunctions ).includes( col as string ) ) {
      if ( sorting === col ) setSortingOrder( sortingOrder === 'asc' ? 'desc' : 'asc' );
      else setSorting( col );
    }
  };

  if ( isLoading ) return <LoadingIndicatorBox />;
  if ( isError ) return (
    <ErrorBox
      error={error || 'Some error occurred'}
      possibleAction={{
        text: 'You can try to reload:',
        buttonText: 'Reload',
        onClick: () => {
          refetch();
        }
      }}
    />
  ); 

  return (
    <>
      <ListControls
        onSearch={searchFn && debounce( 100, onFilter( '__search', { filterFn: searchFn } ) )}
        filters={controlsFilters}
      />
      <div className={styles['table-list-query']}>
        {title && <h1 style={{ marginTop: 0, fontSize:25 ,fontWeight: 600 }}>{title}</h1>}
        <TableList
          items={( hidePagination ? sortedItems : paginatedItems ) || []}
          fieldsToDisplay={fieldsToDisplay}
          headings={headings}
          fieldTransformations={fieldTransformations}
          customFields={customFields}
          className={className}
          onItemClick={onItemClick}
          editable={editable}
          onEdit={onEdit}
          onHeadingSort={onHeadingSort}
          sortedBy={sorting ? {
            col: sorting,
            order: sortingOrder
          } : undefined}
        />
        {!hidePagination && <Pagination<Item>
          onPageChange={onPaginate}
          paginateItems={sortedItems || []}
        />}
      </div>
    </>
  );
};