import { useState } from 'react';
import { Formik } from 'formik';
import { authService } from '../../../services';
import { LoginFields, loginFieldsSchema } from '../../../services/types';
import styles from './LoginScreen.module.sass';
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import logo from '../../../assets/img/staffgeek_logo.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link, useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import vecLog from '../../../assets/img/vecLog.png';

export const LoginScreen = () => {

  const navigate = useNavigate(); 
  const [ showPassword, setShowPassword ] = useState( false );
  const [ passworderror, setpassworderror ] = useState( false );  
  const togglePasswordVisibility = () => {
    setShowPassword( !showPassword );
  }; 
  const emailIsValid = ( email: string ) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test( email );
  }; 
  interface ServerResponseError {
    error: string;
  } 
  function isServerResponseError( data: any ): data is ServerResponseError {
    return data !== null;
  } 
  return (
     <Box className={styles['box']}>
      <Grid container className={`${styles['grid']} `}>
        <Grid className={`${styles['grid22']}`} item xs={6}  > 
          <Grid className={`${styles['grid44']}`} item xs={6}  > 
            <img src={logo} width={220} className={styles['logo']}/>
          </Grid>
          <Grid className={`${styles['grid33']}`} > 
            <img src={vecLog} className={styles['blob']}/> 
          </Grid>
        </Grid>
 
        <Grid className={`${styles['grid']} ${styles['gridform']}`} item xs={6} >  
          <Formik
          initialValues={{
            email: '',
            password: ''
          } as LoginFields}
          validationSchema={loginFieldsSchema}
          onSubmit={( fields ) => {
            authService.login( fields ).then( ( res ) => {
              // console.log( 'res ==> ',res ); 
              if ( res && res.verifiedat === null ) { 
                navigate( `/verifyemail/${res.email}/login` );  
              }
              if ( isServerResponseError( res ) && res.error === 'password' ) { 
                setpassworderror( true );
              }
            } ).catch( ( e ) => {
              console.log( 'eeeeeeeeeeeeeeeeeeeeee', e );
            } );
          }}
        >
        {( { handleSubmit, values, handleChange, errors } ) => (

              < form onSubmit={handleSubmit}>
                <Container
                  className={styles['container']}
                  maxWidth="sm"
                >
                  <Box className={styles['boxMargin']}>
                    {/* <img src={logo} width={220} className={styles['logo']}/> */}
                    <Typography
                      className={`${styles['typography']} ${styles['typography2']}`}
                      variant="h4"
                      gutterBottom
                    >
                      Sign in to StaffGeek
                    </Typography> 
                    <Box className={styles['boxMargin']}> 
                       <TextField
                          label="Email address"
                          name="email"
                          required
                          variant="outlined"
                          fullWidth
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          error={
                            values.email !== '' && !emailIsValid( values.email )
                          }
                          helperText={ 
                              values.email !== '' &&
                              !emailIsValid( values.email ) &&
                              'Invalid email address'
                          }
                          className={styles[ 'textField' ]}
                        />
                        <TextField
                          label="Password"
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          required
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          value={values.password}
                          className={styles['textField']}
                          error={
                            passworderror == true
                          }
                          helperText={
                            passworderror === true ? 'Invalid password!' : ''
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        /> 
                      <Grid  className={styles['footer']} > 
                        <Grid className={styles['subfooter']}>
                          <FormControlLabel style={{ color: '#909090',fontSize:'14px' }} control={<Checkbox />} label="Remember me" />
                        </Grid >
                        <Grid className={styles['subfooter']}> 
                          <Link to={routesPaths.passwordRestoreInit} style={{ color: '#909090',fontSize:'14px' }}>Forgot password?</Link>
                        </Grid>
                      </Grid>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        size="large"
                        fullWidth
                        style={{ backgroundColor: emailIsValid( values.email ) ? '#9200ff' : '#919eab6e' }}
                        className={styles['textField']} >
                        Log In Now
                      </Button>
                    </Box>
                  </Box>
                </Container>
              </form>
            )}
          </Formik>
    
          <div style={{ width: '100%', height: '100px' }}>
            {/* <div style={{ width: '50%', height: '1px', background: '#000', display: 'flex', margin: 'auto',marginTop: '30px' }}></div> */}
            <div className={styles['divider']}>
              <div className={styles['line']}></div>
              <div className={styles['or']}>or</div>
              <div className={styles['line']}></div>
            </div>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p>Don’t have an account?   <Link to={{ pathname: '/', }} style={{ color: '#9200ff' }}> Get started </Link></p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box >
  );
};