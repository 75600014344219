import { SwipeableDrawer } from '@mui/material';
import { ReactChild, ReactNode, useState } from 'react';

export interface DrawerWrapperProps {
  children: ReactChild | ReactNode
}

export const DrawerWrapper = ( { children } : DrawerWrapperProps ) => {

  const [ isDrawerOpen, setDrawerOpen ] = useState( false );

  const toggleDrawer = ( open: boolean ) => () => {
    setDrawerOpen( open );
  };

  const iOS =
    typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test( navigator.userAgent );


  return (
    <SwipeableDrawer
      onClose={toggleDrawer( false )}
      onOpen={toggleDrawer( true )}
      open={isDrawerOpen}
      anchor='top'
      disableDiscovery={iOS}
    >
      {children}
    </SwipeableDrawer>
  );
};