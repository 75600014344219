import { Form, Formik, FormikConfig } from 'formik';
import { KeyboardEventHandler, Ref, useEffect, useMemo, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import logo from '../../../assets/img/logo.png';
import { Button } from '../../controls';
import { AnsweredQuestionsObserver } from './AnsweredQuestionsObserver';
import { Description, DescriptionProps } from './Description';
import { Navigation, NavigationProps } from './Navigation';
import { Question, QuestionProps } from './Question';
import styles from './Survey.module.sass';


export type SurveyProps<T> = {
  title?: string,
  questions: Omit<QuestionProps, 'number' | 'onButtonClick'>[],
  initialValues: T,
  onSubmit: ( values: T ) => void,
  validationSchema?: FormikConfig<T>['validationSchema'],
  description?: DescriptionProps,
  hideNavigation?: boolean,
  validationHighlight?: boolean,
  formRef?: Ref<HTMLFormElement>
} & Pick<NavigationProps, 'notCompletedExtra' | 'ignoreCount'>

export const Survey = <T,>( { 
  title, questions, initialValues, onSubmit, 
  validationSchema, description, hideNavigation,
  notCompletedExtra, ignoreCount, validationHighlight,
  formRef
} : SurveyProps<T> ) => {

  const innerFormRef = useRef<HTMLFormElement>( null );

  const [ currentQuestion, setCurrentQuestion ] = useState( 1 );
  const [ answeredQuestionsCount, setAnsweredQuestionsCount ] = useState( 0 );
  
  const [ invalidFields, setInvalidFields ] = useState<string[]>();
  const [ shouldIterate, setShouldIterate ] = useState( false );
  const [ lookForValidation, setLookForValidation ] = useState( validationHighlight );

  const triggerIterate = () => setShouldIterate( true );

  const scrollToQuestion = ( index: number ) => () => {
    if ( index > currentQuestion+1 )
      return;
    const questionElement = document.getElementById( `question-${index}` );
    console.log( index );
    if ( questionElement && innerFormRef.current ) {
      innerFormRef.current.scrollTo( {
        top: questionElement.offsetTop,
        behavior: 'smooth'
      } );
      setCurrentQuestion( index );
    }
  };

  useEffect( () => {
    if ( !validationHighlight ) return;
    if ( shouldIterate ) {
      if ( invalidFields && invalidFields.length ) {
        const index = questions.findIndex( q => {
          const nameMatch = q.name === invalidFields[0];
          if ( nameMatch ) return nameMatch;
          else if ( q.additionalInput ) {
            return q.additionalInput.name === invalidFields[0];
          } else return false;
        } );
        if ( index === -1 ) {
          setInvalidFields( invalidFields.slice( 1 ) );
        } else {
          scrollToQuestion( index + 1 )();
        }
      } else {
        if ( innerFormRef.current ) {
          innerFormRef.current.scrollTo( {
            top: 1000000,
            behavior: 'smooth'
          } );
        }
        setShouldIterate( false );
      }
    }
  }, [ invalidFields, shouldIterate ] );

  const onFormSubmit = ( values: T ) => {
    setLookForValidation( false );
    onSubmit( values );
  };

  const onFormEnterPress : KeyboardEventHandler = event => {
    console.log( event.key );
    if ( event.key === 'Enter' ) event.preventDefault();
  };

  const resultFormRef = useMemo( () => (
    formRef ? mergeRefs( [ innerFormRef, formRef ] ) : innerFormRef
  ), [ formRef ] );

  return (
    <section className={styles['survey']}>
      {title && <h1>{title}</h1>}
      <img className={styles['background-img']} src={logo} />
      { !hideNavigation && (
        <Navigation
          totalCount={questions.length + questions.filter( q => q.additionalInput ).length}
          completedCount={answeredQuestionsCount}
          goNext={scrollToQuestion( currentQuestion + 1 )}
          goPrev={scrollToQuestion( currentQuestion - 1 )}
          notCompletedExtra={notCompletedExtra}
          ignoreCount={ignoreCount}
        />
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        <Form 
          ref={resultFormRef} 
          onKeyDown={onFormEnterPress}
        >
          <AnsweredQuestionsObserver 
            onCountChange={setAnsweredQuestionsCount} 
            onInvalidFieldsChange={lookForValidation ? setInvalidFields : undefined}
            onSubmit={lookForValidation ? triggerIterate : undefined}
          />
          {description && <Description {...description} />}
          {questions.map( ( question, index ) => (
            <Question
              {...question} 
              key={index} 
              number={index + 1} 
              onButtonClick={scrollToQuestion( index + 2 )}
              fade={invalidFields && shouldIterate && !invalidFields.includes( question.name )}
              isCurrent={index + 1 === currentQuestion}
              scrollToQuestion={scrollToQuestion}
            />
          ) )}
          <Button
            text='Submit'
            type='submit'
          />
        </Form>
      </Formik>
    </section>
  );
};