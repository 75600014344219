import { SurveySort } from './types';


const byRole : SurveySort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.role > b.role ? 1 : -1 )
    : items.sort( ( a,b ) => a.role < b.role ? 1 : -1 );
};

const byCreationDate : SurveySort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.createdAt > b.createdAt ? 1 : -1 )
    : items.sort( ( a,b ) => a.createdAt < b.createdAt ? 1 : -1 );
};


const bySubmissionsCount : SurveySort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.submissions.length > b.submissions.length ? 1 : -1 )
    : items.sort( ( a,b ) => a.submissions.length < b.submissions.length ? 1 : -1 );
};


const byFitTechSubmissionsCount : SurveySort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.fitTechSubmissions.length > b.fitTechSubmissions.length ? 1 : -1 )
    : items.sort( ( a,b ) => a.fitTechSubmissions.length < b.fitTechSubmissions.length ? 1 : -1 );
};




export const surveySortings = {
  byRole,
  byCreationDate,
  bySubmissionsCount,
  byFitTechSubmissionsCount,
};