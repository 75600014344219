import { useFormikContext } from 'formik';
import { Select, SelectProps } from '../../ui';

export type SelectFieldProps = Omit<SelectProps, 'onChange' | 'defaultValue'> & {
  name: string
  isDisabled?: boolean
}

export const SelectField = ( props: SelectFieldProps ) => {
  
  const { setFieldValue, initialValues } = useFormikContext();
  
  const onChange = ( value: string ) => {
    setFieldValue( props.name, value );
  };
  
  return (
    <Select
      {...props}
      onChange={onChange}
      isDisabled={props.isDisabled}
      defaultValue={( initialValues as Record<string, string> )[props.name]}
    />
  );
};