import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { useGetArchivedAssessmentsQuery, useGetAssessmentsQuery, useGetAssessmentsWithActiveFitTechQuery, useGetCurrentCompanyQuery } from '../../../services';
import { CulturalAssessment } from '../../../services/models';
import { surveySortings } from '../../../utils/sortFunctions';
import { Button } from '../../controls';
import { TableListQuery } from '../../ui';
import { ArchiveButton } from './ArchiveButton';
import { EditButton } from './EditButton';

export interface CulturalAssessmentListScreenProps {
  archive?: true,
  fitTech?: true
}

export const CulturalAssessmentListScreen = ( { archive, fitTech } : CulturalAssessmentListScreenProps ) => {

  const currentCompany = useGetCurrentCompanyQuery();

  const navigate = useNavigate();

  const onAssessmentClick = ( assessment: CulturalAssessment ) => {
    navigate( ( fitTech 
      ? routesPaths.fitTechResults
      : routesPaths.culturalAssessmentSingle
    ).replace( ':id', assessment._id ) );
  };

  const goCreateSurvey = () => {
    navigate( routesPaths.createCulturalAssessment );
  };
  
  return (
    <>
      <div className="buttons" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button text='Create Cultural Assessment' onClick={goCreateSurvey} styleType='alter' style={{ width: 'auto' }} />
        <Button text='See DNA' onClick={ () => { navigate( routesPaths.assessmentResult ); }} styleType='alter' style={{ width: 'auto', marginLeft:25 }} />
      </div>
      <TableListQuery<CulturalAssessment, 'actions'>
        fieldsToDisplay={[ 
          'companyId', 'role', fitTech ? 'fitTechSubmissions' : 'submissions', 'createdAt', 'actions' 
        ]}
        headings={{
          companyId: 'Organization',
          createdAt: 'Timestamp',
          role: 'Role',
          submissions: 'Submissions',
          actions: 'Actions',
          fitTechSubmissions: 'Hiring Assessment Submissions'
        }}
        queryHook={(
          fitTech
            ? useGetAssessmentsWithActiveFitTechQuery
            : archive ? useGetArchivedAssessmentsQuery : useGetAssessmentsQuery
        )}
        hidePagination
        fieldTransformations={{
          companyId: () => currentCompany.data?.name || '',
          submissions: ( submissions ) => submissions.length.toString(),
          createdAt: ( date ) => DateTime.fromJSDate( date ).toFormat( 'LL.dd.yyyy hh:mm' ),
          fitTechSubmissions: submissions => submissions.length.toString()
        }}
        onItemClick={onAssessmentClick}
        customFields={{
          actions: assessment => (
            <>
              <EditButton assessment={assessment} />
              {!archive && <ArchiveButton assessment={assessment} />}
            </>
          )
        }}
        sortFunctions={{
          role: surveySortings.byRole,
          createdAt: surveySortings.byCreationDate,
          submissions: surveySortings.bySubmissionsCount,
          fitTechSubmissions: surveySortings.byFitTechSubmissionsCount
        }}
      />
    </>
  );
};