import { useState, useEffect } from 'react';
import { DndProvider, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useGetAssessmentAttributesQuery } from '../../../services';
import { CulturalAssessmentAttribute } from '../../../services/models';
import { Basket } from './Basket';
import { Card } from './Card';
import { CardDragLayer } from './CardDragLayer';
import styles from './CulturalAssessmentGameScreen.module.sass';
import { EssentialsMinimumCard } from './EssentialsMinimumCard';
import { FinishCard } from './FinishCard';
import { ProgressBar } from './ProgressBar';
import { SortEssentialsModal } from './SortEssentialsModal';
import { ThanksCard } from './ThanksCard';
import { WelcomeCard } from './WelcomeCard';
import { ReactComponent as ArrowHelpIcon } from '../../../assets/icons/arrow-help.svg';
import { BasketContext } from './BasketContext';
import { GuideCard } from './GuideCard';
'use strict';
export const CulturalAssessmentGameScreen = ( ) => {
  const attributes = useGetAssessmentAttributesQuery();

  const [ currentIndex, setCurrentIndex ] = useState( 0 );
  const [ deletedAttributes, setDeletedAttributes ] = useState<CulturalAssessmentAttribute[]>( [] );

  const [ essential, setEssential ] = useState<CulturalAssessmentAttribute[]>( [] );
  const [ nice, setNice ] = useState<CulturalAssessmentAttribute[]>( [] );
  const [ notEssential, setNotEssential ] = useState<CulturalAssessmentAttribute[]>( [] );

  const [ submitted, setSubmitted ] = useState( false );
  const [ cardClickOffset, setCardClickOffset ] = useState<XYCoord>();
  const [ essentialsSorted, setEssentialsSorted ] = useState( false );
  const [ started, setStarted ] = useState( false );
  const [ guide, setGuide ] = useState( false );
  const [ essentialForceOpen, setEssentialForceOpen ] = useState( false );
 
  const [ data, setData ] = useState( {} );
  useEffect( ()=>{
    const name = localStorage.getItem( 'name' );
    const email = localStorage.getItem( 'email' );
    setData( {
      name,
      email
    } );
    setTimeout( ()=>{
      console.log( 'data', data );
    }, 2000 );
  }, [] );
  const onAttributeDrop = ( attribute: CulturalAssessmentAttribute ) => {
 
    if ( attributes.data ) {
      if ( deletedAttributes.find( attr => attr._id === attribute._id ) ) {
        setDeletedAttributes( deletedAttributes.slice( 1 ) );
      } else {
        setCurrentIndex( currentIndex + 1 );
      }
    }
  };

  const onCardMouseDown : React.MouseEventHandler = event => {
    setCardClickOffset( {
      x: event.nativeEvent.offsetX,
      y: event.nativeEvent.offsetY,
    } );
  };

  const onCardTouchStart : React.TouchEventHandler = event => {
    const rect = ( event.target as HTMLDivElement ).getBoundingClientRect();
    const x = event.targetTouches[0].clientX - rect.left;
    const y = event.targetTouches[0].clientY - rect.top;
    setCardClickOffset( { x,y } );
  };
  
  if ( !attributes.data ) return null;

  const hasDeleted = !!deletedAttributes.length;
  const isFinished = !hasDeleted && currentIndex >= attributes.data.length;
  const essentialMinimumReached = essential.length >= 12;

  const isTouchDevice =  ( 'ontouchstart' in window );

  const onDeleteAttribute = ( attribute: CulturalAssessmentAttribute ) => {
    setDeletedAttributes( deletedAttributes.concat( attribute ) );
  };

  const onRankEssentialBucket = () => {
    setEssentialForceOpen( true );
    setEssentialsSorted( true );
  };

  const gameCardsRender = () => {
    if ( !attributes.data ) return null;
    return (
      <>
        {
          !hasDeleted ? (
            <Card
              attribute={attributes.data[currentIndex]}
              onMouseDown={onCardMouseDown}
              onTouchStart={onCardTouchStart}
            />
          ) : (
            <Card
              attribute={deletedAttributes[0]}
              onMouseDown={onCardMouseDown}
              onTouchStart={onCardTouchStart}
            />
          )
        }
      </>
    );
  };

  const beforeSubmitRender = () => {
    if ( !attributes.data ) return null;
    return (
      <>
        {isFinished ? (
          essentialMinimumReached
            ? (
              <FinishCard
                sortedAttributes={{ essential, nice, notEssential }} 
                afterSubmit={() => setSubmitted( true )}
                formData={ data }
              />
            ) : (
              <EssentialsMinimumCard />
            )
        ) : (
          gameCardsRender()
        )}
        <div className={styles['tip']}>
          <h3>How important is this attribute?</h3>
          <p>
            Drag the card above into one of the buckets.<br/>
            Click on a bucket to see it's contents.
          </p>
        </div>
        <div className={styles['baskets']}>


          <BasketContext.Provider value={{ attributes: notEssential, setAttributes: setNotEssential }}>
            <Basket
              stars={1}
              title='Not as Important'
              onDrop={onAttributeDrop}
              onDeleteAttribute={onDeleteAttribute}
            />
          </BasketContext.Provider>

          {/* //////////// */}

          <BasketContext.Provider value={{ attributes: nice, setAttributes: setNice }}>
            <Basket
              stars={2}
              title='Nice to Have'
              onDrop={onAttributeDrop}
              onDeleteAttribute={onDeleteAttribute}
            />
          </BasketContext.Provider>

          {/* //////////// */}
          
          <BasketContext.Provider value={{ attributes: essential, setAttributes: setEssential }}>
            <Basket
              stars={3}
              title='Essential'
              required={12}
              onDrop={onAttributeDrop}
              onDeleteAttribute={onDeleteAttribute}
              forceOpenModal={essentialForceOpen}
              setForceOpenModal={setEssentialForceOpen}
            />
          </BasketContext.Provider>
         
        </div>
        <div className={styles['hint']}>
          <em><span>Remember, the Essential bucket requires 12 cards!</span></em>
        </div>
        <ProgressBar done={currentIndex - deletedAttributes.length} total={attributes.data.length} />
        <SortEssentialsModal
          isOpen={isFinished && !essentialsSorted && essentialMinimumReached}
          onClick={onRankEssentialBucket}
        />
      </>
    );
  };

  const afterWelcomeRender = () => (
    !submitted ? (
      beforeSubmitRender()
    ) : (
      <ThanksCard />
    )
  );


  const guideRender = () => (
    <GuideCard
      onClick={() => setGuide( false )}
    />
  );

  return (
    <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
      <CardDragLayer cardClickOffset={cardClickOffset} />
      <div className={styles['container']}>
        <div className={styles['content']}>
          <div className={styles['card-content']}>
            { afterWelcomeRender() }
          </div>
        </div>
      </div>
    </DndProvider>
  );
};