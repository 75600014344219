import { useGetAssessmentsQuery, useGetCurrentUserQuery } from '../../../services';
import { Box } from '../../ui';
import { ReactComponent as CreateModelIcon } from '../../../assets/icons/icon-create-competency.svg';
import { ReactComponent as ModelIcon } from '../../../assets/icons/icon-competency.svg';
import styles from './Dashboard.module.sass';
import { Link } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { RiArrowRightLine } from 'react-icons/ri';

export const Dashboard = () => {

  const currentUser = useGetCurrentUserQuery();
  const assessmentModels = useGetAssessmentsQuery();

  return (
    <>
      {currentUser.data && (
        <Box style={{ fontSize: 24 }}>
          Welcome {currentUser.data.name}!
        </Box>
      )}
      
      <div className={styles['items']}>
      <Box>
          <h3>Create Cultural Assessment</h3>
          <div className={styles['icon']}>
            <CreateModelIcon />
          </div>
          <Link to={routesPaths.createCulturalAssessment}>
            Create New <RiArrowRightLine />
          </Link>
        </Box>
        <Box>
          <h3>Cultural Assessment Models</h3>
          <div className={styles['icon']}>
            <ModelIcon />
            {assessmentModels.data && assessmentModels.data.length}
          </div>
          <Link to={routesPaths.culturalAssessmentSingle}>
            View <RiArrowRightLine />
          </Link>
        </Box>
      </div>
    </>
  );
};