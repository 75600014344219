import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useState } from 'react';
import { FitTechSubmitFields, fitTechSubmitSchema, useGetFitTechDataQuery, useGetFitTechQuestionsQuery, useIsFitTechActiveQuery, useSubmitFitTechMutation } from '../../../services';
import { Survey, SurveyProps } from '../../ui/Survey';
import { ClosedQuestion } from '../../ui/Survey/QuestionRenders';
import styles from './FitTechScreen.module.sass';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';

export const FitTechScreen = () => {

  const [ token, setToken ] = useState<string>();
  const [ started, setStarted ] = useState( false );


  const isActive = useIsFitTechActiveQuery( token || skipToken );
  const questions = useGetFitTechQuestionsQuery( token || skipToken );
  const fitTechData = useGetFitTechDataQuery( token || skipToken );  
  const [ submitFitTech ] = useSubmitFitTechMutation();


  useEffect( () => {
    const match = window.location.href.match( new RegExp( 
      `${routesPaths.fitTech.replace( '/*', '' )}/(.*)` 
    ) );
    if ( match && match[1] ) {
      setToken( match[1] );
    }
  }, [] );


  const initialValues = useMemo( () => {
    const values: FitTechSubmitFields = {
      age: '',
      email: '',
      ethnicity: '',
      firstName: '',
      gender: '',
      lastName: ''
    };
    if ( !questions.data ) return values;
    questions.data.questions.forEach( question => values[question._id] = '' );
    return values;
  }, [ questions.data ] );

  const validationSchema = useMemo( () => {
    if ( !questions.data ) return undefined;
    const ids = questions.data.questions.map( question => question._id )
      .concat( questions.data.secondaryQuestions.map( question => `secondary_${question._id}` ) );
    return fitTechSubmitSchema( ids );
  }, [ questions.data ] ); 
  const navigate = useNavigate();
  const fitTechQuestions = useMemo( () => {
    if ( !questions.data ) return [];
    const mainQuestions = questions.data.questions.map( question => ( {
      name: question._id,
      label: question.wording,
      hideButton: true,
      render: ClosedQuestion
    } ) ) as SurveyProps<typeof initialValues>['questions'];
    const secondaryQuestions = questions.data.secondaryQuestions.map( question => ( {
      name: `secondary_${question._id}`,
      label: question.wording,
      hideButton: true,
      render: ( defaultProps ) => (
        <ClosedQuestion
          {...defaultProps}
          variants={[
            { label: question.positive, value: 'positive' },
            { label: `Both ${question.positive} and ${question.negative}`, value: 'both' },
            { label: question.negative, value: 'negative' },
          ]}
          labelsStyle={{ width: 400 }}
        />
      ),

    } ) ) as SurveyProps<typeof initialValues>['questions'];
    return mainQuestions.concat( secondaryQuestions );
  }, [ questions.data ] );

  const onSubmit = ( values: FitTechSubmitFields ) => {
    if ( token && fitTechData.data ) {
      submitFitTech( { ...values, token } ).unwrap().then( ( res ) => {
      navigate( '/hiringAssessment-thankyou' );
        // if ( fitTechData.data ) window.location.replace( fitTechData.data.companySiteUrl );
        // const linkEl = document.createElement( 'a' );
        // linkEl.download = res.fileName;
        // linkEl.href = res.fileName; 
        // linkEl.style.display = 'none';
        // document.body.append( linkEl );
        // linkEl.click();
        // linkEl.remove();
      } );
    }
  };

  if ( !questions.data || !fitTechData.data  || isActive.data === undefined ) return null;  

  if ( !isActive.data ) {
    navigate( '/assessments/payment' );
    // return (
    //   <div className={styles['notActive']} id={styles['fit-tech']}>
    //     <p>Hiring Assessment is not active</p>
    //   </div>
    // );
  }


  return (
    <div className={styles['container']} id={styles['fit-tech']}>
      
        <Survey
          hideNavigation
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validationHighlight={true}
          questions={[
            {
              label: `What is your email address on your ${fitTechData.data.companyName} application or resume?`,
              name: 'email',
              type: 'email'
            },
            {
              label: 'What is your first name?',
              name: 'firstName',
            },
            {
              label: 'What is your last name?',
              name: 'lastName',
            },
            ...fitTechQuestions,
            {
              label: <p style={{
                marginTop: '4.3rem',
                marginBottom: '-1.5rem'
              }}> OPTIONAL RESEARCH INFORMATION</p>,
              name: '',
              render: () => (
                <span style={{
                  color: '#9266ff',
                  fontFamily: 'Open Sans, sans-serif',
                  paddingBottom: '1.3rem'
                }}>
                  In order to keep our assessments up to the highest standard in accordance with federal law, please take a moment to provide some optional information. This information is only for StaffGeek internal research, and will not in any way be shared with your employer or prospective employer, or be used to modify results from the assessment you complete. If you would prefer not to answer the following questions, simply skip through the questions and click Submit.
                </span>
              ),
              hideButton: false,
              specificButtonText: 'Continue',
              skipIndex: true
            },
            {
              label: 'How do you identify your gender?',
              name: 'gender',
              render: defaultProps => (
                <ClosedQuestion
                  {...defaultProps}
                  variants={[
                    { label: 'Male', value: 'male' },
                    { label: 'Female', value: 'female' },
                    { label: 'Other / Non-binary', value: 'other' },
                    { label: 'Prefer not to disclose', value: 'preferNotToDisclose' }
                  ]}
                  labelsStyle={{ width: 250 }}
                />
              ),
              hideButton: true
            },
            {
              label: 'What is your age?',
              name: 'age',
              render: defaultProps => (
                <ClosedQuestion
                  {...defaultProps}
                  variants={[
                    { label: '20-29', value: '20-29' },
                    { label: '30-39', value: '30-39' },
                    { label: '40-49', value: '40-49' },
                    { label: '50+', value: '50+' },
                    { label: 'Prefer not to disclose', value: 'preferNotToDisclose' }
                  ]}
                />
              ),
              hideButton: true
            },
            {
              label: 'What is your race or ethnicity?',
              name: 'ethnicity',
              style: { minHeight: 500 },
              render: defaultProps => (
                <ClosedQuestion
                  {...defaultProps}
                  variants={[
                    { label: 'America Indian or Alaska Native', value: 'americaNative' },
                    { label: 'Asian', value: 'asian' },
                    { label: 'Black or African American', value: 'african' },
                    { label: 'Hispanic or Latino', value: 'latino' },
                    { label: 'Native Hawaiian or Other Pacific Islander', value: 'islander' },
                    { label: 'White', value: 'white' },
                    { label: 'Other', value: 'other' },
                    { label: 'Prefer not to disclose', value: 'preferNotToDisclose' },
                  ]}
                  labelsStyle={{ width: 'auto' }}
                />
              ),
              hideButton: true,
              // children: <Button text='Submit' type='submit' />
            }
          ]}
        />
    </div>
  );
};