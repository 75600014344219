import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseUrl } from '../baseInstance';
import { FitTechQuestionsResponse } from '../models';
import { FitTechSubmitFields } from '../types';

export const fitTechApi = createApi( {
  baseQuery: fetchBaseQuery( { baseUrl } ),
  reducerPath: 'fitTech',
  endpoints: build => ( {
    getFitTechQuestions: build.query<FitTechQuestionsResponse, string>( {
      query: token => ( {
        url: '/assessment/fitTech/questions',
        params: { token }
      } ),
      transformResponse: ( raw: FitTechQuestionsResponse ) => {
        return {
          questions: randomizeTillNoRepeat( raw.questions ),
          secondaryQuestions: randomizeTillNoRepeat( raw.secondaryQuestions )
        } as FitTechQuestionsResponse;
      }
    } ),
    getFitTechData: build.query<{
      companyName: string,
      companySiteUrl: string
    }, string>( {
      query: token => ( {
        url: '/assessment/fitTech/data',
        params: { token }
      } )
    } ),
    submitFitTech: build.mutation<{ fileName: string }, FitTechSubmitFields & { token: string }>( {
      query: fields => ( {
        url: '/assessment/fitTech',
        method: 'post',
        body: ( () => {
          const { age, email, ethnicity, firstName, gender, lastName, token, ...answers } = fields;
          const mainAnswers = Object.fromEntries( Object.entries( answers ).filter( ( [ key ] ) => (
            !key.includes( 'secondary' )
          ) ) );
          const secondaryAnswers = Object.fromEntries(
            Object.entries( answers )
              .filter( ( [ key ] ) => (
                key.includes( 'secondary' )
              ) )
              .map( ( [ key, value ] ) => [ key.replace( 'secondary_', '' ), value ] )
          );
          return {
            age, email, ethnicity, firstName, gender, lastName,
            answers: mainAnswers, token, secondaryAnswers
          };
        } )()
      } )
    } ),
    isFitTechActive: build.query<boolean, string>( {
      query: token => ( {
        url: '/assessment/fitTech/active',
        params: { token }
      } ),
    } )
  } )
} );


export const {
  useGetFitTechQuestionsQuery,
  useGetFitTechDataQuery,
  useSubmitFitTechMutation,
  useIsFitTechActiveQuery
} = fitTechApi;


function findRepeatIndex( questions: { key: string }[] ) {
  return questions.findIndex( ( el, i ) => {
    return questions[i - 1]?.key === el.key && questions[i - 2]?.key === el.key;
  } );
}

function randomizeAroundIndex( questions: { key: string }[], index: number, range = 10 ) {
  const bottomIndex = index - range > 0 ? index - range : 0;
  const topIndex = index + range < questions.length ? index + range : questions.length;
  return [
    ...questions.slice( 0, bottomIndex ),
    ...questions.slice( bottomIndex, topIndex ).sort( () => Math.random() - .5 ),
    ...questions.slice( topIndex )
  ];
}

function randomizeTillNoRepeat( questions: { key: string }[] ) {
  let randomized = questions.sort( () => Math.random() - .5 );
  let repeatIndex = findRepeatIndex( randomized );
  while ( repeatIndex !== -1 ) {
    randomized = randomizeAroundIndex( randomized, repeatIndex );
    repeatIndex = findRepeatIndex( randomized );
  }
  return randomized;
}