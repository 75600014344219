import React, { useState, useEffect, useMemo } from 'react';
import { Button } from '../../controls';
import { GuideCard } from './GuideCard';
import { routesPaths } from '../../../navigation/routes';
import { ToastContainer, toast } from 'react-toastify';
import styles from './CulturalAssessmentGameScreen.module.sass';

export const WelcomeCard = () => {
  const [ guide, setGuide ] = useState( false );
  const [ token, setToken ] = useState<string>();
  const [ formData, setFormData ] = useState( {
    name: '',
    email: '',
  } );

  useEffect( () => {
    const match = window.location.href.match( new RegExp(
      `${routesPaths.gameWelcome.replace( '/*', '' )}/(.*)`
    ) );
    if ( match && match[1] ) {
      console.log( 'match', match );
      setToken( match[1] );
    }
  }, [] );

  const gameUrl = useMemo( () => (
    typeof token === 'string'
      ? window.location.origin + routesPaths.game.replace( ':token/*', token )
      : undefined
  ), [ token ] );

  const handleInputChange = ( event: any ) => {
    const { name, value } = event.target;
    setFormData( {
      ...formData,
      [name]: value,
    } );
  };

  const handleSubmit = () => {
    if ( formData.name !== '' && formData.email !== '' ) {
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if ( !emailRegex.test( formData.email ) ) {
        toast.error( 'Please enter a valid email address' );
        return;
      }

      localStorage.setItem( 'name', formData.name );
      localStorage.setItem( 'email', formData.email );

      if ( gameUrl !== undefined ) {
        window.location.href = gameUrl;
      }
    } else {
      toast.error( 'Please fill in all required fields' );
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['content']} style={{ background: 'unset' }}>
        <div className={styles['card-content']}>
          {guide ? (
            <GuideCard onClick={() => setGuide( false )} />
          ) : (
            <div className={[
              styles['card1'], styles['no-drag'], { background: 'unset !important' }
            ].join( ' ' )}>
              <h1>Cultural Assessment</h1>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p className={styles['description']}>
                  This exercise aims to help identify the most critical attributes for performance within your organization. You will be shown a number of cards and asked whether or not each competency is Essential, Nice To Have, or Not Important. Please drag and drop each card into the bucket you feel is most appropriate.
                </p>
              </div>
              <label>Full Name<span>*</span></label>
              <input className={`${styles['text-input']}`} name='name' onChange={handleInputChange} required />
              <label>Email Address<span>*</span></label>
              <input className={`${styles['text-input']}`} name='email' type='email' onChange={handleInputChange} required />
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Button
                  onClick={handleSubmit}
                  text='Start'
                  styleType='whiteText'
                  style={{ backgroundColor: '#8250FF', fontSize: 14, textTransform: 'uppercase', width: '90%' }}
                />
              </div>
              <Button
                onClick={() => setGuide( true )}
                text='How to use the Culture Assessment?'
                style={{ backgroundColor: '#fff', color: '#8250FF', fontSize: 14, width: '100%', textAlign: 'center', padding: 0 }} />
              <p><em>If you have any questions, please contact </em><a href="mailto:Support@StaffGeek.com">Support@StaffGeek.com</a>.</p>
              <ToastContainer />
            </div>
          )
          }
        </div>
      </div>
    </div>

  );
};
