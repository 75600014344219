import { useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { RiCloseFill } from 'react-icons/ri';
import mergeRefs from 'react-merge-refs';
import { CulturalAssessmentAttribute } from '../../../services/models';
import styles from './CulturalAssessmentGameScreen.module.sass';
import { GameDragTypes } from './types';

export interface BasketModalAttributeProps {
  attribute: CulturalAssessmentAttribute,
  onDelete: ( attribute: CulturalAssessmentAttribute ) => void,
  order: number,
  isLast: boolean,
  onDrop: ( 
    dropped: CulturalAssessmentAttribute,
    target: CulturalAssessmentAttribute,
    position: 'before' | 'after'
  ) => void,
  onDoubleClick?: ( attribute: CulturalAssessmentAttribute ) => void
}

interface Position {
  x: number,
  y: number
}

export type BasketCardItem = CulturalAssessmentAttribute & (
  Pick<BasketModalAttributeProps, 'isLast' | 'order'>
) & { position: Position };

export const BasketModalAttribute = ( { attribute, onDelete, order, isLast, onDrop, onDoubleClick } : BasketModalAttributeProps ) => {

  const [ initialPosition, setInitialPosition ] = useState<Position | null>( null );
  const [ currentPosition, setCurrentPosition ] = useState<Position | null>( null );
  const containerRef = useRef<HTMLDivElement | null>( null );

  const [ { isDragging }, dragRef ] = useDrag( {
    type: GameDragTypes.BUCKET_CARD,
    collect: monitor => ( {
      isDragging: !!monitor.isDragging(),
      // a: monitor.
    } ),
    item: { ...attribute, isLast, order, position: initialPosition } as BasketCardItem
  } );

  const [ { isLeftOver }, leftDropRef ] = useDrop( () => ( {
    accept: GameDragTypes.BUCKET_CARD,
    collect: monitor => ( { isLeftOver: !!monitor.isOver() } ),
    drop: ( item: BasketCardItem ) => {
      onDrop( item, attribute, 'before' );
    }
  } ), [ onDrop ] );
  const [ { isPlaceholderOver }, placeholderDropRef ] = useDrop( () => ( {
    accept: GameDragTypes.BUCKET_CARD,
    collect: monitor => ( { isPlaceholderOver: !!monitor.isOver() } ),
    drop: ( item: BasketCardItem ) => {
      onDrop( item, attribute, 'before' );
    }
  } ), [ onDrop ] );

  const [ { isOver, dropAttribute } , dropRef ] = useDrop( () => ( {
    accept: GameDragTypes.BUCKET_CARD,
    collect: monitor => ( {
      dropAttribute: monitor.getItem() as BasketCardItem,
      isOver: !!monitor.isOver() && monitor.getItem()._id !== attribute._id,
    } ),
    canDrop: ( item: BasketCardItem ) => item._id !== attribute._id,
    drop: ( item, monitor ) => {
      if ( !monitor.didDrop() ) onDrop( item, attribute, 'after' );
    },
    hover: () => {
      if ( containerRef.current ) {
        setCurrentPosition( { 
          x: containerRef.current.offsetLeft,
          y: containerRef.current.offsetTop
        } );
      }
    }
  } ), [ onDrop ] );

  const isLastInRow = useMemo( () => {
    if ( !dropAttribute || !initialPosition || !currentPosition ) return false;
    return (
      currentPosition.y < dropAttribute.position.y && order % 3 === 0
    ) || (
      currentPosition.x > initialPosition.x && currentPosition.y < initialPosition.y
    );
  }, [ order, dropAttribute, initialPosition, currentPosition ] );

  useEffect( () => {
    if ( !initialPosition && containerRef.current ) setInitialPosition( {
      x: containerRef.current.offsetLeft,
      y: containerRef.current.offsetTop,
    } );
  }, [ containerRef.current ] );

  const onAttributeDoubleClick = onDoubleClick ? () => onDoubleClick( attribute ) : undefined;
  
  return (
    <>
      {( isOver || isPlaceholderOver ) && isLastInRow && (
        <div 
          className={styles['attribute-container']}
          ref={placeholderDropRef}>
        </div>
      )}
      <div 
        className={[
          styles['attribute-container'],
          isOver ? styles['over'] : undefined,
          isDragging ? styles['dragging'] : undefined,
          isOver ? isLeftOver ? styles['left'] : styles['right'] : undefined,
          isLastInRow ? styles['last-in-row'] : undefined,
        ].join( ' ' )} 
        ref={mergeRefs( [ containerRef, dropRef ] )}
      >
        {isOver && !isLastInRow && (
          <>
            <div className={styles['left']} ref={leftDropRef}></div>
          </>
        )}
        <div 
          className={[ 
            styles['attribute'],
            isDragging ? styles['dragging'] : undefined,
          ].join( ' ' )} 
          ref={dragRef}
          onClick={onAttributeDoubleClick}
          onDoubleClick={onAttributeDoubleClick}
        >
          <div className={styles['order']}>
            <span>{order}</span>
          </div>
          <span>{attribute.title}</span>
          <div className={styles['delete']} onClick={onDelete.bind( null, attribute )}>
            <RiCloseFill />
          </div>
        </div>
      </div>
    </>
  );
};