import { NavLink, To } from 'react-router-dom';
import styles from './Navigation.module.sass';
import { NavigationSubItem, NavigationSubItemProps } from './NavigationSubItem';

export interface NavigationItemProps {
  icon: React.FunctionComponent,
  label: string,
  to: To,
  isActive?: boolean,
  subItems?: NavigationSubItemProps[],
  disabled?: boolean,
}

export const NavigationItem = ( { icon: Icon, label, to, subItems, isActive, disabled } : NavigationItemProps ) => {

  const handleClick = ( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
    if( disabled ) e.preventDefault();
  };
  
  return(
  <li className={styles['navigation-item']}>
    <NavLink onClick={handleClick} to={to} className={ isActive ? styles['active'] : undefined } >
      <Icon />
      <span>{label}</span>
    </NavLink>
    {subItems?.map( ( item, index ) => <NavigationSubItem key={index} {...item} /> )}
  </li>
);};