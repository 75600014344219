import { AppStateController } from './AppStateController';
import { useTypedSelector } from '../../state';
import { AuthController } from './AuthController';


export const RootController = () => {
  
  const { isAuthorized } = useTypedSelector( state => state.authSlice );
  
  return (
    <>
      <AuthController />
      {isAuthorized && <AppStateController />}
    </>
  );
};