import { UsersSort } from './types';

const byName : UsersSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.name > b.name ? 1 : -1 )
    : items.sort( ( a,b ) => a.name < b.name ? 1 : -1 );
};

const byEmail : UsersSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.email > b.email ? 1 : -1 )
    : items.sort( ( a,b ) => a.email < b.email ? 1 : -1 );
};

const byTeam : UsersSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.team > b.team ? 1 : -1 )
    : items.sort( ( a,b ) => a.team < b.team ? 1 : -1 );
};

const byRegistrationDate : UsersSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.createdAt > b.createdAt ? 1 : -1 )
    : items.sort( ( a,b ) => a.createdAt < b.createdAt ? 1 : -1 );
};

const byLoginDate : UsersSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.lastLogin > b.lastLogin ? 1 : -1 )
    : items.sort( ( a,b ) => a.lastLogin < b.lastLogin ? 1 : -1 );
};


const byRole : UsersSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a,b ) => a.role > b.role ? 1 : -1 )
    : items.sort( ( a,b ) => a.role < b.role ? 1 : -1 );
};

const byLastName: UsersSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a, b ) => {
        const lastNameA = ( a.name.split( ' ' )[1] || '' ).toLowerCase();
        const lastNameB = ( b.name.split( ' ' )[1] || '' ).toLowerCase();
        return lastNameA.localeCompare( lastNameB );
      } )
    : items.sort( ( a, b ) => {
        const lastNameA = ( a.name.split( ' ' )[1] || '' ).toLowerCase();
        const lastNameB = ( b.name.split( ' ' )[1] || '' ).toLowerCase();
        return lastNameB.localeCompare( lastNameA );
      } );
};

const byFirstName: UsersSort = ( items, order ) => {
  return order === 'asc'
    ? items.sort( ( a, b ) => {
        const lastNameA = ( a.name.split( ' ' )[0] || '' ).toLowerCase();
        const lastNameB = ( b.name.split( ' ' )[0] || '' ).toLowerCase();
        return lastNameA.localeCompare( lastNameB );
      } )
    : items.sort( ( a, b ) => {
        const lastNameA = ( a.name.split( ' ' )[0] || '' ).toLowerCase();
        const lastNameB = ( b.name.split( ' ' )[0] || '' ).toLowerCase();
        return lastNameB.localeCompare( lastNameA );
      } );
};

export const usersSortings = {
  byName,
  byEmail,
  byTeam,
  byRegistrationDate,
  byLoginDate,
  byRole,
  byFirstName,
  byLastName
};