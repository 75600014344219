import { MouseEventHandler, ReactChild, ReactChildren } from 'react';
import styles from './Button.module.sass';

export interface ButtonProps {
  text: string,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  children?: ReactChildren | ReactChild | null,
  type?: 'button' | 'submit',
  styleType?: 'default' | 'whiteText' | 'nice' | 'transparent' | 'alter' | 'alter-light' | 'mainText',
  style?: React.CSSProperties,
  href?: string,
  buttonRef?: React.Ref<HTMLElement>,
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'],
  disabled?: boolean,
  isChildrenBefore?: boolean
}

export const Button = ( { 
  text, children, onClick, type='button', styleType='default', 
  style, href, buttonRef, target, disabled, isChildrenBefore
} : ButtonProps ) => {


  
  return href ? (
    <a
      href={href}
      className={`${styles['button']} ${styles[styleType]}`}
      style={style}
      ref={buttonRef as never}
      target={target}
    >
      {text}
    </a>
  ) : (
    <button
      style={style}
      type={type}
      onClick={onClick}
      className={`${styles['button']} ${styles[styleType]}`}
      ref={buttonRef as never}
      disabled={disabled}
    >
      { isChildrenBefore && children }
      {text}
      { !isChildrenBefore && children }
    </button>
  );
};