import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { NavigationPanel, TopBar } from '../../components/global';
import { useGetCurrentUserQuery, useGetAssessmentByCompanyQuery } from '../../services';
import { useTypedSelector } from '../../state';
import { authorizedNavigationItems, authorizedRoutes, noBackgroundPaths, renderRoutes, unauthorizedNavigationItems, unauthorizedRoutes } from '../routes';
import styles from './MainRouter.module.sass';
import { appActions } from '../../state';
import { useDispatch } from 'react-redux';

export const MainRouter = () => {

  const appState = useTypedSelector( state => state.appSlice );
  const dispatch = useDispatch();
  const { isAuthorized } = useTypedSelector( state => state.authSlice );
  const currentUser = useGetCurrentUserQuery( !isAuthorized ? skipToken : undefined );
  const assessment = useGetAssessmentByCompanyQuery( !isAuthorized ? skipToken : '1' );

  useMemo( () => {
    if( assessment.data?._id ){
      dispatch( appActions.setIsShown( false ) );
    }
  }, [ assessment.data ] );

  const { pathname } = useLocation();

  const shouldHideBackground = useMemo( () => {
    return noBackgroundPaths.map( path => (
      new RegExp( `^${path.replace( ':id', '(.{24})' )}$` ).test( pathname )
    ) ).reduce( ( result, current ) => current || result, false );
  }, [ pathname ] );

  if ( !currentUser.data && currentUser.isLoading ) return null;

  return (
    <div 
      className={[
        styles['main-container'],
        shouldHideBackground ? styles['hide-background'] : undefined
      ].join( ' ' )}
    >
      <aside className={styles['aside']}>
        <NavigationPanel items={
          isAuthorized && currentUser.data
            ? authorizedNavigationItems( currentUser.data.role, appState ) 
            : unauthorizedNavigationItems
        } />
      </aside>
      <main>
        <TopBar />
        <div className={styles['content']}>
            <Routes>
              {
                renderRoutes( isAuthorized && currentUser.data 
                  ? authorizedRoutes( currentUser.data.role ) 
                  : unauthorizedRoutes )
              }
              <Route path='*' element={<Navigate to={'/'} />} />
            </Routes>
        </div>
      </main>
    </div>
  );
};