import { useEffect, useState, useMemo } from 'react';
import logo from '../../../assets/img/staffgeek_logo.png';
import { Button } from '../../controls';
import styles from './FitTechScreen.module.sass';
import { routesPaths } from '../../../navigation/routes';
import { useGetFitTechDataQuery, useIsFitTechActiveQuery } from '../../../services';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../../../state';



  export const BeforeStart = (  ) => {
    
    const [ token, setToken ] = useState<string>();
    const navigate = useNavigate();
    const isActive = useIsFitTechActiveQuery( token || skipToken );
    const fitTechData = useGetFitTechDataQuery( token || skipToken );
    const { hasFitTech } = useTypedSelector( ( state ) => state.appSlice );
    
    
    useEffect( () => {
      const match = window.location.href.match( new RegExp( 
        `${routesPaths.fitTechBefor.replace( '/*', '' )}/(.*)` 
      ) );
      if ( match && match[1] ) {
        console.log( 'match', match );
        setToken( match[1] );
      }
    }, [] );
    const gameUrl = useMemo( () => (
      typeof token === 'string'
      ? window.location.origin + routesPaths.fitTech.replace( '*', token )
      : undefined
      ), [ token ] );
      
  const onStart = ()=>{
    if ( gameUrl ) window.open( gameUrl, '_blank' );
  };

  const handleKeyPress = ( event: KeyboardEvent ) => {
    if ( event.key === 'Enter' ) {
      onStart();
    }
  };
  useEffect( () => {
    document.addEventListener( 'keydown', handleKeyPress );
    return () => {
      document.removeEventListener( 'keydown', handleKeyPress );
    };
  }, [] );
 


//   if ( !hasFitTech ) {
//     navigate( '/assessments/payment' );
//   return (
//     <div className={styles['notActive']} id={styles['fit-tech']}>
//       <p>Hiring Assessment is not active</p>
//     </div>
//   );
// }



  return (
    <div className={styles['intro']}>
      <img src={logo} alt="StaffGeek logo" style={{ maxWidth: '33rem', height: 'auto' }} />
      <h1>Hiring Assessment Sequencing</h1>
      <p>
        To find the best fitment for you, we need to better understand
        how much you match the Company DNA (Distinct Native Attributes).
        Fill out the following survey to begin the sequencing.
      </p>
      <Button text='Start' onClick={onStart} />
    </div>
  );
};