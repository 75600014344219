import styles from './FitTechthankyou.module.sass';
import { ReactComponent as DividerIcon } from '../../../assets/icons/divider.svg';
import { Button } from '../../controls';


export const FitTechthankyou = () => {
  return (
    <div className={styles['container'] }>
      <div className={[
        styles['card'], styles['no-drag']
      ].join( ' ' )}>
        <h1>Thank you!</h1>
        <DividerIcon className={styles['divider']} />
        <p className={styles['description']}>
        Your submission has been successfully received.
        </p> 
      </div>
    </div>
  );
};