import { useField, useFormikContext } from 'formik';
import { TextInputView, TextInputViewProps } from './TextInputView';

export interface TextInputProps extends Omit<TextInputViewProps, 'value' | 'onChange'> {
  customOnChange?: ( value: string ) => void;
}

export const TextInput = ( props : TextInputProps ) => {
  const [ field, meta ] = useField( props.name );

  const { values, handleChange } = useFormikContext();

  const inputValue = ( values as Record<string, string> )[props.name];

  const handleInputChange = ( value: string ) => {
    handleChange( props.name )( value );
    if ( props.customOnChange ) {
      props.customOnChange( value );
    }
  };
  
  return (
    <>
      <TextInputView
        {...props}
        value={inputValue}
        onChange={handleInputChange}
      />
      {props?.isSubmitForm && meta.touched && meta.error && (
        <div style={{ color: 'red' }}>{meta.error}</div>
      )}
    </>
  );
};