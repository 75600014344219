import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as DividerIcon } from '../../../assets/icons/divider.svg';
import { SubmitGameFields, submitGameSchema, useGetAssessmentGroupsQuery, useSubmitGameResultsMutation } from '../../../services';
import { CulturalAssessmentAttribute } from '../../../services/models';
import { Button, TextInput } from '../../controls';
import { SelectField } from '../../controls/Select/Select';
import styles from './CulturalAssessmentGameScreen.module.sass';
import { useNavigate } from 'react-router-dom';

export interface FinishCardProps {
  sortedAttributes: {
    essential: CulturalAssessmentAttribute[],
    nice: CulturalAssessmentAttribute[],
    notEssential: CulturalAssessmentAttribute[],
  },
  afterSubmit?: () => void,
  formData: any
}

export const FinishCard = ( { sortedAttributes, afterSubmit, formData } : FinishCardProps ) => {

  const [ isModalOpen, setModalOpen ] = useState( false ); 

  const navigate = useNavigate();

  const [ token, setToken ] = useState<string>();
  
  const groups = useGetAssessmentGroupsQuery( token || skipToken );

   
  const [ submitResults ] = useSubmitGameResultsMutation();
  console.log( 'groups.data.cultureNeeds', groups.data );
  useEffect( () => {
    const regexMatch = window.location.href.match( /^https?:\/\/.*\/game\/(.*)$/ );
    if ( regexMatch ) {
      setToken( regexMatch[1] );
    }
  }, [] );

  
  if ( !groups.data ) return null;

  const initialValues = {
    name: '', email: '', group: '', cultureNeeds: groups.data.cultureNeeds
  } as SubmitGameFields;

  const onSubmit = ( ) => {
    if ( token ) {  
      submitResults( {
        ...formData,
        group: groups?.data?.groups[0],
        cultureNeeds: groups?.data?.cultureNeeds,
        ...sortedAttributes,
        token
      } ).unwrap().then( () => {
        if ( afterSubmit ) afterSubmit();
      } );
      localStorage.removeItem( 'name' );
      localStorage.removeItem( 'email' );
    }
  };

  return (
    <div className={[
      styles['card'], styles['no-drag']
    ].join( ' ' )}>
      <h1>You've assigned all of the cards!</h1>
      <p className={styles['description']}>
        Now is your chance to take once last look into your buckets to make sure you’re totally happy with your choices. If everything looks good, press “Confirm”!
      </p>
      <Button
        text='Confirm'
        styleType='whiteText'
        style={{ backgroundColor: '#8250FF' , fontSize: 14, textTransform: 'uppercase', width: '100%' }}
        onClick={onSubmit}
      />
   
    </div>
  );
};