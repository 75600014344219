import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { CulturalAssessmentAttribute } from '../../../services/models';
import { CardView, CardViewProps } from './CardView';
import styles from './CulturalAssessmentGameScreen.module.sass';
import { GameDragTypes } from './types';

export type CardProps = {
  attribute : CulturalAssessmentAttribute,
} & Pick<CardViewProps, 'onMouseDown' | 'onTouchStart'>

export const Card = ( { attribute, onMouseDown, onTouchStart } : CardProps ) => {

  const [ { isDragging } , dragRef, preview ] = useDrag( {
    type: GameDragTypes.CARD,
    collect: monitor => ( {
      isDragging: !!monitor.isDragging()
    } ),
    item: attribute
  } );

  useEffect( () => {
    preview( getEmptyImage(), { captureDraggingState: true } );
  }, [] );
  
  return (
    <CardView
      attribute={attribute}
      className={isDragging ? styles['dragging'] : undefined}
      cardRef={dragRef}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
    />
  );
};