import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../baseInstance';
import { UserAccount } from '../models';
import { CreateUserFields, ServerResponse, EditUserFields, getHelpFields } from '../types';


export const usersApi = createApi( {
  baseQuery: axiosBaseQuery,
  reducerPath: 'users',
  tagTypes: [ 'users', 'current' ],
  endpoints: build => ( {
    getUsers: build.query<UserAccount[], void>( {
      query: () => ( { url: '/users', method: 'get' } ),
      providesTags: ( res, err ) => err ? [] : [ { type: 'users', id: 'all' } ]
    } ),
    getUser: build.query<UserAccount, string>( {
      query: id => ( {
        url: `/users/${id}`,
        method: 'get'
      } ),
      providesTags: ( res, err, arg ) => err ? [] : [ { type: 'users', id: arg } ]
    } ),
    createUser: build.mutation<ServerResponse, CreateUserFields>( {
      query: fields => ( {
        url: '/users',
        method: 'post',
        data: fields
      } ),
      invalidatesTags: ( res, err ) => err ? [] : [ { type: 'users', id: 'all' } ]
    } ),
    updateUser: build.mutation<ServerResponse, {id: string, fields: EditUserFields}>( {
      query: arg => ( {
        url: `/users/${arg.id}`,
        method: 'PUT',
        data: arg.fields
      } ),
      invalidatesTags: ( res, err, arg ) => err ? [] : [ { type: 'users', id: arg.id }, { type: 'users', id: 'all' } ]
    } ),
    getCurrentUser: build.query<UserAccount, void>( {
      query: () => ( { url: '/users/me', method: 'get' } ),
      providesTags: ( res, err ) => err ? [] : [ { type: 'users', id: res?._id }, 'current' ]
    } ),
    changePassword: build.mutation<void, string>( {
      query: newPassword => ( {
        url: '/users/me/password',
        method: 'put',
        data: { password: newPassword }
      } )
    } ),
    GetHelp: build.mutation<ServerResponse,getHelpFields>( {
      query: fields => ( {
        url: '/users/sendHelpRequest',
        method: 'post',
        data: fields
      } ),
      invalidatesTags: ( res, err ) => err ? [] : [ { type: 'users', id: 'all' } ]
    } ),
    deleteUser: build.mutation<ServerResponse, string>( {
      query: ( id ) => ( {
        url: `/users/${id}`,
        method: 'delete',
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'users', id: arg }, { type: 'users', id: 'all' } ],
    } ),
  } )
} );

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetCurrentUserQuery,
  useChangePasswordMutation,
  useGetHelpMutation,
  useDeleteUserMutation,
} = usersApi;