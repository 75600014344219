import React from 'react';
import { CulturalAssessmentAttribute } from '../../../services/models';


export const BasketContext = React.createContext( { 
  attributes: [],
  setAttributes: () => null
} as {
  attributes: CulturalAssessmentAttribute[],
  setAttributes: ( attrs: CulturalAssessmentAttribute[] ) => void
} );