import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { assessmentApi, assessmentGameApi, companyApi, fitTechApi, paymentApi, usersApi } from '../services';
import { appSlice, authSlice } from './slices';


export const store = configureStore( {
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [assessmentApi.reducerPath]: assessmentApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [assessmentGameApi.reducerPath]: assessmentGameApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [fitTechApi.reducerPath]: fitTechApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
  },
  middleware: gDM => (
    gDM( {
      serializableCheck: false
    } )
      .concat( assessmentApi.middleware )
      .concat( companyApi.middleware )
      .concat( assessmentGameApi.middleware )
      .concat( usersApi.middleware )
      .concat( fitTechApi.middleware )
      .concat( paymentApi.middleware )
  )
} );

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export const useTypedSelector : TypedUseSelectorHook<RootState> = useSelector;
export type Store = typeof store;