import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo, useState, useRef, ChangeEvent } from 'react';
import { useGetAssessmentResultByCompanyQuery, useGetCurrentCompanyQuery, useGetAssessmentByCompanyQuery, useGetAssessmentSubmissionsQuery, useGetAssessmentShareTokenQuery, useGetAssessmentDocumentFileNameQuery, useCreateAssessmentMutation } from '../../../services';
import { Button } from '../../controls';
import styles from './CulturalAssessmentInstructionsScreen.module.sass'; 
import { FiEye } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import { routesPaths } from '../../../navigation/routes';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingIndicatorBox, PopUp, ProgressCircle, Select, TableListQuery } from '../../ui';
import { useClickAwayListener, useScreenTitle } from '../../../utils';
import vecAssfrom from '../../../assets/img/vecAss.png';
import { useDispatch } from 'react-redux';
import { appActions } from '../../../state';
import { useTypedSelector } from '../../../state';
import { RiFileCopyLine } from 'react-icons/ri';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Coaching from '../../../assets/img/Coaching 1.png';
import { CulturalAssessment } from '../../../services/models';

type FormFields = Pick<CulturalAssessment, 'groups' | 'targetParticipants'> & {orgName: string, role?: string};

export const CulturalAssessmentInstructionsScreen = () => {

  const appState = useTypedSelector( state => state.appSlice );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ id, setId ] = useState( '' );
  const modelFileName = useGetAssessmentDocumentFileNameQuery( id || skipToken );
  const assessmentResult = useGetAssessmentResultByCompanyQuery( id || skipToken );
  const currentCompany = useGetCurrentCompanyQuery();
  const assessment = useGetAssessmentByCompanyQuery( '1' );
  const submissions = useGetAssessmentSubmissionsQuery( id || skipToken );
  const shareToken = useGetAssessmentShareTokenQuery( id || skipToken );
  const [ shareButtonElement, setShareButtonElement ] = useState<HTMLElement>();
  const [ isSharePopupOpen, setSharePopupOpen ] = useState( false );
  const sharePopupRef = useRef<HTMLElement>( null );
  const resultsPopupRef = useRef<HTMLElement>( null );
  const [ isResultsPopupOpen, setResultsPopupOpen ] = useState( false );
  const [ showLink, setShowLink ] = useState( false );
  const [ isCopied, setCopied ] = useState( false );
  const [ selectedValue, setSelectedValue ] = useState( 'pdf' );
  const [ createCulturalAssessment, { data: creationResponse, ...creationInfo } ] = useCreateAssessmentMutation();

  const handleSelectChange = ( event: ChangeEvent<HTMLSelectElement> ) => {
    setSelectedValue( event.target.value );
  };

  console.log( 'submissions', submissions.data?.length );
  const [ download, setDownload ] = useState( false ); 
  const handleDownload = () => {
    setDownload( !download );
    let downloadUrl = '';

    if ( selectedValue === 'pdf' && modelFileName.data && modelFileName.data.pdf && download ) {
      downloadUrl = modelFileName.data.pdf;
    } else if ( selectedValue === 'pptx' && modelFileName.data && modelFileName.data.pptx ) {
      downloadUrl = modelFileName.data.pptx;
    }

    if ( downloadUrl ) {
      window.open( downloadUrl, '_blank' );
      setSelectedValue( 'pdf' );
      downloadUrl = '';
    }
  };

  const toggleResultsPopup = ( open: boolean ) => () => setResultsPopupOpen( open );
  const toggleSharePopup = ( open: boolean ) => () => setSharePopupOpen( open );

  useClickAwayListener( resultsPopupRef, isResultsPopupOpen ? toggleResultsPopup( false ) : null );
  useClickAwayListener( sharePopupRef, isResultsPopupOpen ? toggleResultsPopup( false ) : null );

  const gameUrl = useMemo( () => (
    typeof shareToken.data === 'string'
      ? window.location.origin + routesPaths.gameWelcome.replace( '*', shareToken.data )
      : undefined
  ), [ shareToken.data ] );

  const assessmentUrl = useMemo( () => (
    typeof shareToken.data === 'string'
      ? window.location.origin + routesPaths.fitTechBefor.replace( '*', shareToken.data )
      : undefined
  ), [ shareToken.data ] );

  const copyAssessmentLink = () => {
    if ( gameUrl ) {
      toast.success( 'Successfully copied Cultural Assessment link.', {
        position: 'top-right',
        autoClose: 3000,
      } );
      navigator.clipboard.writeText( gameUrl );
      setCopied( true );
    }
  };

  const gamePath = useMemo( () => gameUrl?.replace( window.location.origin, '' ) || '', [ gameUrl ] );
  const assessmentPath = useMemo( () => assessmentUrl?.replace( window.location.origin, '' ) || '', [ assessmentUrl ] );
  const goToGame = () => {
    if ( gameUrl ) window.open( gamePath, '_blank' );
  };
  const goToAssessment = () => {
    if ( assessmentUrl ) navigate( assessmentPath );
  };
  const cultureModelLink = () => {
    if ( assessmentUrl ) navigate( '/Model' );
  };


  const goToSubmission = () => {
    if ( assessmentUrl ) navigate( '/cultural-assessments/result' );
  };

  useMemo( () => {
    if ( assessment.data?._id ) {
      setId( assessment.data?._id );
    }
  }, [ assessment.data ] );

  const [ isModalOpen, setModalOpen ] = useState( false );

  const rolesLabel = useMemo( () => {
    if ( !assessment.data?.groups?.length ) return '';
    return assessment.data.groups.join( ', ' );
  }, [] );
 
  useScreenTitle( 'Culture Assessment Instructions' );
 
  return ( 
    <div className={styles[ 'container' ]}>
         
      <div className={styles[ 'container2' ]}>
        <p className={styles[ 'headerText' ]}>About Culture Assessment</p>
        <p className={styles[ 'description' ]}>Your cultural assessment model will be used to develop your hiring assessment.  As each submission is received by members of your team, your culture model will be updated. When you have all the results you need, you can create your hiring assessment based on your culture model.</p>
        <div className={styles[ 'stepsDiv' ]}>

          <div className={styles[ 'step' ]}>
            <div className={styles[ 'steptop']}>
              <div className={styles[ 'stepsub' ]}>
                <div className={styles['span']}></div>
                <p className={styles['StepH']}>Step 1</p>
              </div>
              <p className={styles['stepD']}> Complete the assessment yourself here.</p>  
            </div>
            <a href={gameUrl} target='_blank' className={styles['btn']}>Start</a>
          </div>

          <div className={styles[ 'step' ]}>
            <div className={styles[ 'steptop']}>
              <div className={styles[ 'stepsub' ]}>
                <div className={styles['span']}></div>
                <p className={styles['StepH']}>Step 2</p>
              </div>
              <p className={styles['stepD']}>Copy the link and share it with your team members by email.</p>  
            </div>
            <button onClick={copyAssessmentLink} className={styles['btn']}>Copy</button>
          </div>

          <div className={styles[ 'step' ]}>
            <div className={styles[ 'steptop']}>
              <div className={styles[ 'stepsub' ]}>
                <div className={styles['span']}></div>
                <p className={styles['StepH']}>Step 3</p>
              </div>
              <p className={styles['stepD']}>When you have all the results you need, create your hiring assessment.</p> 
            </div> 
            <button onClick={goToSubmission} className={styles['btn']}>View Submissions</button>
          </div>

        </div>
      </div> 
      {/* <ToastContainer /> */}
    </div>
  );
};