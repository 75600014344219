import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Formik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { editCulturalAssessmentSchema, useCreateAssessmentMutation, useGetAssessmentQuery, useGetCurrentCompanyQuery, useUpdateAssessmentMutation } from '../../../services';
import { CulturalAssessment } from '../../../services/models';
import { useScreenTitle } from '../../../utils';
import { Button, SeparatedWordsInput, TextInput } from '../../controls';
import { Box, LabeledField, LoadingIndicatorBox, ResponseMessage } from '../../ui';
import { SuccessModal } from '../../ui/Modals';
import styles from './CulturalAssessmentEditScreen.module.sass';
import { appActions } from '../../../state';
import { useDispatch } from 'react-redux';

type FormFields = Pick<CulturalAssessment, 'groups' | 'targetParticipants'> & {orgName: string, role?: string};

export const CulturalAssessmentEditScreen = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ createCulturalAssessment, { data: creationResponse, ...creationInfo } ] = useCreateAssessmentMutation();
  const [ updateCulturalAssessment, updateInfo ] = useUpdateAssessmentMutation();

  const currentCompany = useGetCurrentCompanyQuery();

  const { data: assessmentToEdit, isLoading } = useGetAssessmentQuery( id || skipToken );

  const [ isModalOpen, setModalOpen ] = useState( false );

  const isEditing = !!id && !!assessmentToEdit;

  const isSuccess = creationInfo.isSuccess || updateInfo.isSuccess;
  const isError = creationInfo.isError || updateInfo.isError;

  useScreenTitle( `${isEditing ? 'Edit' : 'Create'} Cultural Assessment` );


  if ( isLoading || !currentCompany.data ) return <LoadingIndicatorBox />;

  const initialValues : FormFields = isEditing ? {
    groups: assessmentToEdit.groups,
    role: assessmentToEdit.role,
    targetParticipants: assessmentToEdit.targetParticipants,
    orgName: currentCompany.data.name
  } : {
    groups: [],
    role: undefined,
    targetParticipants: 0,
    orgName: currentCompany.data?.name
  };

  const onSubmit = ( values : typeof initialValues ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { orgName, role, ...fields } = values;
    fields.targetParticipants = +fields.targetParticipants;
    if ( id && assessmentToEdit ) {
      updateCulturalAssessment( {
        id, ...fields
      } );
    } else {
      createCulturalAssessment( fields ).unwrap().then( () => {
        setModalOpen( true );
      } );
    }
  };
  
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={editCulturalAssessmentSchema( currentCompany.data.cultureNeeds )}
      >
        {( { errors, handleSubmit } ) => (
          <form onSubmit={handleSubmit} className={styles['form']}>
            <LabeledField label='Organization Name' style={{ width: 'calc(30% - 12px)', marginRight: 24 }}>
              <TextInput
                name='orgName'
                disabled
              />
            </LabeledField>
            <LabeledField label='Role' style={{ width: 'calc(70% - 12px)' }}>
              <TextInput
                name='role'
                disabled
              />
            </LabeledField>
            {currentCompany.data?.cultureNeeds === 'protected' && (
              <LabeledField label='Groups'>
                <SeparatedWordsInput
                  name='groups'
                  placeholder='Enter your organization groups'
                />
                <span data-role='error'>{errors.groups && errors.groups}</span>
              </LabeledField>
            )}
            <LabeledField label='How many people do you plan to send this Cultural DNA Modeling survey to?'>
              <TextInput
                name='targetParticipants'
                placeholder='Enter a number'
                type='number'
              />
            </LabeledField>
            <Button 
              text={isEditing ? 'Save' : 'Create'} 
              type={'submit'} 
              styleType='nice'
            />
            <ResponseMessage
              isSuccess={isSuccess}
              isError={isError}
              successLabel={isEditing ? 'Changes saved' : 'Created'}
              style={{ width: 'auto', marginLeft: 24 }}
            />
          </form>
        )}
      </Formik>
      <SuccessModal
        isOpen={isModalOpen}
        onConfirm={() => {
          if ( creationResponse ) {
            dispatch( appActions.setIsShown( false ) );
            navigate( routesPaths.culturalAssessmentSingle.replace( ':id', creationResponse._id ) );
          }
        }}
        text={'Cultural Survey created created successfully'}
      />
    </Box>
  );
};