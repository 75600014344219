import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../baseInstance';
import { CulturalAssessment, CulturalAssessmentAttribute, CulturalAssessmentSubmission, FitTechSubmission } from '../models';
import { EditCulturalAssessmentFields, ServerResponse } from '../types';


export const assessmentApi = createApi( {
  baseQuery: axiosBaseQuery,
  reducerPath: 'assessment',
  tagTypes: [ 
    'allAssessments', 'assessment', 'shareToken', 'fitTechSubmissions', 'archivedAssessments',
    'assessmentsWithActiveFitTech'
  ],
  endpoints: build => ( {
    getAssessments: build.query<CulturalAssessment[], void>( {
      query: () => ( { url: '/assessment', method: 'get', params: { active: 'true' } } ),
      providesTags: [ 'allAssessments' ]
    } ),
    getArchivedAssessments: build.query<CulturalAssessment[], void>( {
      query: () => ( { url: '/assessment', method: 'get', params: { active: 'false' } } ),
      providesTags: [ 'archivedAssessments' ]
    } ),
    archiveAssessment: build.mutation<ServerResponse, string>( {
      query: id => ( {
        url: `/assessment/${id}/archive`,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, id ) => err ? [] : [ 'allAssessments', 'archivedAssessments', { type: 'assessment', id } ]
    } ),
    getAssessment: build.query<CulturalAssessment, string>( {
      query: id => ( { url: `/assessment/${id}`, method: 'get' } ),
      providesTags: ( res, err, arg ) => err ? [] : [ { type: 'assessment', id: arg } ]
    } ),
    createAssessment: build.mutation<ServerResponse & {_id: string}, Omit<EditCulturalAssessmentFields, 'role'>>( {
      query: fields => ( {
        url: '/assessment',
        method: 'post',
        data: fields
      } ),
      invalidatesTags: [ 'allAssessments' ]
    } ),
    updateAssessment: build.mutation<ServerResponse, {id: string} & Omit<EditCulturalAssessmentFields, 'role'>>( {
      query: arg => ( {
        url: `/assessment/${arg.id}`,
        method: 'PUT',
        data: ( () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...fields } = arg;
          return fields;
        } )()
      } ),
      invalidatesTags: ( res, err, arg ) => err ? [] : [ { type: 'assessment', id: arg.id }, 'allAssessments' ]
    } ),
    getAssessmentSubmissions: build.query<CulturalAssessmentSubmission[], string>( {
      query: id => ( {
        url: `/assessment/${id}/submissions`,
        method: 'get',
      } )
    } ),
    getAssessmentShareToken: build.query<string, string>( {
      query: id => ( {
        url: `/assessment/${id}/shareToken`,
        method: 'get'
      } ),
      providesTags: ( res, err, arg ) => typeof res === 'string' ? [ { type: 'shareToken', id: arg } ] : []
    } ),
    getAssessmentResult: build.query<CulturalAssessmentAttribute[], string>( {
      query: id => ( {
        url: `/assessment/${id}/result`,
        method: 'get'
      } )
    } ),
    getAssessmentDocumentFileName: build.query<{pdf: string, pptx: string}, string>( {
      query: id => ( {
        url: `/assessment/${id}/generateDoc`,
        method: 'get'
      } )
    } ),
    getFitTechSubmissions: build.query<FitTechSubmission[], string>( {
      query: id => ( {
        url: `/assessment/${id}/fitTech`,
        method: 'get',
      } ),
      providesTags: ( res, err, id ) => err ? [] : [ { type: 'fitTechSubmissions', id } ]
    } ),
    getAssessmentsWithActiveFitTech: build.query<CulturalAssessment[], void>( {
      query: () => ( {
        url: '/assessment/activeFitTech',
        method: 'get'
      } ),
      providesTags: [ 'assessmentsWithActiveFitTech' ]
    } ),
    activateFitTech: build.mutation<void, string>( {
      query: id => ( {
        url: '/assessment/activateFitTechTrial',
        method: 'post',
        data: {
          assessmentId: id
        }
      } ),
      invalidatesTags: ( res, err, id ) => err ? [] : [
        { type: 'assessment', id }, 'allAssessments', 'assessmentsWithActiveFitTech'
      ]
    } ),
    setFitTechRole: build.mutation<void, {assessmentId: string, role: string}>( {
      query: args => ( {
        url: `/assessment/${args.assessmentId}/fitTech/role`,
        method: 'post',
        data: { role: args.role }
      } ),
      invalidatesTags: ( res, err, arg ) => err ? [] : [ { type: 'assessment', id: arg.assessmentId } ]
    } ),
    getAssessmentResultByCompany: build.query<CulturalAssessmentAttribute[], string>( {
      query: id => ( {
        url: `/assessment/${id}/resultByCompany`,
        method: 'get'
      } ),
    } ),
    getAssessmentByCompany: build.query<CulturalAssessment, string>( {
      query: id => ( { url: `/assessment/${id}/assessmentByCompany`, method: 'get' } ),
      providesTags: ( res, err, arg ) => err ? [] : [ { type: 'assessment', id: arg } ]
    } )
  } )
} );


export const {
  useGetAssessmentsQuery,
  useGetAssessmentQuery,
  useCreateAssessmentMutation,
  useUpdateAssessmentMutation,
  useGetAssessmentSubmissionsQuery,
  useGetAssessmentShareTokenQuery,
  useGetAssessmentResultQuery,
  useGetAssessmentDocumentFileNameQuery, 
  useGetFitTechSubmissionsQuery,
  useGetArchivedAssessmentsQuery,
  useArchiveAssessmentMutation,
  useGetAssessmentsWithActiveFitTechQuery,
  useActivateFitTechMutation,
  useSetFitTechRoleMutation,
  useGetAssessmentResultByCompanyQuery,
  useGetAssessmentByCompanyQuery
} = assessmentApi;