import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { useGetCurrentCompanyQuery, useGetCurrentUserQuery, useGetUserQuery } from '../../../services';
import { Button } from '../../controls';
import styles from './ProfileScreen.module.sass';

export interface ProfileScreenProps {
  isCurrentUser?: boolean
}

export const ProfileScreen = ( { isCurrentUser=false } : ProfileScreenProps ) => {

  const { id } = useParams();
  const navigate = useNavigate();


  const queriedUser = useGetUserQuery( isCurrentUser ? skipToken : id || skipToken );
  const currentUser = useGetCurrentUserQuery( undefined, {
    skip: !isCurrentUser
  } );
  const currentCompany = useGetCurrentCompanyQuery();

  const user = isCurrentUser ? currentUser : queriedUser;

  if ( !user.data || !currentCompany.data ) return null;

  const goEdit = () => {
    if ( user.data ) navigate( routesPaths.editUser.replace( ':id', user.data._id ) );
  };

  const goManageUsers = () => {
    console.log( 'window.location.pathname', window.location.pathname );
    
    if ( user.data && window.location.pathname.includes( 'profile' ) ) navigate( routesPaths.users );
  };
  
  return (
    <>
      <div className={styles['header']}>
        <h1>{user.data.name}</h1>
        {/* {user.data.role === 'admin'&&<Button text='Users' styleType='alter' onClick={goManageUsers} />} */}
        <Button text='Edit' styleType='alter' onClick={goEdit} />
      </div>
      <ul className={styles['user-data']}>
        <li>
          <span>Email</span>
          <span>{user.data.email}</span>
        </li>
        <li>
          <span>Company</span>
          <span>{currentCompany.data.name}</span>
        </li>
        {user.data.role !== 'contributor' && <li>
          <span>Email report recipients</span>
          <span>{currentCompany.data.recipients}</span>
        </li>}
        {/* <li>
          <span>Description</span>
          <span>{user.data.description}</span>
        </li> */}
      </ul>
    </>
  );
};