import { skipToken } from '@reduxjs/toolkit/dist/query';
import { StripeElements } from '@stripe/stripe-js';
import { Form, Formik } from 'formik';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation/routes';
import { useGetFitTechPaymentSecretQuery, useGetAssessmentByCompanyQuery, useGetAssessmentsQuery, useCheckoutSessionQuery, useGetCurrentUserQuery } from '../../../services';
import { useStripe } from '../../../utils';
import { Button, CheckBox } from '../../controls';
import { LoadingIndicator } from '../../ui';
import styles from './PaymentScreen.module.sass';
import { StripeReturnParams } from './types';
import * as yup from 'yup';
import { createElement } from 'react';


export const PaymentScreen = () => {
  const [ id, setId ] = useState( '' );
  const navigate = useNavigate();
  const stripe = useStripe();

  const [ isPaid, setIsPaid ] = useState( false );

  const assessments = useGetAssessmentsQuery(); 
  
  useMemo( () => {
    if ( assessments.data ) {
      const paid = assessments.data.some( ( assessment ) => assessment.fitTechStatus.paid );

      setIsPaid( paid );
    }
  }, [ assessments.data ] );

  const [ paymentElementLoading, setPaymentElementLoading ] = useState( true );
  const [ paymentError, setPaymentError ] = useState<string>();

  const assessment = useGetAssessmentByCompanyQuery( '1' );
  const paymentIntent = useGetFitTechPaymentSecretQuery( id || skipToken );

  const [ elements, setElements ] = useState<StripeElements>();
  const stripeElementRef = useRef<HTMLDivElement>( null );

  const subscriptionEnd = useMemo( () => {
    if( assessment.data?._id ){
      
      setId( assessment.data?._id );      
    }
  }, [ assessment.data ] );
  

  if ( isPaid ) {
    // Open a new page with the provided link
    window.open( 'https://billing.stripe.com/p/login/test_bIYaEIcjO4lP19eaEE', '_blank' );

    // // Navigate back to the previous page
    // window.history.back();

    // // You can also use the following to navigate back after a delay (e.g., 2000 milliseconds)
    setTimeout( () => window.location.reload(), 0 );
    navigate( '/' );

    // Return null or any other component if needed
    return <Fragment></Fragment>;
  }

  const session = useCheckoutSessionQuery( id || skipToken );
  const currentUser = useGetCurrentUserQuery();
  useEffect( () => {
    const script = document.createElement( 'script' );
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild( script );

    return () => {
        document.body.removeChild( script );
    };
}, [] );
  console.log( session.data );
  return createElement( 'stripe-pricing-table', {
      'pricing-table-id': 'prctbl_1OtDaRIsfEoQ3NBLFau74GBg',
      'publishable-key': 'pk_test_tM1mvsVnjxHsYhF4gH9Ozn8R',
      'client-reference-id': session.data?.clientReferenceId,
      'customer-email': currentUser.currentData?.email
  } );
};

