import { RootRouter } from './navigation';
import '../node_modules/modern-normalize/modern-normalize.css';
import { RootController } from './components/serviceComponents';
import { ToastContainer } from 'react-toastify';

export const App = () => {

  return (
    <>
      <RootController />
      <RootRouter />
      <ToastContainer />
    </>
  );
};